import {
  GoogleMapsAddressPicker,
  GoogleMapsAddressPickerProps,
} from "@ipis/client-essentials";

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
type Props = Omit<
  GoogleMapsAddressPickerProps,
  "apiKey" | "requireAllComponents"
>;

const EASGoogleMapsAddressPicker = (props: Props) => {
  return (
    <GoogleMapsAddressPicker
      apiKey={apiKey}
      requireAllComponents={true}
      {...props}
    />
  );
};

export default EASGoogleMapsAddressPicker;
