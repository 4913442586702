import {
  FieldValues,
  Path,
  UseFormRegisterReturn,
  UseFormReturn,
} from "react-hook-form";
import { FormInputShellPublicProps } from "../components/form/form-inputs/components/FormInputShell";
import { useEffect, useRef } from "react";

export function useFormError(args: {
  form: UseFormReturn<any> | undefined;
  name: string;
}): string | undefined {
  if (!args.form) {
    return undefined;
  }

  const errors = args.form.formState.errors;
  const subpaths = args.name.split(".");

  const [first, ...rest] = subpaths;

  let _error: any = errors[first];
  for (const subpath of rest) {
    if (_error === undefined || typeof _error !== "object") {
      break;
    }
    _error = _error[subpath];
  }
  if (typeof _error === "object" && typeof _error.message === "string") {
    return _error.message;
  }
  return undefined;
}
export function useFormValue<T extends FieldValues>(
  props: FormInputShellPublicProps<T>,
  settings: {
    defaultValue?: any;
    setDefaultValueAsUndefined?: boolean;
  } = {}
): any {
  const defaultValueSetRef = useRef(false);

  function getValue() {
    if (props.form) {
      return props.form.watch(props.name);
    } else if (props.controls) {
      return props.controls.value;
    }
    return undefined;
  }
  const value = getValue();

  const form = props.form;
  const controls = props.controls;
  const defaultValue = settings.defaultValue;

  useEffect(() => {
    if (defaultValueSetRef.current) {
      return;
    }
    if (defaultValue === undefined && !settings.setDefaultValueAsUndefined) {
      return;
    }
    const currentVal = controls?.value;
    if (!controls?.onChange || currentVal !== undefined) {
      return;
    }
    controls.onChange(defaultValue);
    defaultValueSetRef.current = true;
  }, [controls, defaultValue]);

  useEffect(() => {
    if (defaultValueSetRef.current) {
      return;
    }

    if (defaultValue === undefined && !settings.setDefaultValueAsUndefined) {
      return;
    }
    if (!form) {
      return;
    }

    const value = form.getValues(props.name);

    if (value !== undefined) {
      return;
    }

    form.setValue(props.name, defaultValue);
    defaultValueSetRef.current = true;
  }, [form, props.name, defaultValue]);

  return value;
}

export type UseFormRegisterElementType =
  | "select"
  | "textarea"
  | "text"
  | "number"
  | "radio";

type Args<T extends FieldValues> = {
  props: CustomRegisterProps<T>;
  type: UseFormRegisterElementType;
  transformValue?: (val: any) => any;
};

function processValue(args: {
  rootArgs: Args<any>;
  val: any;
  defaultTransformation?: (val: any) => any;
}) {
  const { rootArgs, val, defaultTransformation } = args;
  if (rootArgs.transformValue) {
    return rootArgs.transformValue(val);
  }

  if (defaultTransformation) {
    return defaultTransformation(val);
  }

  return val;
}

/* 
  Needs to take readyonly and disabled into account, from args and from context
*/
function useOnChange<T extends FieldValues>(
  args: Args<T>
): (value: any) => void {
  if (!args.props.controls) {
    return () => {};
  }

  const callback = args.props.controls.onChange;

  if (args.type === "select") {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      const val = processValue({
        rootArgs: args,
        val: e.target.value,
      });
      callback?.(val);
    };
  }

  if (args.type === "textarea" || args.type === "text") {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = processValue({
        rootArgs: args,
        val: e.target.value,
      });
      callback?.(val);
    };
  }

  if (args.type === "number") {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = processValue({
        rootArgs: args,
        val: e.target.value,
        defaultTransformation: (val) => {
          const number = parseFloat(val);
          return isNaN(number) ? undefined : number;
        },
      });
      callback?.(val);
    };
  }

  return () => {};
}

type CustomRegisterProps<T extends FieldValues> = Pick<
  FormInputShellPublicProps<T>,
  "form" | "name" | "controls" | "onBlur" | "onFocus"
> & {
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
};

export function useFormRegisterProps<T extends FieldValues>(
  args: Args<T>
): any {
  const { props, type } = args;
  const onChange = useOnChange(args);

  const base: Record<string, any> = {
    onBlur: args.props.onBlur,
    onFocus: args.props.onFocus,
  };

  const setValueAs =
    (defaultTransformation?: (val: any) => any) => (val: any) => {
      const processedValue = processValue({
        rootArgs: args,
        val,
        defaultTransformation,
      });

      return processedValue;
    };

  if (props.form) {
    let registerProps: UseFormRegisterReturn<Path<T>>;
    switch (type) {
      case "select":
        registerProps = props.form.register(props.name, {
          setValueAs: setValueAs(),
        });
        break;
      case "textarea":
      case "text":
        registerProps = props.form.register(props.name, {
          minLength: props.minLength,
          maxLength: props.maxLength,
          setValueAs: setValueAs(),
        });
        break;
      case "number":
        registerProps = props.form.register(props.name, {
          min: props.min,
          max: props.max,
          setValueAs: setValueAs((val) => {
            const number = parseFloat(val);
            return isNaN(number) ? undefined : number;
          }),
        });
        break;
      case "radio":
        throw new Error(
          `Radio not implemented; the setValueAs function apparently only works for textinputs,
          and I need to transform the value. Writing a custom onChange function kind of works,
          but the ref from RHF somehow overrides that value. And omitting the ref makes it so that
          it wont get focused on error.`
        );
      /*  registerProps = {
          name: rhfResult.name,
          ref: rhfResult.ref,
          onBlur: rhfResult.onBlur,
          onChange: async (e: any) => {
            const event = e as React.ChangeEvent<HTMLInputElement>;
            const rawValue = event.target.value;
            const value = processValue({
              rootArgs: args,
              val: rawValue,
            });
            console.log({
              rawValue,
              value,
              name: props.name,
              hasForm: !!props.form,
            });
            props.form?.clearErrors(props.name);
            props.form?.setValue(props.name, value);
          },
        }; 
        break;*/
    }
    return {
      ...base,
      ...registerProps,
    };
  }

  if (props.controls) {
    const controlledProps = {
      value: props.controls.value,
      onChange,
    };
    return {
      ...base,
      ...controlledProps,
    };
  }

  return base;
}
