import {
  FormEditableAddressFieldset,
  IpisTextInputCompact,
} from "@ipis/client-essentials";
import { Path, UseFormReturn } from "react-hook-form";
import { PrivateCustomerInformationValues } from "../../temporary-booking-flow-schemas";
import DifferentLocationController from "./DifferentLocationController";
import WorkOrderLocationSection from "./WorkOrderLocationSection";

type MapFormFields<T extends string> = {
  [Key in keyof PrivateCustomerInformationValues as T extends ""
    ? Key
    : `${T}.${Key}`]: PrivateCustomerInformationValues[Key];
};

interface Props<T extends string = ""> {
  nested?: T;
  /* 
    This should use the MapFormFields type, 
    but that gives typescript errors when passing in the
    form for some reason
  */
  form: UseFormReturn<any>;
  editable: boolean;
}

const PrivateCustomerInformationFields = <T extends string>(
  props: Props<T>
) => {
  const form = props.form;

  function getName(
    field: Path<PrivateCustomerInformationValues>
  ): Path<MapFormFields<T>> {
    const prefix = props.nested ? `${props.nested}.` : "";
    return `${prefix}${field}` as Path<MapFormFields<T>>;
  }

  const differentLocationName = getName("locationDifferentFromBaseAddress");
  const differentLocationValue = form.watch(differentLocationName);

  return (
    <section className={"grid auto-rows-auto grid-cols-2 gap-4"}>
      <IpisTextInputCompact
        id="firstName"
        name={getName(
          "privateCustomerInformation.contactInformation.firstName"
        )}
        label="Förnamn"
        form={form}
        readOnly
      />
      <IpisTextInputCompact
        id="lastName"
        name={getName("privateCustomerInformation.contactInformation.lastName")}
        label="Efternamn"
        form={form}
        readOnly
      />
      <FormEditableAddressFieldset
        id="privateAddress"
        isEditing={false}
        form={props.form}
        legend={
          differentLocationValue ? "Hemadress" : "Hem- och utförandeadress"
        }
        className="col-span-2"
        fieldMapping={{
          street: getName("verificationStatus.address.street"),
          postalCode: getName("verificationStatus.address.postalCode"),
          city: getName("verificationStatus.address.city"),
          country: getName("verificationStatus.address.country"),
        }}
      />
      {/* <EASGoogleMapsAddressPicker
        className="col-span-2"
        onChange={(address) => {
          props.form.setValue(addressName, address as any);
        }}
        readOnly={!props.editable}
        currentValue={currentAddress as any}
        isError={isAddressError}
      /> */}

      {props.editable && (
        <DifferentLocationController
          form={form}
          name={differentLocationName}
          className="col-span-2"
        />
      )}
      {differentLocationValue && (
        <WorkOrderLocationSection
          nested={props.nested}
          className="col-span-2"
          form={form}
          editable={props.editable}
        />
      )}
      <IpisTextInputCompact
        id="phone"
        name={getName("privateCustomerInformation.contactInformation.phone")}
        label="Telefonnummer"
        form={form}
        type="tel"
        className="col-span-2"
      />
    </section>
  );
};

export default PrivateCustomerInformationFields;
