import { classNames } from "../utils/client-utils";

export type SvgIconSize = "sm" | "md" | "lg";

export type ExposedIconProps<T extends InternalIconProps> = T & {
  className?: string;
  size?: SvgIconSize | number;
  viewBox?: string;
};

export type InternalIconProps = {
  className?: string;
  style?: React.CSSProperties;
};

const withIconSize = <T extends InternalIconProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  return (props: ExposedIconProps<T>) => {
    let className: string | undefined = props.className;
    let sizeClassName: string | undefined;
    let style: React.CSSProperties | undefined = props.style;
    const { size: propsSize, ...rest } = props;
    const size = props.size ?? "md";

    const r = rest as T;

    if (typeof size === "number") {
      style = {
        ...style,
        height: size,
        width: size,
      };
    } else {
      switch (size) {
        case "sm":
          sizeClassName = "h-5 w-5";
          break;
        case "md":
          sizeClassName = "h-6 w-6";
          break;
        case "lg":
          sizeClassName = "h-7 w-7";
          break;
      }

      className = classNames(className, sizeClassName);
    }

    return <WrappedComponent {...r} style={style} className={className} />;
  };
};

export default withIconSize;
