import { BrandTheme } from "@eljouren/domain";
import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import TabList from "../../components/tabs/TabList";
import TabContextProvider from "../../contexts/__providers/TabContextProvider";
import { IpisLayoutContext } from "../../contexts/layout-context";
import { TabContextProps } from "../../contexts/tab-context";
import { IpisUnexpectedError } from "../../screens/error/IpisUnexpectedError";
import { IpisWindowModalsProvider } from "../modals/window-modals/IpisWindowModalsProvider";
import { PageHeader } from "./PageHeader";

interface Props {
  children?: React.ReactNode;
  sideBar?: React.ReactNode;
}

export const IpisLayout = (props: Props) => {
  /* 
    Important: this shouldn't be commented out, the the EAS client breaks for some reason if useLocation is used here. Probably some dependencey issue with react-router-dom.
  */
  /* const [tabProps, setTabProps] = useState<TabContextProps<string> | false>(
    false
  ); */
  const [tabProps] = useState<TabContextProps<string> | false>(false);
  const [externalBrandTheme, setExternalBrandTheme] = useState<
    BrandTheme.Type | undefined
  >(undefined);

  /* 
   Important: this shouldn't be commented out, the the EAS client breaks for some reason if useLocation is used here. Probably some dependencey issue with react-router-dom.
  */
  /* const location = useLocation();
  
  const mostRecentPath = React.useRef(location.pathname);
  
  useEffect(() => {
    if (mostRecentPath.current !== location.pathname) {
      setTabProps(false);
    }
  }, [location.pathname]); */

  return (
    <IpisLayoutContext.Provider
      value={{ externalBrandTheme, setExternalBrandTheme }}
    >
      <ConditionalTabContext tabProps={tabProps} key="tabContext">
        {props.sideBar}
        <PageHeader />
        <ErrorBoundary
          fallbackRender={(props) => {
            return <IpisUnexpectedError error={props.error} />;
          }}
        >
          {props.children}
          {tabProps && (
            <TabList className="col-start-1 row-start-3 lg:row-start-2" />
          )}
        </ErrorBoundary>
        <IpisWindowModalsProvider />
      </ConditionalTabContext>
    </IpisLayoutContext.Provider>
  );
};

const ConditionalTabContext = (props: {
  children?: React.ReactNode;
  tabProps: TabContextProps<string> | false;
}) => {
  if (props.tabProps) {
    return (
      <TabContextProvider {...props.tabProps}>
        {props.children}
      </TabContextProvider>
    );
  }

  return <>{props.children}</>;
};
