import { createContext, useContext } from "react";
import {
  BaseGoogleAddressType,
  EmergencyCustomerInformationValues,
  EmergencyWorkOrderDetails,
  TypeOfCustomer,
} from "../temporary-booking-flow-schemas";
import { BookingType, BookingTypeService, CostCenter } from "../eas-trpc-types";

/* 
	Might not belong in this file
*/
export enum BookingFlowStep {
  chooseCostCenter = 0,
  chooseBookingType = 1,
  // Emergency
  emergencyWorkOrderCustomerInformation = 100,
  emergencyWorkOrderDetails = 101,
  emergencyWorkOrderConfirmation = 102,
  // Booking request
  bookingRequestConfirmation = 200,
  // Customer support
  customerSupportConfirmation = 300,
}

export type BookingFlowContextType = {
  selectedCostCenter?: CostCenter;
  selectCostCenter: (costCenter: CostCenter) => void;
  costCenters: CostCenter[] | undefined;
  selectedBookingType?: BookingType;
  selectBookingType: (bookingType: BookingType) => void;
  typeOfCustomer: TypeOfCustomer;
  setTypeOfCustomer: (typeOfCustomer: TypeOfCustomer) => void;
  emergencyBookingCustomerValues:
    | EmergencyCustomerInformationValues
    | undefined;
  typeOfService: BookingTypeService | undefined;
  setTypeOfService: (service: BookingTypeService) => void;
  setEmergencyBookingCustomerValues: (
    values: EmergencyCustomerInformationValues
  ) => Promise<void>;
  emergencyWorkOrderDetails: EmergencyWorkOrderDetails | undefined;
  setEmergencyWorkOrderDetails: (details: EmergencyWorkOrderDetails) => void;
  getEmergencyWorkOrderLocation: (
    info: EmergencyCustomerInformationValues
  ) => BaseGoogleAddressType;
  confirmEmergencyWorkOrder(props: {
    costCenter: CostCenter;
    customerInfo: EmergencyCustomerInformationValues;
    workOrderDetails: EmergencyWorkOrderDetails;
    comment: string;
  }): Promise<{
    bookingReference: string;
  }>;
  goBack: () => void;
  reset: () => void;
  step: BookingFlowStep;
};

export const BookingFlowContext = createContext<BookingFlowContextType>(
  {} as never
);

export function useBookingFlowContext() {
  return useContext(BookingFlowContext);
}

/* export function useBookingFlowTypeOfServiceStep() {
  const ctx = useBookingFlowContext();

  const cc = ctx.selectedCostCenter;
  if (!cc) {
    throw new Error("Cost center not selected");
  }

  return {
    ...ctx,
    selectedCostCenter: cc,
  };
} */
