import React from "react";

interface Props {
  size: number;
  light?: boolean;
  logo: {
    lightUrl: string;
    darkUrl: string;
  }
}

export const IpisLogoIconV2: React.FC<Props> = (props) => {
  return (
    <img
      style={{
        width: props.size,
        height: props.size,
      }}
      src={
        props.light
          ? props.logo.lightUrl
          : props.logo.darkUrl
      }
      alt="Logga för IPIS Plattform"
    />
  );
};

