import { IconCheck, IconCircleCheckFilled } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";
import { classNames } from "../../utils/client-utils";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "default" | "circle";
  success?: boolean;
}

const _IpisCheckmarkIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "circle") {
    return (
      <IconCircleCheckFilled
        {...rest}
        className={classNames(
          props.className,
          props.success && "fill-success text-light-background"
        )}
      />
    );
  }

  return (
    <IconCheck
      {...rest}
      className={classNames(props.className, props.success && "text-success")}
    />
  );
};
export const IpisCheckmarkIcon = withIconSize(_IpisCheckmarkIcon);
