import EnvironmentVariables from "../EAS_EnvironmentVariables";
import AuthenticationManager from "../authentication/EAS_AuthenticationManager";

/* 
	For easier mocking
*/
export type EAS_IAuthApiClient = EAS_AuthApiClient;

export default class EAS_AuthApiClient {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = EnvironmentVariables.get().mainProxyApiUrl;
  }

  private getEndpoint(endpoint: string): string {
    return `${this.baseUrl}/${endpoint}`;
  }

  public isSignedIn(): boolean {
    return AuthenticationManager.getInstance().isSignedIn();
  }

  public async authenticate(args: {
    email: string;
    password: string;
  }): Promise<200 | 404> {
    try {
      const url = this.getEndpoint("auth");
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: args.email,
          password: args.password,
          type: "external-booking-agent",
        }),
      });

      if (res.status === 404) {
        return 404;
      }

      if (res.status !== 200) {
        throw new Error("Failed to authenticate");
      }

      const json = await res.json();
      console.log({ json });
      AuthenticationManager.getInstance().store(json);

      return 200;
    } catch (error) {
      throw error;
    }
  }

  public async signOut(): Promise<void> {
    try {
      const url = this.getEndpoint("signOut");
      await fetch(url, {
        method: "POST",
        credentials: "include",
      });
    } catch (er) {
      console.error(er);
    }
    AuthenticationManager.getInstance().signOut();
  }
}
