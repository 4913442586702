import { motion } from "framer-motion";
import { useExternalBrandTheme } from "../../hooks/brand-hooks";
import { classNames } from "../../utils/client-utils";

export type PageHeaderV2Props = {
  className?: string;
  loading?: boolean;
  noBackButton?: boolean;
  hideOnKeyboardOpen?: boolean;
  overrideLink?: string;
  overrideLinkWhenNoHistory?: string;
  isOffline?: boolean;
};

export const PageHeader = (props: PageHeaderV2Props) => {
  const base = "#103236";
  const from = "#72cbd6";
  const to = base;
  const isOnline = !props.isOffline;

  const theme = useExternalBrandTheme().externalBrandTheme;

  const loading = props.loading;

  let key: string;
  if (loading) {
    key = "loadingPageHeaderSmall";
  } else {
    key = "staticPageHeaderSmall";
  }

  return (
    <motion.header
      id="page-header"
      key={key}
      className={classNames(
        "relative min-h-[65px] items-center gap-x-2 gap-y-1 p-1 py-2",
        "bg-accent text-light-background",
        !isOnline && "pb-12",
        props.className
      )}
      style={
        theme
          ? {
              background: theme.headerBackgroundColor,
              color: theme.headerTextColor,
            }
          : undefined
      }
      initial={
        loading
          ? {
              background: `linear-gradient(to bottom, transparent 4px, ${base} 4px),linear-gradient(to right, ${from} 0%, ${to} 1%)`,
            }
          : undefined
      }
      animate={
        loading
          ? {
              background: `linear-gradient(to bottom, transparent 4px, ${base} 4px), linear-gradient(to right, ${from} 100%, ${to} 100%)`,
            }
          : undefined
      }
      transition={
        loading
          ? {
              repeat: Infinity,
              repeatDelay: 0.8,
              duration: 0.8,
            }
          : undefined
      }
    >
      {!isOnline && (
        <span className="absolute bottom-0 left-0 col-span-3 flex w-full justify-center bg-error px-4 py-1 text-sm text-light-background">
          Ingen internetuppkoppling
        </span>
      )}
    </motion.header>
  );
};
