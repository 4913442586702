import { RichTextPreTag } from "./RichTextPreTag";

interface Props {
  className?: string;
  html: string;
}

export const IpisFormInputParanthesizedTooltip = (props: Props) => {
  return <RichTextPreTag html={props.html} className={props.className} />;
};

