import { IpisUnexpectedError } from "@ipis/client-essentials";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { z } from "zod";
import ApiClientContextProvider from "./contexts/__providers/EAS_ApiClientContextProvider";
import BookingFlowRoute from "./routes/BookingFlowRoute";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  /* console.log({
    issue,
    ctx,
  }); */

  if (issue.code === z.ZodIssueCode.too_small) {
    return { message: `Minst ${issue.minimum} tecken!` };
  }

  if (issue.code === z.ZodIssueCode.too_big) {
    return { message: `Max ${issue.maximum} tecken!` };
  }

  // New invalid_type condition for translating "Required" message
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected !== "undefined" && issue.received === "undefined") {
      return { message: "Obligatorisk" }; // Translation for "Required"
    }
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      // 5 minute staletime
      staleTime: 1000 * 60 * 5,
    },
  },
});

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <BookingFlowRoute />,
      errorElement: (
        <IpisUnexpectedError error={new Error("Unexpected error")} />
      ),
    },
    {
      path: "*",
      element: <BookingFlowRoute />,
      errorElement: (
        <IpisUnexpectedError error={new Error("Unexpected error")} />
      ),
    },
  ]);

  return (
    <ErrorBoundary
      fallbackRender={(props) => {
        return <IpisUnexpectedError error={props.error} />;
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ApiClientContextProvider>
          {/* <IpisWindowModalsProvider /> */}
          <RouterProvider router={router} />
        </ApiClientContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
