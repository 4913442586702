import { createContext, useContext } from "react";
import { EAS_IBookingFlowApiClient } from "../api-clients/EAS_BookingFlowApiClient";
import { EAS_IAuthApiClient } from "../api-clients/EAS_AuthApiClient";

export type ApiClientContextType = {
  bookingFlow: EAS_IBookingFlowApiClient;
  auth: EAS_IAuthApiClient;
};

export const ApiClientContext = createContext<ApiClientContextType>(
  {} as never
);

export function useApiClients() {
  const ctx = useContext(ApiClientContext);
  return ctx;
}
