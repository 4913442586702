import {
  FormEditableAddressFieldset,
  IpisButton,
  classNames,
  useFormError,
} from "@ipis/client-essentials";
import { Controller, Path, UseFormReturn } from "react-hook-form";
import EASGoogleMapsAddressPicker from "../../components/EASGoogleMapsAddressPicker";
import { CustomerInformationFormValues } from "../../temporary-booking-flow-schemas";

type MapFormFields<T extends string> = {
  [Key in keyof CustomerInformationFormValues as T extends ""
    ? Key
    : `${T}.${Key}`]: CustomerInformationFormValues[Key];
};

interface Props<T extends string = ""> {
  nested?: T;
  /* 
    This should use the MapFormFields type, 
    but that gives typescript errors when passing in the
    form for some reason
  */
  form: UseFormReturn<any>;
  editable: boolean;
  className?: string;
}

const WorkOrderLocationSection = <T extends string>(props: Props<T>) => {
  const form = props.form;

  function getName(
    field: Path<CustomerInformationFormValues>
  ): Path<MapFormFields<T>> {
    const prefix = props.nested ? `${props.nested}.` : "";
    return `${prefix}${field}` as Path<MapFormFields<T>>;
  }

  const addressName = getName("workOrderLocationAddress");
  const addressError = useFormError({ form, name: addressName });
  const currentAddress = form.watch(addressName);

  return (
    <section
      className={classNames(
        "flex flex-col gap-4",
        props.editable && "py-4",
        props.className
      )}
    >
      {props.editable && (
        <header className="flex items-center justify-between">
          <h3 className="text-lg font-bold">Utförandeaddress</h3>

          <Controller
            control={form.control}
            name={getName("locationDifferentFromBaseAddress")}
            render={({ field }) => {
              return (
                <IpisButton
                  variant="text"
                  underline
                  size="sm"
                  label="Ta bort"
                  onClick={() => field.onChange(false)}
                />
              );
            }}
          />
        </header>
      )}
      {props.editable && (
        <EASGoogleMapsAddressPicker
          onChange={(address) => {
            form.setValue(addressName, address as any);
          }}
          readOnly={!props.editable}
          currentValue={currentAddress as any}
          isError={!!addressError}
        />
      )}
      {!props.editable && (
        <FormEditableAddressFieldset
          id="workOrderLocationAddress"
          isEditing={false}
          form={props.form}
          legend={"Utförandeaddress"}
          fieldMapping={{
            street: getName("workOrderLocationAddress.street"),
            postalCode: getName("workOrderLocationAddress.postalCode"),
            city: getName("workOrderLocationAddress.city"),
            country: getName("workOrderLocationAddress.country"),
          }}
        />
      )}

      {/* <FormEditableAddressFieldset
        id="workOrderLocationAddress"
        isEditing={props.editable}
        form={form}
        legend="Utförandeaddress"
        fieldMapping={{
          street: getName("workOrderLocationAddress.street"),
          postalCode: getName("workOrderLocationAddress.postalCode"),
          city: getName("workOrderLocationAddress.city"),
          country: getName("workOrderLocationAddress.country"),
        }}
      /> */}
    </section>
  );
};

export default WorkOrderLocationSection;
