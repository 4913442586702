import { classNames } from "../../../../utils/client-utils";
import { RichTextPreTag } from "./RichTextPreTag";

interface Props {
  className?: string;
  html: string;
}

export const IpisFormInputNote = (props: Props) => {
  return (
    <RichTextPreTag
      html={props.html}
      className={classNames(
        props.className,
        "text-xs text-dark-500 sm:text-sm"
      )}
    />
  );
};
