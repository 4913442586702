import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { UUID } from "@eljouren/utils";
import { ToastList } from "./ToastList";
import { AlertDialog } from "./AlertDialog";
import {
  AlertArgs,
  ConfirmArgs,
  ConfirmDialogAnswerArgs,
  SingleActionModalArgs,
  ToastArgs,
  ToastWithId,
} from "./ipis-window-modal-types";
import { SingleActionDialog } from "./SingleActionDialog";

export type AlertState = {
  type: "alert";
  args: AlertArgs;
};
export type ConfirmState = {
  type: "confirm";
  args: ConfirmArgs;
};
export type ToastState = {
  type: "toast";
  toasts: ToastWithId[];
};
export type SingleActionState = {
  type: "singleAction";
  args: SingleActionModalArgs;
};

export type State = AlertState | ConfirmState | ToastState | SingleActionState;

export const IpisWindowModalsProvider = () => {
  const [show, setShow] = useState<State | null>(null);
  const showRef = useRef<State | null>(show);
  const confirmPromiseResolveFunctionRef = useRef<
    ((answer: boolean) => void) | null
  >(null);

  function alert(args: AlertArgs) {
    setShow({ type: "alert", args });
  }

  function confirm(args: ConfirmArgs) {
    if (confirmPromiseResolveFunctionRef.current) {
      Promise.reject(confirmPromiseResolveFunctionRef.current);
    }

    let resolve: (value: boolean) => void;
    const promise = new Promise<boolean>((res) => {
      resolve = res;
    });

    confirmPromiseResolveFunctionRef.current = resolve!;
    setShow({ type: "confirm", args });
    return promise;
  }

  function toast(args: ToastArgs) {
    const id = args.id ?? UUID.generate().value;
    const current = showRef.current;
    const newToast = { ...args, id };

    if (current?.type === "toast") {
      if (current.toasts.find((toast) => toast.id === id)) {
        return;
      }

      const copy = { ...current };
      copy.toasts.push(newToast);
      setShow(copy);
    } else {
      setShow({ type: "toast", toasts: [newToast] });
    }
  }

  function singleAction(args: SingleActionModalArgs) {
    setShow({ type: "singleAction", args });
  }

  useEffect(() => {
    window.ipisModal = {
      alert,
      confirm,
      toast,
      singleAction,
    };
  }, []);

  useEffect(() => {
    showRef.current = show;
  }, [show]);

  function closeAlert() {
    //window.error.consume();
    setShow(null);
  }

  function closeToast(toast: ToastWithId) {
    if (show?.type !== "toast") {
      return;
    }
    const copy = { ...show };
    const filtered = copy.toasts.filter((el) => el.id !== toast.id);
    copy.toasts = filtered;

    if (filtered.length > 0) {
      setShow(copy);
    } else {
      setShow(null);
    }
  }

  function answerConfirmDialog(args: ConfirmDialogAnswerArgs) {
    if (show?.type !== "confirm") {
      return;
    }
    if (show.args.callback) {
      show.args.callback(args);
    }

    if (confirmPromiseResolveFunctionRef.current) {
      confirmPromiseResolveFunctionRef.current(args.answer);
      confirmPromiseResolveFunctionRef.current = null;
    }

    setShow(null);
  }

  function confirmSingleAction() {
    if (show?.type !== "singleAction") {
      return;
    }
    show.args.callback();
    setShow(null);
  }

  return (
    <AnimatePresence>
      {!show && <></>}
      {show?.type === "alert" && (
        <AlertDialog key="alert" {...show} close={closeAlert} />
      )}
      {show?.type === "confirm" && (
        <AlertDialog key="confirm" {...show} answer={answerConfirmDialog} />
      )}
      {show?.type === "toast" && (
        <ToastList
          key="toastList"
          toasts={show.toasts}
          closeToast={closeToast}
        />
      )}
      {show?.type === "singleAction" && (
        <SingleActionDialog
          key="singleAction"
          {...show}
          confirm={confirmSingleAction}
        />
      )}
    </AnimatePresence>
  );
};
