import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { useOnEnterClick } from "../../../hooks/keyboard-event-hooks";
import { classNames } from "../../../utils/client-utils";
import { IpisButton } from "../../buttons/IpisButton";
import { SingleActionState } from "./IpisWindowModalsProvider";
import { IpisCheckmarkIcon } from "../../icons/IpisCheckmarkIcon";
import { IpisErrorIcon } from "../../icons/IpisErrorIcon";

type Props = SingleActionState & {
  confirm: () => void;
};

export const SingleActionDialog = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ref.current?.focus();
  });
  useOnEnterClick({
    ref: ref,
    callback: () => {
      props.confirm();
    },
  });

  const args = props.args;
  return (
    <motion.div
      className="fixed z-[70] flex h-full w-full flex-col items-center justify-center bg-black/40 p-2 xs:p-4 sm:p-8"
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      role="alertdialog"
      aria-labelledby="alertTitle"
      aria-describedby="alertPrompt"
    >
      <div
        className={classNames(
          "pointer-events-auto flex w-[450px] max-w-full flex-col rounded border bg-light-background p-4 shadow-lg"
        )}
        role="document"
        tabIndex={0}
        ref={ref}
      >
        <h2
          id="alertTitle"
          className="break-words text-xl font-bold text-dark-950"
        >
          {args.title}
        </h2>
        {args.icon === "success" && (
          <IpisCheckmarkIcon
            variant="circle"
            success
            size={42}
            className="mx-auto mt-8"
          />
        )}
        {args.icon === "error" && (
          <IpisErrorIcon size={42} className="mx-auto mt-8 text-error" />
        )}
        <p id="alertPrompt" className="py-8 text-base text-dark-800">
          {args.body}
        </p>
        <IpisButton
          label={args.actionLabel}
          onClick={props.confirm}
          className="mx-auto mt-2 min-w-[200px] max-w-full"
          variant="secondary-on-light-background"
        />
      </div>
    </motion.div>
  );
};
