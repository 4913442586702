import {
  HTTPHeaders,
  createTRPCProxyClient,
  httpBatchLink,
} from "@trpc/client";
import SuperJSON from "superjson";
import type { TDomainAppRouter } from "../../server/dist/routes/domain/DomainAppRouter";
import type { TAppRouter } from "../../case-creation/src/routes/app/AppRouter";
import EnvironmentVariables from "./EAS_EnvironmentVariables";
import AuthenticationManager from "./authentication/EAS_AuthenticationManager";

function headers() {
  const headers: HTTPHeaders | undefined =
    AuthenticationManager.getInstance().getOptionalAuthHeader();
  function getCookie(cookieName: string) {
    const cookieArray = document.cookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();

      if (cookie.indexOf(cookieName + "=") === 0) {
        return cookie.substring((cookieName + "=").length);
      }
    }

    return null;
  }

  /* 
    Vercel rebuild comment
  */
  const csrfToken = getCookie("csrf-token") ?? "";
  const withCsrf = {
    ...(headers ?? {}),
    "x-csrf-token": csrfToken,
  };

  return withCsrf;
}

export const mainProxyTrpcClient = createTRPCProxyClient<TDomainAppRouter>({
  transformer: SuperJSON,
  links: [
    httpBatchLink({
      url: EnvironmentVariables.get().mainProxyTrpcUrl,
      headers,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});

export const easProxyTrpcClient = createTRPCProxyClient<TAppRouter>({
  transformer: SuperJSON,
  links: [
    httpBatchLink({
      url: EnvironmentVariables.get().easProxyTrpcUrl,
      headers,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});
