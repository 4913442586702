import React from "react";
import { FieldValues } from "react-hook-form";
import { useFormInputContext } from "../../../../contexts/form-input-context";
import {
  FormInputShell,
  FormInputShellPublicProps,
} from "../components/FormInputShell";
import {
  useFormRegisterProps,
  useFormValue,
} from "../../../../hooks/form-hooks";

interface Props<T extends FieldValues> extends FormInputShellPublicProps<T> {
  rows: number;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  inputProps?: Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    "minLength" | "maxLength" | "disabled" | "placeholder" | "rows"
  >;
}

export const IpisTextArea = <T extends FieldValues>(props: Props<T>) => {
  const ctx = useFormInputContext();
  const currentValue = useFormValue(props);
  const regProps = useFormRegisterProps({
    props,
    type: "textarea",
  });
  const currentLength = currentValue?.length;

  const readOnly = ctx?.readOnly || props.inputProps?.readOnly;

  return (
    <FormInputShell {...props}>
      <div className="col-span-2 grid w-full grid-cols-1 grid-rows-[minmax(0,1fr),auto] rounded border border-border-color bg-input-background outline-1 focus-within:outline">
        <textarea
          id={props.id}
          aria-placeholder={props.placeholder}
          className="col-span-2 w-full  bg-input-background p-4 focus:outline-0 focus:outline-black"
          {...regProps}
          disabled={!!props.disabled}
          readOnly={!!ctx?.readOnly}
          rows={props.rows}
          {...props.inputProps}
        />
        {!readOnly && (
          <div
            className="grid grid-cols-[minmax(0,1fr),auto] grid-rows-1 gap-2 p-4 text-xs text-semi-faded"
            onClick={() => document.getElementById(props.id)?.focus()}
          >
            <p aria-hidden className="">
              {props.placeholder}
            </p>
            {!!props.maxLength && (
              <p>
                <span aria-label="Antal tecken">{currentLength ?? 0}</span>
                <span aria-hidden>/</span>
                <span aria-label="Max antal tecken">{props.maxLength}</span>
              </p>
            )}
          </div>
        )}
      </div>
    </FormInputShell>
  );
};
