import { IpisSelect } from "@ipis/client-essentials";
import { useBookingFlowContext } from "../../contexts/booking-flow-context";

const CostCenterDropdown = () => {
  const ctx = useBookingFlowContext();

  const all = ctx.costCenters;
  const selected = ctx.selectedCostCenter;

  if (!all || !selected) {
    return undefined;
  }

  return (
    <IpisSelect
      id="cost-center-dropdown"
      name="costCenter"
      transparent
      onDarkBackground
      options={all.map((cc) => {
        return {
          value: cc.id,
          label: cc.name,
        };
      })}
      controls={{
        value: selected.id,
        onChange: (id: string) => {
          const cc = all.find((cc) => cc.id === id);
          if (cc) {
            ctx.selectCostCenter(cc);
          }
        },
      }}
    />
  );
};

export default CostCenterDropdown;
