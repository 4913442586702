import { MotionProps, motion } from "framer-motion";
import React from "react";
import { useTailwindColors } from "../../../../hooks/tailwind-hooks";
import { classNames } from "../../../../utils/client-utils";

interface Props {
  className?: string;
  id?: string;
  isFocused: boolean;
  isHovered: boolean;
  onBlur?: (e: React.FocusEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  children?: React.ReactNode;
  label?: {
    text: string;
    htmlFor?: string;
  };
  inputBackground?: string;
  rows?: number;
  motionProps?: MotionProps;
  size: "sm" | "md" | "lg";
  leftSidePaddingInPixels: number;
  readOnly?: boolean;
  onDarkBackground?: boolean;
  required?: boolean;
}

export const FauxFieldsetWrapper = (props: Props) => {
  const { className, children } = props;
  const colors = useTailwindColors();

  let borderWidth: number;
  let borderColor: string;

  const treatAsHovered = props.isHovered && !props.readOnly;

  if (props.isFocused) {
    borderWidth = 1.2;
    borderColor = props.onDarkBackground
      ? colors["border-color"] + "bb"
      : colors.dark[950];
  } else if (treatAsHovered) {
    borderWidth = 0.75;
    borderColor = props.onDarkBackground
      ? colors["border-color"] + "bb"
      : colors.dark[900];
  } else {
    borderWidth = 0.75;
    borderColor = colors["border-color"];
  }

  const inputBackground = props.inputBackground ?? "transparent";

  const { style, ...restMotionProps } = props.motionProps ?? {};

  return (
    <motion.div
      /* 
        I don't understand why the mb-auto is needed for it to not grow in height
       */
      className={classNames(className, "")}
      style={{
        display: "grid",
        gridTemplateColumns: `${props.leftSidePaddingInPixels}px auto 1fr`,
        gridTemplateRows: `repeat(${props.rows ?? 1}, min-content)`,
        ...props.motionProps?.style,
      }}
      {...restMotionProps}
    >
      <motion.div
        transition={{
          duration: 0.2,
        }}
        aria-hidden
        className={"col-span-1 col-start-1 h-full w-full rounded-l"}
        animate={{
          borderLeftWidth: borderWidth,
          borderTopWidth: borderWidth,
          borderBottomWidth: borderWidth,
          borderColor,
        }}
        style={{
          gridRow: `1 / span ${props.rows ?? 1}`,
        }}
      />
      <motion.div
        transition={{
          duration: 0.2,
        }}
        aria-hidden
        className={"col-start-3 h-full w-full rounded-r"}
        animate={{
          borderRightWidth: borderWidth,
          borderTopWidth: borderWidth,
          borderBottomWidth: borderWidth,
          borderColor,
        }}
        style={{
          gridRow: `1 / span ${props.rows ?? 1}`,
        }}
      />
      <motion.div
        transition={{
          duration: 0.2,
        }}
        aria-hidden
        className={"col-start-2 h-full w-full"}
        animate={{
          borderBottomWidth: borderWidth,
          borderColor,
        }}
        style={{
          gridRow: `1 / span ${props.rows ?? 1}`,
        }}
      />
      {!!props.label && (
        <motion.label
          className={classNames(
            "z-10 col-start-2 row-start-1 mb-auto flex px-2",
            !props.onDarkBackground && "text-dark-950",
            props.onDarkBackground && "text-light-background",
            props.size === "sm" && "text-sm",
            props.size === "md" && "text-md",
            props.size === "lg" && "text-lg"
          )}
          htmlFor={props.label.htmlFor}
          style={{
            transform: "translateY(calc(-50% + 1px))",
            background: `linear-gradient(to bottom, transparent calc(50%), ${inputBackground} calc(50%))`,
          }}
        >
          <span>{props.label.text}</span>
          {props.required && <span>{"*"}</span>}
        </motion.label>
      )}
      {children}
    </motion.div>
  );
};
