import { useEffect, useState } from "react";

/* 
  Lmao probably a better way of doing this
*/
type ValidKey =
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "g"
  | "h"
  | "i"
  | "j"
  | "k"
  | "l"
  | "m"
  | "n"
  | "o"
  | "p"
  | "q"
  | "r"
  | "s"
  | "t"
  | "u"
  | "v"
  | "w"
  | "x"
  | "y"
  | "z"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "Enter"
  | "Escape"
  | "Backspace"
  | "Delete"
  | "ArrowUp"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "Tab"
  | "Shift"
  | "Control"
  | "Alt"
  | "Meta";

export interface KeyboardShortcutOptions {
  key: ValidKey;
  ctrlKey?: boolean;
  altKey?: boolean;
  shiftKey?: boolean;
  metaKey?: boolean;
  preventDefault?: boolean;
  callback: (e: KeyboardEvent) => void;
}

export function useKeyboardShortcuts(
  options: KeyboardShortcutOptions,
  dependencies: any[] = []
): void {
  useEffect(
    () => {
      const handleKeyDown = (event: KeyboardEvent) => {
        /* console.log({
        key: event.key,
        ctrlKey: event.ctrlKey,
        altKey: event.altKey,
        shiftKey: event.shiftKey,
        metaKey: event.metaKey,
      }); */
        const { key, ctrlKey, altKey, shiftKey, metaKey } = options;
        if (!event.key) {
          return;
        }
        if (
          event.key.toLowerCase() === key.toLowerCase() &&
          event.ctrlKey === !!ctrlKey &&
          event.altKey === !!altKey &&
          event.shiftKey === !!shiftKey &&
          event.metaKey === !!metaKey
        ) {
          if (options.preventDefault) {
            event.preventDefault();
          }
          options.callback(event);
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    },
    // eslint-disable-next-line
    [options, options.callback, ...dependencies]
  );
}

export function useRedo(callback: () => void): void {
  useKeyboardShortcuts({ key: "z", ctrlKey: true, shiftKey: true, callback });
}

export function useUndo(callback: () => void): void {
  useKeyboardShortcuts({ key: "z", ctrlKey: true, callback });
}

export function usePaste(callback: () => void): void {
  useKeyboardShortcuts({ key: "v", ctrlKey: true, callback });
}

export function useCut(callback: () => void): void {
  useKeyboardShortcuts({ key: "x", ctrlKey: true, callback });
}

export function useOnEscapeClick(callback: () => void) {
  useOnClick("Escape", callback);
}

/* 
  Deprecated in favor of useKeyboardShortcuts
*/
function useOnClick(key: string, callback: () => void) {
  const onKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === key) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });
}

let disabledEnterClickListeners = false;
export function useDisableEnterClickListeners() {
  useEffect(() => {
    disabledEnterClickListeners = true;

    return () => {
      disabledEnterClickListeners = false;
    };
  });
}

export function useOnEnterClick(args: {
  ref: React.MutableRefObject<HTMLElement | null | undefined>;
  callback?(): void;
}) {
  useEffect(() => {
    const { ref, callback } = args;
    const _callback = (e: KeyboardEvent) => {
      if (disabledEnterClickListeners) {
        return;
      }
      onEnterClick(e);
    };
    if (ref.current && callback) {
      const { current } = ref;
      current.addEventListener("keypress", _callback);
    }

    return () => {
      ref.current?.removeEventListener("keypress", _callback);
    };
  });

  const onEnterClick = (event: KeyboardEvent) => {
    if (event.code === "Enter") {
      // Not sure what the default is in this case
      event.preventDefault();
      args.callback && args.callback();
    }
  };
}

export function useDetectKeyboardOpen(
  minKeyboardHeight: number = 300,
  defaultValue: boolean = false
): boolean {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);

  useEffect(() => {
    const listener = () => {
      const newState =
        window.screen.height - minKeyboardHeight >
        (window.visualViewport?.height || 0);
      setIsKeyboardOpen(newState);
    };
    window.visualViewport?.addEventListener("resize", listener);
    return () => {
      window.visualViewport?.removeEventListener("resize", listener);
    };
  }, [minKeyboardHeight]);

  return isKeyboardOpen;
}
