import { classNames } from "@ipis/client-essentials";
import { useBookingFlowContext } from "../../contexts/booking-flow-context";
import { BookingTypeService } from "../../eas-trpc-types";

interface Props {
  className?: string;
}

const ImportantInformationSidebar = (props: Props) => {
  const ctx = useBookingFlowContext();

  const cc = ctx.selectedCostCenter;
  if (!cc) {
    return <></>;
  }

  if (!cc.bookingInformationHtml) {
    return <></>;
  }

  const bookingType = ctx.selectedBookingType;
  let service: BookingTypeService | undefined;

  if (bookingType) {
    if (bookingType.availableServices.length === 1) {
      service = bookingType.availableServices[0];
    } else if (ctx.emergencyWorkOrderDetails) {
      service = ctx.emergencyWorkOrderDetails.service;
    } else if (ctx.typeOfService) {
      service = ctx.typeOfService;
    }
  }

  return (
    <aside
      className={classNames(
        "flex w-[350px] flex-col gap-4 divide-y overflow-auto rounded bg-secondary-50 p-4",
        props.className
      )}
    >
      <pre
        className="font-roobert whitespace-pre-wrap break-words"
        dangerouslySetInnerHTML={{
          __html: cc.bookingInformationHtml,
        }}
      />
      {service && <PricingInformation service={service} />}
    </aside>
  );
};

const PricingInformation = (props: { service: BookingTypeService }) => {
  const priceInfo = props.service.priceInfo;

  return (
    <section className="flex flex-col gap-2 pt-4">
      <h3 className="font-bold md:text-lg">
        Prisinfo för {props.service.name}
      </h3>
      <ul className="flex flex-col gap-1 divide-y">
        <PriceInfoListItem label="Ex. moms:" value={priceInfo.priceExclVat} />
        <PriceInfoListItem label="Inkl. moms:" value={priceInfo.priceInclVat} />
        <PriceInfoListItem
          label="Skatteavdrag:"
          value={priceInfo.taxReductionAmount}
        />
        <PriceInfoListItem
          label="Totalt inkl. skatteavdrag, inkl. moms:"
          value={priceInfo.priceAfterTaxReduction}
        />
      </ul>
    </section>
  );
};
const PriceInfoListItem = (props: { label: string; value: number }) => {
  return (
    <li className="grid grid-cols-2 items-center gap-2">
      <span className="text-sm font-semibold text-dark-800">{props.label}</span>
      <span className="">{props.value}:-</span>
    </li>
  );
};

export default ImportantInformationSidebar;
