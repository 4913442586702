import { motion } from "framer-motion";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useFormInputContext } from "../../../../contexts/form-input-context";
import {
  useFormRegisterProps,
  useFormValue,
} from "../../../../hooks/form-hooks";
import { classNames } from "../../../../utils/client-utils";
import { IpisCaretIcon } from "../../../icons/IpisCaretIcon";
import { FauxFieldsetWrapper } from "../components/FauxFieldsetWrapper";
import { FormInputShellPublicProps } from "../components/FormInputShell";

export type IpisSelectCompactOption = {
  value: string;
  label: string;
};

interface Props<T extends FieldValues>
  extends Pick<
    FormInputShellPublicProps<T>,
    "form" | "name" | "controls" | "onBlur" | "onFocus" | "required"
  > {
  id: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  options: IpisSelectCompactOption[];
  readOnly?: boolean;
}

export const IpisSelectCompact = <T extends FieldValues>(props: Props<T>) => {
  const ctx = useFormInputContext();
  const readOnly = props.readOnly || !!ctx?.readOnly;

  const registerProps = useFormRegisterProps({
    props,
    type: "select",
  });

  const currentValue = useFormValue(props, {
    defaultValue: props.options[0]?.value,
  });

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <FauxFieldsetWrapper
      required={props.required}
      isFocused={isFocused}
      isHovered={isHovered}
      label={{
        text: props.label,
        htmlFor: props.id,
      }}
      size="md"
      leftSidePaddingInPixels={8}
      readOnly={readOnly}
      className={classNames(props.className, "relative")}
    >
      <div className="col-span-3 col-start-1 row-start-1 grid grid-cols-[minmax(0,1fr)]">
        <motion.select
          id={props.id}
          className="appearance-none gap-2  rounded bg-transparent px-4 py-4 pr-8 focus:outline-none"
          {...registerProps}
          onFocus={(e) => {
            setIsFocused(true);
            props.onFocus?.(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            registerProps.onBlur?.(e);
            props?.onBlur?.(e);
          }}
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          disabled={props.disabled || readOnly}
          tabIndex={readOnly ? -1 : undefined}
        >
          {props.options.map((option, i) => {
            const key = `${props.id}-${option.value}-${i}`;
            return (
              <OptionComponent
                key={key}
                index={i}
                option={option}
                id={props.id}
                currentValue={currentValue}
                name={props.name}
              />
            );
          })}
        </motion.select>
        <IpisCaretIcon
          direction="down"
          className="absolute right-2 top-1/2 -translate-y-1/2"
        />
      </div>
    </FauxFieldsetWrapper>
  );
};

const OptionComponent = (props: {
  option: {
    value: string;
    label: string;
  };
  index: number;
  id: string;
  currentValue: string;
  name: string;
}) => {
  const id = `${props.id}-${props.option.value}-${props.index}`;
  return (
    <motion.option
      key={id}
      className="focus:outline-4 focus:outline-red-400"
      value={props.option.value}
      label={props.option.label}
    />
  );
};
