import { createContext, useContext } from "react";

export type BackButtonStackCallback = () => void;
export type BeforeBackCallback = () => Promise<boolean>;
export type RemoveFunction = () => void;

export type BackButtonStackContextType = {
  push: (callback: BackButtonStackCallback) => RemoveFunction;
  pop: () => BackButtonStackCallback;
  isEmpty: () => boolean;
  beforeBack: (element: BeforeBackCallback) => RemoveFunction;
  popBeforeBack: () => BeforeBackCallback;
  beforeBackIsEmpty: () => boolean;
};

/* 
  Buggy!
*/
export const BackButtonStackContext = createContext<BackButtonStackContextType>(
  {} as never
);

export function useBackButtonStack(): BackButtonStackContextType {
  return useContext(BackButtonStackContext);
}
