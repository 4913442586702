import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import {
  IpisButton,
  IpisTextArea,
  IpisTextInputCompact,
  useLoadingAction,
} from "@ipis/client-essentials";
import { FieldErrors, useForm } from "react-hook-form";
import { useApiClients } from "../../../contexts/EAS_api-client-context";
import { useBookingFlowContext } from "../../../contexts/booking-flow-context";
import { CostCenter } from "../../../eas-trpc-types";
import ImportantInformationSidebar from "../../components/ImportantInformationSidebar";

const FormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  workOrderNumber: z.string().min(1),
  invoiceNumber: z.string().optional(),
  description: z.string().min(1).max(150),
  phone: z.string(),
  email: z.string().email(),
});

export type FormValues = z.infer<typeof FormSchema>;

interface Props {
  selectedCostcenter: CostCenter;
}

const CustomerSupportDetailsPage = (props: Props) => {
  const { bookingFlow } = useApiClients();
  const ctx = useBookingFlowContext();
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
  });

  const onSubmitAction = useLoadingAction(async (values: FormValues) => {
    try {
      await bookingFlow.createCustomerSupportCase({
        description: values.description,
        firstName: values.firstName,
        lastName: values.lastName,
        invoiceNumber: values.invoiceNumber,
        workOrderNumber: values.workOrderNumber,
        costCenterId: props.selectedCostcenter.id,
        phone: values.phone,
        email: values.email,
      });

      window.ipisModal.singleAction({
        title: "Kundtjänstärende skapat",
        body: "Vi har tagit emot ditt kundtjänstärende!",
        actionLabel: "Tillbaka till startsidan",
        callback: () => {
          ctx.reset();
        },
        icon: "success",
      });
    } catch (er) {
      window.ipisModal.alert({
        title: "Okänt fel",
        prompt:
          "Något gick fel när vi försökte skicka in ditt kundtjänstärende",
        typeOfAlert: "error",
      });
    }
  });

  function onError(errors: FieldErrors<FormValues>) {
    console.log({
      context: "BookingRequestWorkOrderDetailsPage",
      errors,
    });
  }

  return (
    <section className="grid h-full w-full grid-cols-3 grid-rows-[auto,minmax(0,1fr),auto] gap-x-24 gap-y-4">
      <h3 className="col-span-3 text-xl font-bold text-dark-950 md:text-2xl lg:text-3xl">
        Hur kan vi hjälpa kunden?
      </h3>
      <form
        className="col-span-2 mb-auto grid max-h-full grid-cols-2 gap-x-4 gap-y-8 overflow-auto px-2 py-4"
        onSubmit={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
      >
        <IpisTextInputCompact
          id="firstName"
          name="firstName"
          form={form}
          label="Förnamn"
        />
        <IpisTextInputCompact
          id="lastName"
          name="lastName"
          form={form}
          label="Efternamn"
        />

        <IpisTextInputCompact
          id="workOrderNumber"
          name="workOrderNumber"
          form={form}
          label="Uppdragsnummer"
        />

        <IpisTextInputCompact
          id="invoiceNumber"
          name="invoiceNumber"
          form={form}
          label="Fakturanummer (Fortnox)"
        />
        <IpisTextInputCompact
          id="phone"
          name="phone"
          form={form}
          label="Telefonnummer"
          type="tel"
        />
        <IpisTextInputCompact
          id="email"
          name="email"
          form={form}
          label="E-postadress"
          type="email"
        />

        <IpisTextArea
          id="description"
          name="description"
          label="Beställningsbeskrivning"
          form={form}
          rows={2}
          maxLength={150}
          className="col-span-2 mt-6"
        />
      </form>
      <ImportantInformationSidebar />
      <footer className="col-span-3 flex items-center justify-between pt-12">
        <IpisButton
          variant="text"
          underline
          label="Gå tillbaka"
          onClick={ctx.goBack}
        />
        <IpisButton
          variant="primary"
          label="Skicka in"
          onClick={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
          loading={onSubmitAction.isLoading}
          disabled={onSubmitAction.isLoading}
        />
      </footer>
    </section>
  );
};

export default CustomerSupportDetailsPage;
