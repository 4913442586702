import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";

interface Props extends InternalIconProps {
  direction: "left" | "right";
}

const IpisArrowIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  if (props.direction === "right") {
    return <IconArrowRight {...rest} className={props.className} />;
  }

  return <IconArrowLeft {...rest} className={props.className} />;
};
export const IpisArrowIcon = withIconSize(IpisArrowIcon_);
