import {
  FormEditableAddressFieldset,
  FormInputContext,
  IpisRadioGroup,
  IpisTextInputCompact,
} from "@ipis/client-essentials";
import { Path, UseFormReturn } from "react-hook-form";
import { useApiClients } from "../../contexts/EAS_api-client-context";
import { BusinessCustomerInformationValues } from "../../temporary-booking-flow-schemas";
import DifferentLocationController from "./DifferentLocationController";
import SsnLookupComponent from "./SsnLookupComponent";
import WorkOrderLocationSection from "./WorkOrderLocationSection";
import { useState } from "react";

type MapFormFields<T extends string> = {
  [Key in keyof BusinessCustomerInformationValues as T extends ""
    ? Key
    : `${T}.${Key}`]: BusinessCustomerInformationValues[Key];
};

interface Props<T extends string = ""> {
  nested?: T;
  /* 
    Should use the MapFormFields type,
    but that gives typescript errors when passing in the
    form for some reason
  */
  form: UseFormReturn<any>;
  editable: boolean;
}

function getName<T extends string>(
  field: Path<BusinessCustomerInformationValues>,
  props: { nested?: T }
): Path<MapFormFields<T>> {
  const prefix = props.nested ? `${props.nested}.` : "";
  return `${prefix}${field}` as Path<MapFormFields<T>>;
}

const BusinessCustomerInformationFields = <T extends string>(
  props: Props<T>
) => {
  const form = props.form;

  const differentLocationName = getName(
    "locationDifferentFromBaseAddress",
    props
  );
  const differentLocationValue = form.watch(differentLocationName);

  return (
    <section className={"grid grid-cols-1 gap-4"}>
      <IpisTextInputCompact
        id="verificationStatus.businessName"
        form={props.form}
        name={getName("verificationStatus.businessName", props)}
        label="Företagsnamn"
        readOnly
      />
      <FormEditableAddressFieldset
        id="businessAddress"
        isEditing={false}
        form={props.form}
        legend={
          differentLocationValue
            ? "Företagsadress"
            : "Företags- och utförandeadress"
        }
        fieldMapping={{
          street: getName("verificationStatus.businessAddress.street", props),
          postalCode: getName(
            "verificationStatus.businessAddress.postalCode",
            props
          ),
          city: getName("verificationStatus.businessAddress.city", props),
          country: getName("verificationStatus.businessAddress.country", props),
        }}
      />
      {props.editable && (
        <DifferentLocationController form={form} name={differentLocationName} />
      )}
      {differentLocationValue && (
        <WorkOrderLocationSection
          nested={props.nested}
          form={form}
          editable={props.editable}
        />
      )}
      <BusinessCustomerContactPersonSection
        nested={props.nested}
        form={form}
        readOnly={!props.editable}
      />
    </section>
  );
};

const BusinessCustomerContactPersonSection = <T extends string>(props: {
  nested?: T;
  form: UseFormReturn<MapFormFields<T>>;
  readOnly?: boolean;
}) => {
  const { bookingFlow } = useApiClients();
  const [nameSetThroughSsnLookup, setNameSetThroughSsnLookup] = useState(false);

  async function ssnLookup(args: { ssn: string }): Promise<void> {
    if (props.readOnly) {
      return;
    }
    const path = getName("verificationStatus.customerId", props);
    const id = props.form.getValues(path);
    if (typeof id !== "string") {
      window.alert("Företagsid saknas");
      return;
    }
    const res = await bookingFlow.fetchReferencePersonDetails({
      ssn: args.ssn,
      accountId: id,
    });

    const firstNamePath = getName(
      "businessInformation.contactPerson.firstName",
      props
    );
    props.form.setValue(firstNamePath, res.firstName as any);
    const lastNamePath = getName(
      "businessInformation.contactPerson.lastName",
      props
    );
    props.form.setValue(lastNamePath, res.lastName as any);
    const idPath = getName("businessInformation.contactPerson.id", props);
    props.form.setValue(idPath, res.id as any);
    setNameSetThroughSsnLookup(true);
  }

  return (
    <section>
      <h3 className="text-lg font-bold text-dark-950">Kontaktperson</h3>
      <SsnLookupComponent trigger={ssnLookup} readOnly={props.readOnly} />
      <fieldset className="grid grid-cols-2 gap-4 py-4">
        <FormInputContext.Provider
          value={{ readOnly: props.readOnly || nameSetThroughSsnLookup }}
        >
          <IpisTextInputCompact
            id="businessInformation.contactPerson.firstName"
            form={props.form}
            name={getName("businessInformation.contactPerson.firstName", props)}
            label="Förnamn"
            className="mb-auto"
          />
          <IpisTextInputCompact
            id="businessInformation.contactPerson.lastName"
            form={props.form}
            name={getName("businessInformation.contactPerson.lastName", props)}
            label="Efternamn"
            className="mb-auto"
          />
        </FormInputContext.Provider>
        <IpisTextInputCompact
          id="businessInformation.contactPerson.phone"
          form={props.form}
          name={getName("businessInformation.contactPerson.phone", props)}
          label="Telefonnummer"
          className="mb-auto"
        />
        <IpisTextInputCompact
          id="businessInformation.contactPerson.jobTitle"
          form={props.form}
          name={getName("businessInformation.contactPerson.jobTitle", props)}
          label="Titel"
          className="mb-auto"
        />
        <IpisTextInputCompact
          id="businessInformation.invoiceEmail"
          form={props.form}
          name={getName("businessInformation.invoiceEmail", props)}
          label="E-postadress för fakturering"
          className="col-span-2 mb-auto"
          type="email"
          htmlNote="Vi skickar alla fakturarelaterade meddelanden och kvitton här."
        />
        <IpisRadioGroup
          id="businessInformation.invoiceRecipient"
          form={props.form}
          name={getName("businessInformation.invoiceRecipient", props)}
          label="Vem ska faktureras för denna tjänst?"
          options={[
            { label: "Företaget", value: "business-account" },
            { label: "Kontaktpersonen", value: "contact-person" },
          ]}
          hideError
          useUniformWidth
          className="col-span-2 mb-auto"
          readOnly={props.readOnly}
        />
      </fieldset>
    </section>
  );
};

export default BusinessCustomerInformationFields;
