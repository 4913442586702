import { z } from "@ipis/centralized-zod";
import { SsnSchema } from "../../temporary-booking-flow-schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  IpisButton,
  IpisTextInputCompact,
  classNames,
  useLoadingAction,
} from "@ipis/client-essentials";

const FormSchema = z.object({
  ssn: SsnSchema,
});

type FormValues = z.infer<typeof FormSchema>;

export type SsnLookupCallbackValues = {
  firstName: string;
  lastName: string;
};

interface Props {
  trigger: (args: { ssn: string }) => Promise<any>;
  readOnly?: boolean;
}

const SsnLookupComponent = (props: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
  });

  const onSubmitAction = useLoadingAction(async (values: FormValues) => {
    return props.trigger({ ssn: values.ssn });
  });

  return (
    <div
      className={classNames(
        "grid grid-cols-[minmax(0,1fr),auto] gap-4 pt-4",
        onSubmitAction.isLoading && "skeleton"
      )}
    >
      <IpisTextInputCompact
        id="ssn"
        form={form}
        label="Personnummer"
        name="ssn"
        placeholder="ÅÅÅÅMMDD-XXXX"
        className={props.readOnly ? "col-span-2" : ""}
      />
      {!props.readOnly && (
        <IpisButton
          label="Hämta uppgifter"
          onClick={form.handleSubmit(onSubmitAction.triggerSafely)}
          className="mb-auto mt-2"
          variant="secondary-on-light-background"
          loading={onSubmitAction.isLoading}
          disabled={onSubmitAction.isLoading}
        />
      )}
    </div>
  );
};

export default SsnLookupComponent;
