import { useBackButtonStack } from "../contexts/back-button-stack-context";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/* 
  Buggy!

  Needs to be used as an effect instead so that we can cleanup the stack when the component unmounts.
*/
export const useAddToBackButtonStack = (callback: () => void) => {
  const stack = useBackButtonStack();
  useEffect(() => {
    const remove = stack.push(callback);
    return () => {
      remove();
    };
  });
};

export const useManipulateBackButtonStack = () => {
  const stack = useBackButtonStack();

  async function handle(
    args: {
      onlyHandleBeforeBack?: boolean;
    } = {}
  ): Promise<boolean> {
    if (!stack.beforeBackIsEmpty()) {
      const shouldGoBack = await stack.popBeforeBack()();
      if (!shouldGoBack) {
        // We've handled the back button press, so we don't want to go back.
        return true;
      }
    }

    if (!stack.isEmpty()) {
      const callback = stack.pop();
      if (args.onlyHandleBeforeBack) {
        return false;
      }
      callback();
      return true;
    }

    return false;
  }

  return { handle, stack };
};

export function useIsPreviousPageFromCurrentSite() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const internalNavigation = searchParams.get("internalNavigation");

  // If the internalNavigation flag is set, return true
  if (internalNavigation === "true") {
    return true;
  }

  const state = location.state as { previousLocation?: string } | undefined;
  const previousLocation = state?.previousLocation;

  // If the previousLocation is available in state, compare origins
  if (previousLocation) {
    const currentOrigin = window.location.origin;
    const previousOrigin = new URL(previousLocation, currentOrigin).origin;

    // Return true if the origins match, otherwise return false
    return currentOrigin === previousOrigin;
  }

  // Return false if no previousLocation is available
  return false;
}
