import { AnimatePresence, motion } from "framer-motion";
import { IpisLoadingSpinner } from "./IpisLoadingSpinner";

interface Props {
  isVisible: boolean;
}

export const IpisLoadingOverlay = (props: Props) => {
  return (
    <AnimatePresence>
      {props.isVisible && (
        <motion.div
          className="fixed left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-black/20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <IpisLoadingSpinner className="h-12 w-12" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
