import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { useOnOutsideClick } from "../../hooks/user-interaction-hooks";
import { classNames } from "../../utils/client-utils";
import { IpisButton } from "../buttons/IpisButton";

export interface PublicSlideUpModalProps {
  heading?: string;
  anchorToId?: string;
  isOpen: boolean;
}

interface Props extends PublicSlideUpModalProps {
  onClose?: () => void;
  children?: React.ReactNode;
  className?: string;
  fadeOutOtherContent?: boolean;
  preventCloseOnOutsideClick?: boolean;
}

export const SlideUpModal = (props: Props) => {
  if (props.anchorToId) {
    const element = document.getElementById(props.anchorToId);
    if (element) {
      return createPortal(
        <ActualSlideUpModal {...props} position="absolute" />,
        element
      );
    }
  }

  return <ActualSlideUpModal {...props} position="fixed" />;
};

const ActualSlideUpModal = (
  props: Props & {
    position: "fixed" | "absolute";
  }
) => {
  const ref = useRef<any>(null);

  useOnOutsideClick({
    ref,
    callback: () => {
      if (props.isOpen && !props.preventCloseOnOutsideClick) {
        props.onClose?.();
      }
    },
  });

  return (
    <AnimatePresence>
      {props.isOpen && (
        <>
          {props.fadeOutOtherContent && (
            <motion.div
              className="fixed left-0 top-0 z-20 h-full w-full bg-black bg-opacity-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            ></motion.div>
          )}
          <motion.div
            ref={ref}
            className={classNames(
              "bottom-0 left-1/2 z-30 flex w-[600px] max-w-full -translate-x-1/2 flex-col gap-4 rounded-t-lg bg-light-background p-8 pb-16",
              props.position === "fixed" ? "fixed" : "absolute",
              props.className
            )}
            initial={{ y: "150%", x: "-50%", opacity: 0 }}
            animate={{ y: "0%", x: "-50%", opacity: 1 }}
            exit={{ y: "150%", x: "-50%", opacity: 0 }}
            transition={{
              //remove spring
              type: "tween",
            }}
          >
            {(!!props.heading || !!props.onClose) && (
              <motion.header className="flex justify-between">
                {!!props.heading && (
                  <h3 className="text-xl">{props.heading}</h3>
                )}
                {props.onClose && (
                  <IpisButton
                    onClick={props.onClose}
                    onlyIcon
                    label=""
                    className="ml-auto"
                    variant="text"
                    icon={{
                      position: "leading",
                      type: "close",
                    }}
                  />
                )}
              </motion.header>
            )}
            {props.children}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
