import {
  addMilliseconds,
  getWeek,
  getYear,
  setHours,
  setMinutes,
} from "date-fns";
import { easProxyTrpcClient, mainProxyTrpcClient } from "../eas-trpc-setup";
import {
  BookingType,
  BookingTypeService,
  CancelWorkOrderInput,
  ConfirmBusinessOnCallWorkOrderInput,
  ConfirmPrivateOnCallWorkOrderInput,
  CreateBookingRequestCaseInput,
  CreateCustomerSupportCaseInput,
  CreateWorkOrderShellInput,
  CreateWorkOrderShellOutput,
  FetchCostCentersOutput,
  GetWorkOrderHistoryOutput,
} from "../eas-trpc-types";
import { BookingFlowHandyman } from "../temporary-booking-flow-schemas";
import { sv } from "date-fns/locale";
import EnvironmentVariables from "../EAS_EnvironmentVariables";

/* 
	For easier mocking
*/
export type EAS_IBookingFlowApiClient = EAS_BookingFlowApiClient;

export default class EAS_BookingFlowApiClient {
  async getWorkOrderHistory(): Promise<GetWorkOrderHistoryOutput> {
    const days = EnvironmentVariables.get().inProduction ? 1 : 5;
    return easProxyTrpcClient.workOrder.getWorkOrderHistory.query({
      lastNDays: days,
    });
  }

  async cancelWorkOrder(input: CancelWorkOrderInput): Promise<void> {
    const res = await easProxyTrpcClient.workOrder.cancelWorkOrder.mutate(
      input
    );
    if (!res.success) {
      throw new Error("Failed to cancel work order");
    }
  }

  async createWorkOrderShell(
    args: CreateWorkOrderShellInput
  ): Promise<CreateWorkOrderShellOutput> {
    return easProxyTrpcClient.workOrder.createWorkOrderShell.mutate(args);
  }

  async confirmPrivateOnCallWorkOrder(
    args: ConfirmPrivateOnCallWorkOrderInput
  ): Promise<{
    bookingReference: string;
  }> {
    return easProxyTrpcClient.workOrder.confirmPrivateOnCallWorkOrder.mutate(
      args
    );
  }
  async confirmBusinessOnCallWorkOrder(
    args: ConfirmBusinessOnCallWorkOrderInput
  ): Promise<{
    bookingReference: string;
  }> {
    return easProxyTrpcClient.workOrder.confirmBusinessOnCallWorkOrder.mutate(
      args
    );
  }

  async createBookingRequestCase(input: CreateBookingRequestCaseInput) {
    return easProxyTrpcClient.case.createBookingRequestCase.mutate(input);
  }

  async createCustomerSupportCase(input: CreateCustomerSupportCaseInput) {
    return easProxyTrpcClient.case.createCustomerSupportCase.mutate(input);
  }

  async fetchBrands(): Promise<FetchCostCentersOutput> {
    const res = easProxyTrpcClient.costcenter.fetchCostCenters.query();
    return res;
  }

  async fetchPrivateCustomerVerification(args: { ssn: string }) {
    return easProxyTrpcClient.checkbiz.fetchPrivateCustomer.query({
      ssn: args.ssn,
    });
  }

  async fetchBusinessCustomerVerification(args: { orgnr: string }) {
    return easProxyTrpcClient.checkbiz.fetchBusinessCustomer.query({
      orgnr: args.orgnr,
    });
  }

  async fetchReferencePersonDetails(args: { ssn: string; accountId: string }) {
    return easProxyTrpcClient.checkbiz.fetchReferencePerson.query({
      ssn: args.ssn,
      accountId: args.accountId,
    });
  }

  async fetchSuggestedHandymen(args: {
    service: BookingType;
    skill: BookingTypeService;
    address: {
      formattedAddress: string;
      latitude: number;
      longitude: number;
    };
  }): Promise<BookingFlowHandyman[]> {
    if (!EnvironmentVariables.get().inProduction) {
      return [
        {
          id: "003JX00000LqtmyYAB",
          fullName: "John Doe",
          phone: "0701234567",
          earliestStart: new Date(),
          travelInfoText: "5 minuter",
        },
      ];
    }

    // 3 hours
    const TIME_ESTIMATE_IN_MINUTES = 3 * 60;

    const start = setHours(setMinutes(new Date(), 0), 8);
    const end = setHours(setMinutes(new Date(), 0), 16);
    const DEFAULT_WORKING_HOURS: { start: string; end: string } = {
      start: start.toUTCString(),
      end: end.toUTCString(),
    };

    const day = start.getUTCDay();
    let index: number;
    if (day === 0) {
      index = 6;
    } else {
      index = day - 1;
    }

    const weekDayTuples = Array(7)
      .fill(null)
      .map((_, i) => {
        return index === i;
      }) as [boolean, boolean, boolean, boolean, boolean, boolean, boolean];

    const response =
      await mainProxyTrpcClient.bookingsRouter.findWorkOrderBookingSuggestions.query(
        {
          address: {
            name: args.address.formattedAddress,
            latitude: args.address.latitude,
            longitude: args.address.longitude,
          },
          timeEstimateInMinutes: TIME_ESTIMATE_IN_MINUTES,
          qualification: args.skill.skill,
          week: getWeek(start, {
            locale: sv,
          }),
          year: getYear(start),
          isOnDuty: args.service.category === "ON_CALL",
          weekDays: weekDayTuples,
          defaultWorkingHours: DEFAULT_WORKING_HOURS,
        }
      );

    /* 
        There's a lot to discuss here, regarding on how to prioritise the handymen.
    */
    const now = new Date();
    const slots = [...response.orderedSuggestions.flatMap((sug) => sug.slots)]
      .sort((a, b) => {
        /* 
        Sort by earliest start time first
      */
        return Number(a.earliestStart) - Number(b.earliestStart);
      })
      .filter((slot) => {
        /* 
          Filter out the ones where the latest start time is before the current time (this should be done by the backend, probably)
        */
        const now = new Date();
        const latest = slot.latestStart;
        const isAfter = latest.getTime() > now.getTime();
        return isAfter;
      });

    const suggestions: BookingFlowHandyman[] = [];

    const MAX_SUGGESTION_COUNT = 6;
    /* 
      Find the {MAX_SUGGESTION_COUNT} first available handymen/slots, without duplicate handymen
    */
    let i = 0;
    while (suggestions.length < MAX_SUGGESTION_COUNT && i < slots.length) {
      const slot = slots[i];
      const resourceInfo = response.resourceInformation[slot.resourceId];

      const previous = suggestions.find(
        (s) => s.id === resourceInfo.resource.id
      );

      const travelInfo =
        slot.before.travelInfo ?? resourceInfo.travelInfoFromHome;
      const travelInfoMs = travelInfo?.duration.valueInMilliseconds ?? 0;

      let standardizedEarliestStart;
      const earliestStart = slot.earliestStart;
      const slotStartIsBeforeNow = earliestStart.getTime() < now.getTime();
      if (slotStartIsBeforeNow) {
        const now = new Date();
        const mod = addMilliseconds(now, travelInfoMs);
        standardizedEarliestStart = mod;
      } else {
        standardizedEarliestStart = earliestStart;
      }

      const travelInfoText = travelInfo?.duration.text ?? "N/A";
      if (!previous) {
        suggestions.push({
          id: resourceInfo.resource.id,
          fullName:
            resourceInfo.resource.firstName +
            " " +
            resourceInfo.resource.lastName,
          phone: resourceInfo.resource.phone,
          earliestStart: standardizedEarliestStart,
          travelInfoText,
        });
      }

      i++;
    }

    return suggestions;
  }
}
