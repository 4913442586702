import { UseQueryResult } from "react-query";
import { FetchCostCentersOutput } from "../../eas-trpc-types";
import { CostCenterListItem } from "../components/CostCenterListItem";

type Props = {
  ccRes: UseQueryResult<FetchCostCentersOutput>;
  placeholderCount?: number;
};

const ChooseCostCenterPage = (props: Props) => {
  const isError = props.ccRes.isError;
  const costCenters = props.ccRes.data?.brands;
  const hasCostCenters = costCenters && costCenters.length > 0;
  const placeholderCount = props.placeholderCount || 8;
  const placeHolderArr = Array.from({ length: placeholderCount }, (_, i) => i);
  const showPlaceholders = !hasCostCenters && !isError;

  return (
    <section className={"flex w-full max-w-screen-xl flex-col gap-4"}>
      <h2 className="text-base font-bold text-dark-950 sm:text-xl lg:text-2xl">
        Vilket varumärke gäller ärendet?
      </h2>
      {isError && (
        <p className="text-sm text-error">
          Det gick inte att hämta varumärken. Försök igen senare.
        </p>
      )}
      <ul
        className="grid auto-rows-[180px] grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4 lg:mr-auto lg:grid-cols-[repeat(4,200px)]"
        /* variants={StaggerVariants}
				initial="hidden"
				animate="show" */
      >
        {hasCostCenters &&
          costCenters.map((cc) => {
            return <CostCenterListItem key={cc.id} costCenter={cc} />;
          })}
        {showPlaceholders &&
          placeHolderArr.map((i) => {
            return (
              <li
                className="skeleton h-full w-full"
                key={`cost-center-placeholder-${i}`}
                aria-hidden
              ></li>
            );
          })}
      </ul>
    </section>
  );
};

export { ChooseCostCenterPage };
