import { FormElementAnswer } from "@eljouren/domain";
import { motion } from "framer-motion";
import { FieldPath, FieldValues } from "react-hook-form";
import {
  useFormRegisterProps,
  useFormValue,
} from "../../../../hooks/form-hooks";
import { classNames } from "../../../../utils/client-utils";
import {
  FormInputShell,
  FormInputShellPublicProps,
} from "../components/FormInputShell";
import { IpisTextInputCompact } from "../text-input/IpisTextInputCompact";

type Option = {
  value: string;
  label: string;
};

interface Props<T extends FieldValues> extends FormInputShellPublicProps<T> {
  disabled?: boolean;
  placeholder?: string;
  options: Option[];
  allowOther?: boolean;
  otherName?: FieldPath<T>;
  transparent?: boolean;
  onDarkBackground?: boolean;
}

const OTHER = FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION;

export const IpisSelect = <T extends FieldValues>(props: Props<T>) => {
  const options = [...props.options];
  const allowOther = props.allowOther && !!props.otherName;
  if (allowOther) {
    console.log({ allowOther });
    options.push({ value: OTHER, label: "Annat" });
  }

  const currentValue = useFormValue(props);

  const registerProps = useFormRegisterProps({
    props,
    type: "select",
  });

  const currentOption = options.find((o) => o.value === currentValue);

  const clarificationName = props.otherName;
  const otherIsSelected = currentOption?.value === OTHER;
  const requireClarification =
    !!clarificationName && allowOther && otherIsSelected;

  /* console.log({
    clarificationName,
    otherIsSelected,
    allowOther,
    requireClarification,
  }); */

  return (
    <FormInputShell {...props}>
      <motion.div className="col-span-2 flex w-full flex-col gap-8">
        <motion.select
          className={classNames(
            "grid grid-cols-2 gap-2 rounded p-2",
            !props.transparent && "bg-input-background",
            props.transparent && "bg-transparent",
            props.onDarkBackground && "text-white",
            !props.onDarkBackground && "text-black"
          )}
          {...registerProps}
        >
          {options.map((option, i) => {
            const key = `${props.id}-${option.value}-${i}`;
            return (
              <OptionComponent
                key={key}
                index={i}
                option={option}
                id={props.id}
                currentValue={currentValue}
                name={props.name}
              />
            );
          })}
        </motion.select>
        {requireClarification && (
          <IpisTextInputCompact
            required={true}
            id={clarificationName}
            name={clarificationName}
            label={"Vänligen specificera"}
            form={props.form}
            className="col-span-2"
          />
        )}
      </motion.div>
    </FormInputShell>
  );
};

const OptionComponent = (props: {
  option: {
    value: string;
    label: string;
  };
  index: number;
  id: string;
  currentValue: string;
  name: string;
}) => {
  const id = `${props.id}-${props.option.value}-${props.index}`;
  return (
    <motion.option
      key={id}
      className="text-black focus:outline-4 focus:outline-red-400"
      value={props.option.value}
      label={props.option.label}
    />
  );
};
