import { IconToggleLeft } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisYesNoFieldIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconToggleLeft {...rest} className={props.className} />;
};
export const IpisYesNoFieldIcon = withIconSize(IpisYesNoFieldIcon_);
