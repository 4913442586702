import { IconPlus } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";
import { classNames } from "../../utils/client-utils";
//import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "default" | "circle";
}

const IpisPlusIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "circle") {
    return (
      <IconPlus
        {...rest}
        className={classNames(props.className, "rounded-full")}
      />
    );
  }

  return <IconPlus {...rest} className={props.className} />;
};

export const IpisPlusIcon = withIconSize(IpisPlusIcon_);
