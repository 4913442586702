import { classNames } from "../../utils/client-utils";
import GoogleAddressInput, {
  TGoogleAddressInputResponse,
} from "./GoogleAddressInput";

export interface GoogleMapsAddressPickerProps {
  apiKey: string;
  className?: string;
  currentValue?: TGoogleAddressInputResponse;
  onChange?: (address: TGoogleAddressInputResponse) => void;
  readOnly?: boolean;
  isError: boolean;
  requireAllComponents?: boolean;
}

const GoogleMapsAddressPicker = (props: GoogleMapsAddressPickerProps) => {
  let isError: boolean = props.isError;
  if (props.requireAllComponents && props.currentValue) {
    const curr = props.currentValue;
    const missingComponent =
      !curr.city || !curr.street || !curr.postalCode || !curr.country;
    isError = isError || missingComponent;
  }

  return (
    <div
      className={classNames(
        "flex w-full flex-col gap-1 border",
        isError && "border-error",
        !isError && "border-transparent",
        props.className
      )}
    >
      {/* 
  ToDo:
  *  The label is not actually connected to the input
*/}
      <label className="text-base font-bold" htmlFor="address">
        {props.readOnly ? "Adress" : "Sök på adress"}
      </label>
      {!props.readOnly && (
        <GoogleAddressInput
          apiKey={props.apiKey}
          defaultValue={props.currentValue?.formattedAddress}
          /* onBlur={props.field.onBlur} */
          className="border"
          onAddressSelected={(address) => {
            if (props.readOnly) {
              return;
            }
            props.onChange?.(address);
          }}
        />
      )}
      {!props.currentValue && !props.readOnly && (
        <span className="pl-1 text-xs text-dark-gray text-opacity-80">
          Klicka på adressen i dropdownen för att välja adress.
        </span>
      )}
      {!props.currentValue && props.readOnly && (
        <span className="pl-1 text-xs text-dark-gray text-opacity-80">
          Ingen adress vald
        </span>
      )}

      {!!props.currentValue && (
        <ul
          className={classNames(
            "gap-2 text-dark-gray text-opacity-80",
            "grid grid-cols-2 items-center pl-1",
            !props.readOnly && "text-xs"
          )}
        >
          <SelectedAddressListItem
            label="Gata"
            value={props.currentValue.street}
            requireAllComponents={props.requireAllComponents}
            readOnly={props.readOnly}
          />
          <SelectedAddressListItem
            label="Postnummer"
            value={props.currentValue.postalCode}
            requireAllComponents={props.requireAllComponents}
            readOnly={props.readOnly}
          />
          <SelectedAddressListItem
            label="Stad"
            value={props.currentValue.city}
            requireAllComponents={props.requireAllComponents}
            readOnly={props.readOnly}
          />
          <SelectedAddressListItem
            label="Land"
            value={props.currentValue.country}
            requireAllComponents={props.requireAllComponents}
            readOnly={props.readOnly}
          />
        </ul>
      )}
    </div>
  );
};

const SelectedAddressListItem = (props: {
  label: string;
  value: string | undefined;
  readOnly: boolean | undefined;
  requireAllComponents: boolean | undefined;
}) => {
  const isError = !props.value && props.requireAllComponents;

  return (
    <li className={classNames("flex gap-1", isError && "text-error")}>
      <strong>{props.label}:</strong>
      {props.value || "-"}
    </li>
  );
};

export { GoogleMapsAddressPicker };
