import { IconHelpSquareRounded } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisHelpIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconHelpSquareRounded {...rest} className={props.className} />;
};

	export const IpisHelpIcon = withIconSize(IpisHelpIcon_);
	
