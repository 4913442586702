import {
  IpisButton,
  useLoadingAction,
  useOnOutsideClick,
} from "@ipis/client-essentials";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import AuthenticationManager from "../authentication/EAS_AuthenticationManager";
import { useBind } from "../hooks/observable-hooks";
import { useApiClients } from "../contexts/EAS_api-client-context";

const ProfileWidget = () => {
  const { auth } = useApiClients();
  const authState = useBind(AuthenticationManager.getInstance().obs);
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useOnOutsideClick({
    ref,
    callback: () => {
      setShowDropdown(false);
    },
  });

  const signOut = useLoadingAction(async () => {
    await auth.signOut();
  });

  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }

  if (!authState) {
    return <></>;
  }

  return (
    <div className={"relative"} ref={ref}>
      <IpisButton
        onlyIcon
        variant="tonal"
        label="Hantera profil"
        icon={{
          position: "leading",
          type: "profile",
        }}
        onClick={toggleDropdown}
      />
      <AnimatePresence>
        {showDropdown && (
          <motion.div
            className="absolute right-0 top-full z-10 flex min-w-[350px] flex-col gap-2 rounded bg-black/80 p-8"
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 5 }}
            exit={{ opacity: 0, y: -100 }}
          >
            <h3 className="text-lg font-bold">
              {authState.firstName} {authState.lastName}
            </h3>
            <IpisButton
              label="Logga ut"
              onClick={() => {
                AuthenticationManager.getInstance().signOut();
              }}
              disabled={signOut.isLoading}
              loading={signOut.isLoading}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProfileWidget;
