import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import {
  IpisButton,
  IpisSelectCompact,
  IpisTextArea,
} from "@ipis/client-essentials";
import { DeepPartial, FieldErrors, useForm } from "react-hook-form";
import EASGoogleMapsAddressPicker from "../../../components/EASGoogleMapsAddressPicker";
import { useBookingFlowContext } from "../../../contexts/booking-flow-context";
import { BookingType } from "../../../eas-trpc-types";
import {
  BookingFlowHandymanSchema,
  EmergencyCustomerInformationValues,
  EmergencyWorkOrderDetails,
  ExactGoogleAddress,
} from "../../../temporary-booking-flow-schemas";
import BookingFlowHandymanSuggestionComponent from "../../components/BookingFlowHandymanSuggestionComponent";
import ImportantInformationSidebar from "../../components/ImportantInformationSidebar";

const FormSchema = z.object({
  description: z.string().min(1).max(500),
  workOrderLocation: ExactGoogleAddress,
  handyman: BookingFlowHandymanSchema,
});

export type FormValues = z.infer<typeof FormSchema>;

interface Props {
  selectedBookingType: BookingType;
  customerInfo: EmergencyCustomerInformationValues;
  defaultValues: Omit<EmergencyWorkOrderDetails, "typeOfService"> | undefined;
}

const EmergencyWorkOrderDetailsPage = (props: Props) => {
  const ctx = useBookingFlowContext();
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(),
  });

  function getDefaultValues(): DeepPartial<FormValues> {
    if (props.defaultValues) {
      return {
        ...props.defaultValues,
        workOrderLocation: ctx.getEmergencyWorkOrderLocation(
          props.customerInfo
        ),
        handyman: undefined,
      };
    }

    return {
      workOrderLocation: ctx.getEmergencyWorkOrderLocation(props.customerInfo),
    };
  }

  function onSubmit(values: FormValues) {
    const description = values.description;
    const service = ctx.typeOfService;

    if (!service) {
      window.alert("Kunde inte hitta vald tjänst");
      return;
    }

    ctx.setEmergencyWorkOrderDetails({
      description,
      service: service,
      handyman: values.handyman,
    });
  }

  function onError(errors: FieldErrors<FormValues>) {
    console.log({
      context: "EmergencyWorkOrderDetailsPage.onError",
      errors,
    });
  }

  return (
    <section className="grid h-full w-full grid-cols-[minmax(0,1fr),auto] grid-rows-[auto,minmax(0,1fr),auto] gap-x-8  gap-y-4 md:gap-x-12 lg:gap-x-24">
      <h3 className="col-span-2 text-xl font-bold text-dark-950 md:text-2xl lg:text-3xl">
        Ange arbetsorderdetaljer
      </h3>
      <form
        className="grid grid-cols-1 grid-rows-[minmax(0,1fr),auto]"
        onSubmit={form.handleSubmit(onSubmit, onError)}
      >
        <section className="flex flex-col gap-6 overflow-auto py-4 pl-2 pr-4">
          <IpisSelectCompact
            id="serviceId"
            name="serviceId"
            label="Specifik tjänst"
            options={props.selectedBookingType.availableServices.map(
              (service) => {
                return {
                  label: service.name,
                  value: service.productPackageId,
                };
              }
            )}
            controls={{
              value: ctx.typeOfService?.productPackageId,
              onChange: (e) => {
                const service =
                  props.selectedBookingType.availableServices.find(
                    (service) => service.productPackageId === e
                  );
                if (!service) {
                  return;
                }
                ctx.setTypeOfService(service);
              },
            }}
          />

          <IpisTextArea
            id="description"
            label="Beskriv kortfattat uppdraget för hantverkaren"
            form={form}
            placeholder="Ange sammanfattning"
            name="description"
            rows={2}
            maxLength={500}
          />
          <EASGoogleMapsAddressPicker
            readOnly
            currentValue={form.watch("workOrderLocation") as any}
            isError={form.formState.errors.workOrderLocation !== undefined}
          />
          <BookingFlowHandymanSuggestionComponent
            service={props.selectedBookingType}
            address={form.watch("workOrderLocation")}
            isError={form.formState.errors.handyman !== undefined}
            selectedHandyman={form.watch("handyman")}
            selectHandyman={(handyman) => form.setValue("handyman", handyman)}
            className="col-span-3"
            typeOfService={ctx.typeOfService}
          />
        </section>
        <footer className="col-span-3 flex items-center justify-between py-4 pl-2 pr-4">
          <IpisButton
            variant="text"
            underline
            label="Gå tillbaka"
            onClick={ctx.goBack}
          />
          <IpisButton
            variant="secondary-on-light-background"
            label="Fortsätt"
            onClick={form.handleSubmit(onSubmit, onError)}
          />
        </footer>
      </form>
      <ImportantInformationSidebar />
    </section>
  );
};

export default EmergencyWorkOrderDetailsPage;
