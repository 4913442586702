import { FormElementAnswer } from "@eljouren/domain";
import { HTMLMotionProps, motion } from "framer-motion";
import { useRef } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  UseFormReturn,
} from "react-hook-form";
import { useFormInputContext } from "../../../../contexts/form-input-context";
import { useFormValue } from "../../../../hooks/form-hooks";
import { useOnEnterClick } from "../../../../hooks/keyboard-event-hooks";
import { useTailwindColors } from "../../../../hooks/tailwind-hooks";
import { classNames } from "../../../../utils/client-utils";
import {
  FormInputShell,
  FormInputShellPublicProps,
} from "../components/FormInputShell";
import { IpisTextInputCompact } from "../text-input/IpisTextInputCompact";

type Option = {
  value: string | number | boolean;
  label: string;
};

interface Props<T extends FieldValues> extends FormInputShellPublicProps<T> {
  disabled?: boolean;
  placeholder?: string;
  options: Option[];
  onBlur?: () => void;
  onFocus?: () => void;
  allowOther?: boolean;
  otherName?: FieldPath<T>;
  useUniformWidth?: boolean;
  readOnly?: boolean;
}

const OTHER = FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION;

export const IpisRadioGroup = <T extends FieldValues>(props: Props<T>) => {
  const options = [...props.options];
  const allowOther = props.allowOther && !!props.otherName;
  if (allowOther) {
    options.push({ value: OTHER, label: "Annat" });
  }
  const currentValue = useFormValue(props);

  const currentOption = options.find((o) => o.value === currentValue);

  const clarificationName = props.otherName;
  const otherIsSelected = currentOption?.value === OTHER;
  const requireClarification =
    !!clarificationName && allowOther && otherIsSelected;

  const style: React.CSSProperties = props.useUniformWidth
    ? {
        display: "grid",
        gridTemplateColumns: `repeat(${options.length}, 1fr)`,
      }
    : {};

  return (
    <FormInputShell {...props}>
      <motion.section className="col-span-2 flex w-full flex-col gap-4">
        <motion.fieldset
          className="flex flex-wrap gap-2"
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          style={style}
        >
          {options.map((option, i) => {
            const key = `${props.id}-${option.value}-${i}`;
            return (
              <OptionDiv
                key={key}
                option={option}
                id={props.id}
                currentValue={currentValue}
                name={props.name}
                form={props.form}
                controls={props.controls}
                readOnly={props.readOnly}
              />
            );
          })}
        </motion.fieldset>
        {requireClarification && (
          <IpisTextInputCompact
            required={false}
            id={clarificationName}
            name={clarificationName}
            label={"Vänligen specificera"}
            form={props.form}
            className="col-span-2"
          />
        )}
      </motion.section>
    </FormInputShell>
  );
};

const OptionDiv = (props: {
  option: Option;
  id: string;
  currentValue: string | number | boolean;
  name: string;
  form: UseFormReturn<any> | undefined;
  controls?: FormInputShellPublicProps<any>["controls"];
  readOnly?: boolean;
}) => {
  const ctx = useFormInputContext();
  const id = `${props.id}-${props.option.value}`;
  const readOnly = props.readOnly || ctx?.readOnly;
  const isSelected = props.currentValue === props.option.value;

  if (props.form) {
    return (
      <Controller
        name={props.name}
        control={props.form.control}
        render={({ field }) => (
          <DecoupledOptionDiv
            id={id}
            option={props.option}
            name={props.name}
            label={props.option.label}
            readOnly={readOnly}
            isSelected={isSelected}
            inputProps={{
              ...field,
              onChange: (e) => {
                if (e.target.checked) {
                  field.onChange(props.option.value);
                }
              },
            }}
          />
        )}
      />
    );
  } else if (props.controls) {
    return (
      <DecoupledOptionDiv
        id={id}
        option={props.option}
        name={props.name}
        label={props.option.label}
        readOnly={readOnly}
        isSelected={isSelected}
        inputProps={{
          name: props.name,
          onChange: (e) => {
            if (e.target.checked) {
              props.controls!.onChange?.(props.option.value);
            }
          },
        }}
      />
    );
  }

  throw new Error("No form or controls provided");
};

const DecoupledOptionDiv = (props: {
  id: string;
  option: Option;
  name: string;
  label: string;
  readOnly?: boolean;
  isSelected: boolean;
  inputProps: Omit<
    HTMLMotionProps<"input">,
    "className" | "checked" | "type" | "readOnly" | "id"
  >;
}) => {
  const ref = useRef<HTMLLabelElement>(null);
  const colors = useTailwindColors();

  useOnEnterClick({
    ref,
    callback: () => {
      ref.current?.click();
    },
  });

  return (
    <motion.div className="focus:outline-4 focus:outline-red-400">
      <motion.label
        className={classNames(
          "col-start-2 flex items-center justify-center  gap-2 border border-border-color px-[12px] py-[6px] text-center text-base font-semibold",
          !props.readOnly && "cursor-pointer focus:outline focus:outline-focus"
        )}
        htmlFor={props.id}
        tabIndex={0}
        animate={{
          backgroundColor: props.isSelected ? "#000000" : "#00000000",
          color: props.isSelected
            ? colors["light-background"]
            : colors.dark[950],
        }}
        whileHover={{
          backgroundColor: props.readOnly
            ? undefined
            : props.isSelected
            ? "#000000"
            : "#00000010",
        }}
        ref={ref}
      >
        <motion.span>{props.option.label}</motion.span>
      </motion.label>
      <motion.input
        className="sr-only"
        checked={props.isSelected}
        type="radio"
        readOnly={props.readOnly}
        id={props.id}
        {...props.inputProps}
      />
    </motion.div>
  );
};
