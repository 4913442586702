import { Loader } from "@googlemaps/js-api-loader";
import { useCallback, useEffect, useMemo, useState } from "react";

let loader: Loader | null = null;
const STATE: {
  loading: boolean;
  places: Places | null;
} = {
  loading: false,
  places: null,
};

type Places = google.maps.places.PlacesService;

function loadPlaces(args: {
  callback: (places: Places) => void;
  apiKey: string;
}) {
  if (STATE.loading || STATE.places) {
    if (STATE.places) {
      args.callback(STATE.places);
    }
    return;
  }

  if (!loader) {
    loader = new Loader({
      apiKey: args.apiKey,
      version: "weekly",
      language: "sv",
      region: "SE",
      //...additionalOptions,
    });
  }

  STATE.loading = true;
  loader.importLibrary("places").then((places) => {
    STATE.places = places;
    STATE.loading = false;
    args.callback(places);
  });
}

type Props = {
  apiKey: string;
};

export const useGooglePlaces = (props: Props) => {
  const [places, setPlaces] = useState<Places | null>(STATE.places);
  const apiKey = props.apiKey;
  useEffect(() => {
    if (!places) {
      loadPlaces({
        apiKey,
        callback: setPlaces,
      });
    }
  }, [places, apiKey]);

  return places;
};

export const useGoogleGeocode = (props: Props) => {
  const places = useGooglePlaces(props);
  const geocoder = useMemo(() => {
    if (!places) {
      return null;
    }
    return new google.maps.Geocoder();
  }, [places]);

  const getGeocode = useCallback(
    async (address: string) => {
      if (!geocoder) {
        throw new Error("Geocoder not initialized");
      }

      return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results) {
            if (results.length === 0) {
              reject("No results found");
            } else {
              const [result] = results;
              resolve(result);
            }
          } else {
            reject("Geocoding failed: " + status);
          }
        });
      });
    },
    [geocoder]
  );

  return getGeocode;
};
