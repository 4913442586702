import { IpisButton, classNames } from "@ipis/client-essentials";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

interface Props<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  className?: string;
}

const DifferentLocationController = <T extends FieldValues>(
  props: Props<T>
) => {
  return (
    /* 
		  Ignore can't be used as a jsx component
		  */
    // @ts-ignore
    <Controller
      control={props.form.control}
      name={props.name}
      render={({ field }) => {
        return (
          <IpisButton
            className={classNames("mr-auto", props.className)}
            variant="text"
            underline
            size="sm"
            label="Ska jobbet utföras på en annan adress? Lägg till utförandeadress."
            onClick={() => field.onChange(true)}
          />
        );
      }}
    />
  );
};

export default DifferentLocationController;
