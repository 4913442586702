import { useEffect } from "react";
import { classNames } from "../../utils/client-utils";

interface Props {
  className?: string;
  description?: string;
  error: any;
  tryAgainLabel?: string;
  onTryAgain?(): void;
}

export const IpisUnexpectedError = (props: Props) => {
  const _process: {
    env: Record<string, any>;
  } =
    typeof process === "undefined"
      ? {
          env: {},
        }
      : process;
  const nodeEnv = _process.env["NODE_ENV"];

  useEffect(() => {
    if (nodeEnv === "development" || nodeEnv === "test") {
      console.error(props.error);
    }
  }, [props.error, nodeEnv]);

  return (
    <section
      className={classNames(
        "mx-auto flex max-w-md flex-col gap-4 p-4",
        props.className
      )}
    >
      <header className="flex flex-col gap-2 text-lg font-bold">
        Okänt fel
      </header>
      {/* <main className="mx-auto flex min-w-[250px] flex-col">
        <p className="text-lg">{description}</p>
      </main>
      <footer className="flex justify-end p-2">
        <AppTextButton
          onClick={onTryAgain}
          className="flex items-center gap-2 py-2"
        >
          <AppRefreshIcon size={35} />
          {props.tryAgainLabel || "Ladda om"}
        </AppTextButton>
      </footer> */}
    </section>
  );
};
