import { IconPhotoPlus } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisImageGroupFieldIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconPhotoPlus {...rest} className={props.className} />;
};
export const IpisImageGroupFieldIcon = withIconSize(IpisImageGroupFieldIcon_);
