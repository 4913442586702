import { motion } from "framer-motion";
import { useTailwindColors } from "../../../hooks/tailwind-hooks";
import { classNames } from "../../../utils/client-utils";


interface Props {
  className?: string;
  index: number;
  currentStep: number;
  label: string;
  as?: "li" | "div";
  fixedAtTop?: boolean;
}

const FormProgressIndicatorListItem = (props: Props) => {
  const { index, label, currentStep } = props;

  const colors = useTailwindColors();
  /* 
    Need to make more accessible: Look at the former form progress indicator for direction
  */
  const isCurrentOrPast = index <= currentStep;
  return (
    <Wrapper
      as={props.as ?? "li"}
      key={`checklist-page-indicator-${index}`}
      className={"flex flex-col gap-1 text-center"}
    >
      <motion.span
        aria-hidden
        className={classNames("h-1 sm:h-2 md:h-3 w-full", !props.fixedAtTop && "rounded")}
        initial={{
          background: isCurrentOrPast ? colors.cta : colors["input-background"],
        }}
        animate={{
          background: isCurrentOrPast ? colors.cta : colors["input-background"],
        }}
      />
      <span className="text-2xs sm:text-1xs md:text-base text-semi-faded">{label}</span>
    </Wrapper>
  );
};

const Wrapper = (props: {
  as: "li" | "div";
  children?: React.ReactNode;
  className?: string;
}) => {
  if (props.as === "li") {
    return <motion.li className={props.className}>{props.children}</motion.li>;
  } else {
    return (
      <motion.div className={props.className}>{props.children}</motion.div>
    );
  }
};

export {FormProgressIndicatorListItem};
