import { useEffect, useState } from "react";

import { Utils } from "@eljouren/utils";

type MediaQueryObject = {
  orientation?: "landscape" | "portrait";
} & (
  | {
      minWidth: number;
      maxWidth?: number;
    }
  | {
      minWidth?: number;
      maxWidth: number;
    }
);

export enum TailwindBreakpoint {
  xs = 475,
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  xxl = 1536,
}

function constructQueryString(obj: MediaQueryObject): string {
  return Utils.entries(obj)
    .filter((entry) => entry !== undefined)
    .map((entry) => {
      if (entry === undefined) {
        return "";
      }

      const [key, val] = entry;
      switch (key) {
        case "minWidth":
          return `(min-width: ${val}px)`;
        case "maxWidth":
          return `(max-width: ${val}px)`;
        case "orientation":
          return `(orientation: ${val})`;
        default:
          return "";
      }
    })
    .join(" AND ");
}

function queryObjectFromTailwindBreakpoint(
  breakpoint: TailwindBreakpoint
): MediaQueryObject {
  return {
    minWidth: breakpoint,
  };
}


export function useMediaQueries<T extends keyof typeof TailwindBreakpoint>(
  breakpoints: T[]
): { activeBreakPoint: T | "default" } {
  const [activeBreakPoint, setActiveBreakPoint] = useState<T | "default">(
    "default"
  );

  useEffect(() => {
    const mediaQueryLists = breakpoints.map((bp) => {
      const breakpoint = TailwindBreakpoint[bp];
      const obj = queryObjectFromTailwindBreakpoint(breakpoint);
      const queryString = constructQueryString(obj);
      return window.matchMedia(queryString);
    });

    function determineActiveBreakpoint(): T | "default" {
      for (let i = 0; i < mediaQueryLists.length; i++) {
        if (mediaQueryLists[i].matches) {
          return breakpoints[i];
        }
      }
      return "default";
    }

    function updateActiveBreakpoint() {
      const newBreakpoint = determineActiveBreakpoint();
      if (newBreakpoint !== activeBreakPoint) {
        setActiveBreakPoint(newBreakpoint);
      }
    }

    mediaQueryLists.forEach((mql) =>
      mql.addEventListener("change", updateActiveBreakpoint)
    );

    updateActiveBreakpoint(); // Set the initial value

    return () => {
      mediaQueryLists.forEach((mql) =>
        mql.removeEventListener("change", updateActiveBreakpoint)
      );
    };
  }, [breakpoints, activeBreakPoint]);

  return { activeBreakPoint };
}
