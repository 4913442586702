import { Icon123 } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisNumberFieldIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <Icon123 {...rest} className={props.className} />;
};

export const IpisNumberFieldIcon = withIconSize(IpisNumberFieldIcon_);
