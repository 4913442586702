import {
  FormInputContext,
  IpisButton,
  IpisTextArea,
  IpisTextInputCompact,
  classNames,
} from "@ipis/client-essentials";
import { IconCalendarTime } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useState } from "react";
import { FieldErrors, UseFormReturn, useForm } from "react-hook-form";
import { useBookingFlowContext } from "../../../contexts/booking-flow-context";
import { CostCenter } from "../../../eas-trpc-types";
import {
  BusinessCustomerInformationValues,
  EmergencyCustomerInformationValues,
  EmergencyWorkOrderDetails,
  PrivateCustomerInformationValues,
} from "../../../temporary-booking-flow-schemas";
import BusinessCustomerInformationFields from "../../components/BusinessCustomerInformationFields";
import SelectedHandymanDiv from "../../components/HandymanDiv";
import PrivateCustomerInformationFields from "../../components/PrivateCustomerInformationFields";

type FormValues = {
  comment: string;
};

interface Props {
  costCenter: CostCenter;
  customerInfo: EmergencyCustomerInformationValues;
  workOrderDetails: EmergencyWorkOrderDetails;
}

const EmergencyConfirmationPage = (props: Props) => {
  const ctx = useBookingFlowContext();
  const [confirmationRes, setConfirmationRes] = useState<
    | false
    | {
        bookingReference: string;
      }
  >(false);

  const form = useForm<FormValues>({});

  async function onSubmit(values: FormValues) {
    try {
      const res = await ctx.confirmEmergencyWorkOrder({
        costCenter: props.costCenter,
        customerInfo: props.customerInfo,
        workOrderDetails: props.workOrderDetails,
        comment: values.comment,
      });
      setConfirmationRes(res);
    } catch (er) {
      console.error(er);
    }
  }

  function onError(errors: FieldErrors<FormValues>) {
    console.log(errors);
  }

  return (
    <FormInputContext.Provider value={{ readOnly: true }}>
      <motion.section
        layout
        className={classNames(
          "grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto]",
          confirmationRes && "mx-auto w-1/2",
          !confirmationRes && "w-full"
        )}
      >
        <motion.header className="flex w-full flex-col">
          <h2 className="text-2xl font-bold text-dark-950">
            {!confirmationRes && "Bekräfta jourbokning"}
            {confirmationRes && "Arbetsordern har bekräftats."}
          </h2>
          {confirmationRes && (
            <h3 className="flex items-center gap-1 pb-4 text-dark-500">
              <span>Bokningsreferens:</span>{" "}
              <span>#{confirmationRes.bookingReference}</span>
            </h3>
          )}
        </motion.header>
        <motion.form
          className={classNames(
            "grid h-full w-full gap-12 overflow-auto",
            confirmationRes && "grid-cols-1",
            !confirmationRes && "grid-cols-2"
          )}
          layout
          onSubmit={form.handleSubmit(onSubmit, onError)}
        >
          {props.customerInfo.typeOfCustomer === "private" && (
            <PrivateCustomerSection values={props.customerInfo} />
          )}
          {props.customerInfo.typeOfCustomer === "business" && (
            <BusinessCustomerSection values={props.customerInfo} />
          )}
          <WorkOrderDetailsSection
            values={props.workOrderDetails}
            form={form}
            isConfirmed={!!confirmationRes}
          />
        </motion.form>
        <footer className="flex items-center justify-between pt-12">
          {!confirmationRes && (
            <>
              <IpisButton
                variant="text"
                underline
                label="Gå tillbaka"
                onClick={ctx.goBack}
              />
              <IpisButton
                variant="primary"
                label="Bekräfta"
                onClick={form.handleSubmit(onSubmit, onError)}
              />
            </>
          )}
          {confirmationRes && (
            <IpisButton
              variant="secondary-on-light-background"
              className="ml-auto"
              label="Tillbaka till startsidan"
              onClick={() => {
                ctx.reset();
              }}
            />
          )}
        </footer>
      </motion.section>
    </FormInputContext.Provider>
  );
};

const PrivateCustomerSection = (props: {
  values: PrivateCustomerInformationValues;
}) => {
  const form = useForm<PrivateCustomerInformationValues>({
    defaultValues: props.values,
  });

  return (
    <motion.section className="flex flex-col gap-4" layout>
      <motion.h3 className="text-lg font-bold md:text-xl">
        Personuppgifter
      </motion.h3>
      <IpisTextInputCompact
        id="ssn"
        name="verificationStatus.ssn"
        label="Personnummer"
        form={form}
      />
      <PrivateCustomerInformationFields form={form} editable={false} />
    </motion.section>
  );
};

const BusinessCustomerSection = (props: {
  values: BusinessCustomerInformationValues;
}) => {
  const form = useForm<BusinessCustomerInformationValues>({
    defaultValues: props.values,
  });

  return (
    <motion.section className="flex flex-col gap-4" layout>
      <motion.h3 className="text-lg font-bold md:text-xl">Uppgifter</motion.h3>
      <IpisTextInputCompact
        id="orgNo"
        name="verificationStatus.orgNo"
        label="Organisationsnummer"
        form={form}
      />

      <BusinessCustomerInformationFields form={form} editable={false} />
    </motion.section>
  );
};

const WorkOrderDetailsSection = (props: {
  values: EmergencyWorkOrderDetails;
  form: UseFormReturn<FormValues>;
  isConfirmed: boolean;
}) => {
  const startTimeDate = props.values.handyman.earliestStart;

  /* 
   i.e. 2024-01-01
  */
  const dateString = startTimeDate.toDateString();

  /* 
    i.e. 12:00
  */
  const timeString = startTimeDate.toLocaleTimeString("sv-SE", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <motion.section layout className="flex flex-col gap-4">
      <motion.h3 className="text-lg font-semibold md:text-xl">
        Arbetsorderdetaljer
      </motion.h3>
      <IpisTextArea
        id="description"
        name="description"
        label="Beskrivning"
        controls={{
          value: props.values.description,
        }}
        rows={2}
      />
      <motion.fieldset>
        <motion.legend className="py-2 text-lg font-bold">
          Ansvarig Hantverkare
        </motion.legend>
        <SelectedHandymanDiv handyman={props.values.handyman} />
      </motion.fieldset>
      <motion.fieldset className="py-2">
        <motion.legend className="py-2 text-lg font-bold">
          Schemalagt för
        </motion.legend>
        <motion.p className="grid grid-cols-[auto,minmax(0,1fr),minmax(0,1fr)] gap-4">
          <IconCalendarTime size={24} />
          <IpisTextInputCompact
            id="scheduledFor"
            name="scheduledFor"
            label="Datum"
            controls={{
              value: dateString,
            }}
          />
          <IpisTextInputCompact
            id="startTime"
            name="startTime"
            label="Ungefärlig starttid"
            controls={{
              value: timeString,
            }}
          />
        </motion.p>
      </motion.fieldset>
      <FormInputContext.Provider value={{ readOnly: props.isConfirmed }}>
        <IpisTextInputCompact
          id="comment"
          name="comment"
          label={"Kommentar till hantverkaren"}
          placeholder={props.isConfirmed ? "" : "Lägg till kommentar"}
          form={props.form}
          className="col-span-2"
        />
      </FormInputContext.Provider>
    </motion.section>
  );
};

export default EmergencyConfirmationPage;
