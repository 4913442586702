import { IpisLayout } from "@ipis/client-essentials";
import BookingFlow from "../booking-flow/BookingFlow";
import withBookingAgentSignIn from "../hocs/withBookingAgentSignIn";

const BookingFlowRoute = () => {
  return (
    <IpisLayout>
      <BookingFlow />
    </IpisLayout>
  );
};

export default withBookingAgentSignIn(BookingFlowRoute);
