import { useEffect, useRef } from "react";
import {
  useOnEnterClick,
  useOnEscapeClick,
} from "../../../hooks/keyboard-event-hooks";
import { motion } from "framer-motion";
import { classNames } from "../../../utils/client-utils";
import { IpisButton } from "../../buttons/IpisButton";
import { ConfirmDialogAnswerArgs } from "./ipis-window-modal-types";
import { AlertState, ConfirmState } from "./IpisWindowModalsProvider";

type AlertDialogProps =
  | (AlertState & { close: () => void })
  | (ConfirmState & { answer: (args: ConfirmDialogAnswerArgs) => void });

export const AlertDialog = (props: AlertDialogProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ref.current?.focus();
  });
  useOnEnterClick({
    ref: ref,
    callback: () => {
      if (props.type === "alert") {
        props.close();
      }
    },
  });
  useOnEscapeClick(() => {
    if (props.type === "alert") {
      props.close();
    }
  });

  const isConfirm = props.type === "confirm";
  const yesLabel = (isConfirm && props.args.confirmLabel) || "Ja";
  const noLabel = (isConfirm && props.args.rejectLabel) || "Nej";
  const maxButtonLength = Math.max(yesLabel.length, noLabel.length);
  const splitIntoRows = maxButtonLength > 12;

  return (
    <motion.div
      className="fixed z-[70] flex h-full w-full flex-col items-center justify-center bg-black/40 p-2 xs:p-4 sm:p-8"
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      role="alertdialog"
      aria-labelledby="alertTitle"
      aria-describedby="alertPrompt"
    >
      <div
        className={classNames(
          "pointer-events-auto flex w-[450px] max-w-full flex-col rounded border bg-light-background p-4 shadow-lg"
        )}
        role="document"
        tabIndex={0}
        ref={ref}
      >
        <h2
          id="alertTitle"
          className="break-words text-xl font-bold text-dark-950"
        >
          {props.args.title}
        </h2>
        <p id="alertPrompt" className="pt-4 text-base text-dark-800">
          {props.args.prompt}
        </p>
        {props.type === "alert" && (
          <IpisButton
            label={props.args.buttonLabel ?? "OK"}
            onClick={props.close}
            className="ml-auto mt-2 min-w-[100px]"
          />
        )}
        {props.type === "confirm" && (
          <span
            className={classNames(
              "mt-4 flex flex-col gap-2 sm:mt-8 sm:grid",
              !splitIntoRows && "sm:grid-cols-2",
              splitIntoRows && "sm:grid-cols-1"
            )}
          >
            <IpisButton
              label={props.args.rejectLabel ?? "Nej"}
              variant="text"
              onClick={() => {
                props.answer({ answer: false });
              }}
            />
            <IpisButton
              label={props.args.confirmLabel ?? "Ja"}
              variant="secondary-on-light-background"
              onClick={() => {
                props.answer({ answer: true });
              }}
            />
          </span>
        )}
      </div>
    </motion.div>
  );
};
