import { TailwindBreakpoint } from "@ipis/client-essentials";

const withScreenSizeProtection =
  (_props: { breakpoint: keyof typeof TailwindBreakpoint }) =>
  // Is this bad practice?

  <T extends {}>(WrappedComponent: React.ComponentType<T>) =>
  (props: T) => {
    //const res = useMediaQueries([_props.breakpoint]);
    //const meetsBreakpoint = res.activeBreakPoint === _props.breakpoint;
    const meetsBreakpoint = true;
    const numericValue = TailwindBreakpoint[_props.breakpoint];

    return (
      <>
        {!meetsBreakpoint && (
          <aside className="pointer-events-none fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-black text-white">
            <h2 className="max-w-screen-sm text-lg">
              Tyvärr så är inte den här sidan är inte tillgänglig på skärmar
              mindre än {numericValue}px. Vänligen gör webbläsarfönstret större
              för att fortsätta.
            </h2>
          </aside>
        )}
        <WrappedComponent {...props} />
      </>
    );
  };

export default withScreenSizeProtection;
