import { IpisButton } from "@ipis/client-essentials";
import {
  IconCalendarPlus,
  IconHeadset,
  IconHours24,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useBookingFlowContext } from "../../contexts/booking-flow-context";
import { BookingType, CostCenter } from "../../eas-trpc-types";

type Props = {
  costCenter: CostCenter;
};

const ChooseBookingTypePage = (props: Props) => {
  const ctx = useBookingFlowContext();
  const cc = props.costCenter;
  const allowed = cc.availableBookingTypes;

  return (
    <section
      className={"mr-auto flex h-full w-full max-w-screen-md flex-col gap-4"}
    >
      <h2 className="text-base font-bold text-dark-950  sm:text-xl lg:text-2xl">
        Vilken typ av bokning är det?
      </h2>
      {allowed.map((service) => {
        switch (service.category) {
          case "ON_CALL":
            return (
              <TypeOfServiceButton
                key={service.id}
                heading="Jourarbete"
                subheading="Akuta uppdrag som kräver omedelbar åtgärd"
                icon={<IconHours24 size={40} />}
                service={service}
              />
            );
          case "WORK_ORDER_REQUEST":
            return (
              <TypeOfServiceButton
                key={service.id}
                heading="Bokningsförfrågan"
                subheading="För att schemalägga icke-brådskande tjänster eller möten."
                icon={<IconCalendarPlus size={40} />}
                service={service}
              />
            );
          case "CUSTOMER_SERVICE":
            return (
              <TypeOfServiceButton
                key={service.id}
                heading="Kundservice"
                subheading="För fakturafrågor, ekonomi & support."
                icon={<IconHeadset size={40} />}
                service={service}
              />
            );
        }
      })}

      <footer className="mt-auto py-4">
        <IpisButton
          variant="text"
          underline
          label="Gå tillbaka"
          onClick={ctx.goBack}
        />
      </footer>
    </section>
  );
};

const TypeOfServiceButton = (props: {
  heading: string;
  subheading: string;
  icon: React.ReactElement;
  service: BookingType;
}) => {
  const ctx = useBookingFlowContext();

  return (
    <motion.button
      className="grid w-full grid-cols-[minmax(0,1fr),auto] grid-rows-[min-content,min-content] justify-start border p-6"
      initial={false}
      animate={{
        backgroundColor: "#FFFFFF",
      }}
      whileHover={{
        backgroundColor: "#FCFAED",
      }}
      whileFocus={{
        backgroundColor: "#FCFAED",
      }}
      onClick={() => ctx.selectBookingType(props.service)}
    >
      <h3 className="col-start-1 row-start-1 text-start font-bold text-accent">
        {props.heading}
      </h3>
      <p className="col-start-1 row-start-2 text-start text-sm text-semi-faded">
        {props.subheading}
      </p>
      <span className="col-start-2 row-span-2 row-start-1 m-auto">
        {props.icon}
      </span>
    </motion.button>
  );
};

export { ChooseBookingTypePage as ChooseTypeOfServicePage };
