import { z } from "@ipis/centralized-zod";

const RawSchema = z.object({
  VITE_GOOGLE_MAPS_API_KEY: z.string(),
  VITE_MAIN_PROXY_URL: z.string(),
  VITE_EAS_PROXY_URL: z.string(),
  PROD: z.boolean(),
});

const WithoutTrailingSlash = z
  .string()
  .trim()
  .transform((str) => {
    // Remove trailing slash
    if (str.endsWith("/")) {
      return str.slice(0, -1);
    }
    return str;
  });

export const Schema = z
  .object({
    googleMapsApiKey: z.string().trim(),
    mainProxyUrl: WithoutTrailingSlash,
    easProxyUrl: WithoutTrailingSlash,
    inProduction: z.boolean(),
  })
  .transform((obj) => {
    return {
      ...obj,
      mainProxyTrpcUrl: `${obj.mainProxyUrl}/trpc`,
      mainProxyApiUrl: `${obj.mainProxyUrl}/api`,
      easProxyTrpcUrl: `${obj.easProxyUrl}/trpc`,
    };
  });

export type BeforeTransformation = z.input<typeof Schema>;
export type Type = z.infer<typeof Schema>;

export default class EnvironmentVariables {
  private static CACHE: Type | null = null;

  static get(): Type {
    if (this.CACHE === null) {
      const raw = RawSchema.parse(import.meta.env);
      const beforeTransformation: BeforeTransformation = {
        googleMapsApiKey: raw.VITE_GOOGLE_MAPS_API_KEY,
        mainProxyUrl: raw.VITE_MAIN_PROXY_URL,
        easProxyUrl: raw.VITE_EAS_PROXY_URL,
        inProduction: raw.PROD,
      };
      this.CACHE = Schema.parse(beforeTransformation);
    }
    return this.CACHE;
  }
}
