import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import React, { useRef } from "react";
import { classNames } from "../../utils/client-utils";
import { usePrevious } from "../../hooks/general-hooks";

type Custom = {
  forward: boolean;
  reducedMotion: boolean;
};

type PartialCustom = Partial<Custom>;

const variants = {
  enter: (options: PartialCustom) => {
    let x: number;
    if (options.reducedMotion) {
      x = 0;
    } else {
      x = options.forward ? 1000 : -1000;
    }

    return {
      x,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (options: PartialCustom) => {
    let x: number;
    if (options.reducedMotion) {
      x = 0;
    } else {
      x = !options.forward ? 1000 : -1000;
    }
    return {
      zIndex: 0,
      x,
      opacity: 0,
    };
  },
};

interface Props {
  className?: string;
  preventForwardNavigation?: boolean;
  pageIndex: number;
  minWidth?: number | string;
  minHeight?: number | string;
  gridRow?: number;
  children?: React.ReactNode;
  //mainClassName?: string;
}

export const IpisPagination = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const previousIndex = usePrevious(props.pageIndex);
  const reducedMotion = useReducedMotion();

  function custom(): Custom {
    return {
      forward: props.pageIndex >= (previousIndex || 0),
      reducedMotion: !!reducedMotion,
    };
  }

  return (
    <AnimatePresence initial={false} custom={custom()}>
      <motion.div
        ref={ref}
        className={classNames(
          `col-start-1 col-end-1 h-full`,
          props.className
        )}
        style={{
          gridRowStart: props.gridRow ?? 1,
          gridRowEnd: props.gridRow ?? 1,
        }}
        key={props.pageIndex}
        custom={custom()}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 220, damping: 30, duration: 0.35 },
          opacity: { duration: 0.2 },
        }}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
};


