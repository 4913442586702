import { IconCheck, IconPhone } from "@tabler/icons-react";
import { BookingFlowHandyman } from "../../temporary-booking-flow-schemas";

interface Props {
  handyman: BookingFlowHandyman;
}

const SelectedHandymanDiv = (props: Props) => {
  const h = props.handyman;

  return (
    <div
      className={
        "grid grid-cols-[minmax(0,1fr),auto] gap-4 rounded bg-secondary-100 p-3"
      }
    >
      <h3 className="text-lg font-bold">{h.fullName}</h3>
      <IconCheck size={24} />
      <span className="col-span-2 flex items-center gap-2 py-2">
        <IconPhone size={24} />
        <a href={`tel:${h.phone}`} className="hover:underline">
          {h.phone}
        </a>
      </span>
      <span className="col-span-2 mt-auto font-semibold text-[#4CA154]">
        {/* {h.minutesAwayFromWorkOrderLocation} minuter bort */}
      </span>
    </div>
  );
};

export default SelectedHandymanDiv;
