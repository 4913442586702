import { IconX, IconCircleX } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "default" | "circle";
}

const IpisXIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "circle") {
    return <IconCircleX {...rest} className={props.className} />;
  }

  return <IconX {...rest} className={props.className} />;
};
export const IpisXIcon = withIconSize(IpisXIcon_);
