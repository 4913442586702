import {
  FormProgressIndicator,
  IpisButton,
  IpisDialog,
  IpisLoadingOverlay,
  IpisPage,
  IpisPagination,
  useLoading,
} from "@ipis/client-essentials";
import { useState } from "react";
import { useQuery } from "react-query";
import LogoDark from "../assets/logos/ipis_icon_dark.svg";
import LogoLight from "../assets/logos/ipis_icon_light.svg";
import BookingFlowHistory from "../components/BookingFlowHistory";
import ProfileWidget from "../components/EAS_ProfileWidget";
import { useApiClients } from "../contexts/EAS_api-client-context";
import {
  BookingFlowContext,
  BookingFlowStep,
} from "../contexts/booking-flow-context";
import { BookingType, BookingTypeService, CostCenter } from "../eas-trpc-types";
import withScreenSizeProtection from "../hocs/withScreenSizeProtection";
import {
  BaseGoogleAddressType,
  EmergencyCustomerInformationValues,
  EmergencyWorkOrderDetails,
  TypeOfCustomer,
} from "../temporary-booking-flow-schemas";
import BookingFlowBreadcrumb from "./components/BookingFlowBreadcrumb";
import CostCenterDropdown from "./components/CostCenterDropdown";
import { ChooseTypeOfServicePage } from "./pages/ChooseBookingTypePage";
import { ChooseCostCenterPage } from "./pages/ChooseCostCenterPage";
import BookingRequestWorkOrderDetailsPage from "./pages/booking-request/BookingRequestWorkOrderDetailsPage";
import CustomerSupportDetailsPage from "./pages/customer-support/CustomerSupportDetailsPage";
import EmergencyConfirmationPage from "./pages/emergency/EmergencyConfirmationPage";
import { EmergencyCustomerInformationPage } from "./pages/emergency/EmergencyCustomerInformationPage";
import EmergencyWorkOrderDetailsPage from "./pages/emergency/EmergencyWorkOrderDetailsPage";

type BookingFlowState = {
  selectedCostCenter?: CostCenter;
  typeOfCustomer: TypeOfCustomer;
  bookingType?: BookingType;
  typeOfService?: BookingTypeService;
  customerValues?: EmergencyCustomerInformationValues;
  emergencyWorkOrderDetails?: EmergencyWorkOrderDetails;
  page: BookingFlowStep;
};

enum ProgressIndicatorStep {
  bookingType,
  verification,
  booking,
}

const BookingFlow = () => {
  const { bookingFlow } = useApiClients();

  const costCenterRes = useQuery("costCenters", async () => {
    return bookingFlow.fetchBrands();
  });

  const [flowState, setFlowState] = useState<BookingFlowState>(defaultState());
  const [showHistory, setShowHistory] = useState(false);

  const loader = useLoading();

  function defaultState(): BookingFlowState {
    return {
      typeOfCustomer: "private",
      page: BookingFlowStep.chooseCostCenter,
    };
  }

  function reset() {
    setFlowState(defaultState());
  }

  async function confirmEmergencyWorkOrder(props: {
    costCenter: CostCenter;
    customerInfo: EmergencyCustomerInformationValues;
    workOrderId: string;
    workOrderDetails: EmergencyWorkOrderDetails;
    comment: string;
  }): Promise<{
    bookingReference: string;
  }> {
    const res = await loader.loadWhilePromise(
      _confirmEmergencyWorkOrder(props)
    );
    return res;
  }

  async function _confirmEmergencyWorkOrder(props: {
    costCenter: CostCenter;
    customerInfo: EmergencyCustomerInformationValues;
    workOrderId: string;
    workOrderDetails: EmergencyWorkOrderDetails;
    comment: string;
  }): Promise<{
    bookingReference: string;
  }> {
    try {
      const TIME_ESTIMATE_IN_MINUTES = 120;
      if (props.customerInfo.typeOfCustomer === "private") {
        const res = await bookingFlow.confirmPrivateOnCallWorkOrder({
          //workOrderId: props.workOrderId,
          costCenterId: props.costCenter.id,
          customerId: props.customerInfo.verificationStatus.customerId,
          handymanId: props.workOrderDetails.handyman.id,
          description: props.workOrderDetails.description,
          internalComment: props.comment,
          workOrderLocation: getEmergencyWorkOrderLocation(props.customerInfo),
          service: {
            productPackageId: props.workOrderDetails.service.productPackageId,
            name: props.workOrderDetails.service.name,
          },
          contactDetails: {
            phone:
              props.customerInfo.privateCustomerInformation.contactInformation
                .phone,
          },
          startDate:
            props.workOrderDetails.handyman.earliestStart.toISOString(),
          timeEstimateInMinutes: TIME_ESTIMATE_IN_MINUTES,
        });
        return res;
      } else {
        const businessInfo = props.customerInfo.businessInformation;
        const contactPerson = businessInfo.contactPerson;
        const res = await bookingFlow.confirmBusinessOnCallWorkOrder({
          //workOrderId: props.workOrderId,
          costCenterId: props.costCenter.id,
          customerId: props.customerInfo.verificationStatus.customerId,
          handymanId: props.workOrderDetails.handyman.id,
          invoiceEmail: businessInfo.invoiceEmail,
          businessDetails: {
            id: props.customerInfo.verificationStatus.customerId,
          },
          description: props.workOrderDetails.description,
          internalComment: props.comment,
          workOrderLocation: getEmergencyWorkOrderLocation(props.customerInfo),
          service: {
            productPackageId: props.workOrderDetails.service.productPackageId,
            name: props.workOrderDetails.service.name,
          },
          contactPerson: {
            pregeneratedId: contactPerson.id,
            firstName: contactPerson.firstName,
            lastName: contactPerson.lastName,
            //email: businessInfo.invoiceEmail,
            phone: contactPerson.phone,
            jobTitle: contactPerson.jobTitle,
          },
          startDate:
            props.workOrderDetails.handyman.earliestStart.toISOString(),
          timeEstimateInMinutes: TIME_ESTIMATE_IN_MINUTES,
          invoiceRecipient: businessInfo.invoiceRecipient,
        });
        return res;
      }
    } catch (error) {
      window.alert("Något gick fel, försök igen senare");
      throw error;
    }
  }

  async function selectCostCenter(cc: CostCenter) {
    const page = flowState.page;
    const isDifferent = flowState.selectedCostCenter?.id !== cc.id;
    // trigger vercel please
    if (!isDifferent) {
      if (page === BookingFlowStep.chooseCostCenter) {
        return setFlowState((prev) => {
          return {
            ...prev,
            page: BookingFlowStep.chooseBookingType,
          };
        });
      } else {
        return;
      }
    }

    if (page > BookingFlowStep.chooseBookingType) {
      const res = await window.ipisModal.confirm({
        title: "Är du säker?",
        prompt:
          "Om du byter varumärke kommer du att förlora all information du har fyllt i hittills. Är du säker på att du vill byta varumärke?",
        confirmLabel: "Ja, byt varumärke",
        rejectLabel: "Gå tillbaka",
      });
      if (!res) {
        return;
      }

      setFlowState({
        selectedCostCenter: cc,
        typeOfCustomer: "private",
        page: BookingFlowStep.chooseBookingType,
      });
    } else {
      setFlowState((previousValue) => {
        return {
          ...previousValue,
          selectedCostCenter: cc,
          page: BookingFlowStep.chooseBookingType,
        };
      });
    }
  }

  function selectBookingType(bookingType: BookingType) {
    const cc = flowState.selectedCostCenter;
    // Validate that the cost center is selected
    if (!cc) {
      throw new Error("Cost center not selected");
    }

    // Validate that the typeOfService is in the selected cost center
    const allowedService = cc.availableBookingTypes.find(
      (el) => el.id === bookingType.id
    );

    if (!allowedService) {
      throw new Error("Invalid booking type for cost center");
    }

    let typeOfService: BookingTypeService | undefined;
    if (bookingType.availableServices.length === 1) {
      typeOfService = bookingType.availableServices[0];
    } else {
      typeOfService = undefined;
    }

    let page: BookingFlowStep;
    switch (bookingType.category) {
      case "ON_CALL":
        page = BookingFlowStep.emergencyWorkOrderCustomerInformation;
        break;
      case "WORK_ORDER_REQUEST":
        page = BookingFlowStep.bookingRequestConfirmation;
        break;
      case "CUSTOMER_SERVICE":
        page = BookingFlowStep.customerSupportConfirmation;
        break;
    }

    if (flowState.bookingType?.id !== bookingType.id) {
      setFlowState({
        ...flowState,
        bookingType,
        typeOfService,
        customerValues: undefined,
        emergencyWorkOrderDetails: undefined,
        page,
      });
    } else {
      /* 
        Just update the page
      */
      setFlowState({
        ...flowState,
        page,
      });
    }
  }

  function selectTypeOfSevice(service: BookingTypeService) {
    const state = flowState;
    if (!state.bookingType) {
      throw new Error("Booking type not selected");
    }

    const serviceInBookingType = state.bookingType.availableServices.some(
      (el) =>
        el.productPackageId === service.productPackageId &&
        el.name === service.name
    );

    if (!serviceInBookingType) {
      throw new Error("Service not in selected booking type");
    }

    setFlowState((previousValue) => {
      return {
        ...previousValue,
        typeOfService: service,
      };
    });
  }

  function setTypeOfCustomer(typeOfCustomer: TypeOfCustomer) {
    const page = flowState.page;
    if (page !== BookingFlowStep.emergencyWorkOrderCustomerInformation) {
      throw new Error("Invalid page for setting type of customer");
    }

    setFlowState((previousValue) => {
      return {
        ...previousValue,
        typeOfCustomer,
      };
    });
  }

  async function setCustomerValues(values: EmergencyCustomerInformationValues) {
    const page = flowState.page;
    if (page !== BookingFlowStep.emergencyWorkOrderCustomerInformation) {
      throw new Error("Invalid page for setting customer values");
    }

    const typeOfCustomer = flowState.typeOfCustomer;
    if (values.typeOfCustomer !== typeOfCustomer) {
      throw new Error("Type of customer mismatch");
    }

    setFlowState((previousValue) => {
      return {
        ...previousValue,
        customerValues: values,
        page: BookingFlowStep.emergencyWorkOrderDetails,
      };
    });
  }

  function getEmergencyWorkOrderLocation(
    info: EmergencyCustomerInformationValues
  ): BaseGoogleAddressType {
    if (info.locationDifferentFromBaseAddress) {
      return info.workOrderLocationAddress;
    } else if (info.typeOfCustomer === "business") {
      const address = info.verificationStatus.businessAddress;
      return {
        formattedAddress: `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`,
        ...info.verificationStatus.businessAddress,
        latitude: info.businessInformation.businessAddressCoordinates.lat,
        longitude: info.businessInformation.businessAddressCoordinates.long,
      };
    } else {
      const address = info.verificationStatus.address;
      return {
        formattedAddress: `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`,
        ...info.verificationStatus.address,
        latitude: info.privateCustomerInformation.addressCoordinates.lat,
        longitude: info.privateCustomerInformation.addressCoordinates.long,
      };
    }
  }

  function setEmergencyWorkOrderDetails(details: EmergencyWorkOrderDetails) {
    const page = flowState.page;
    if (page !== BookingFlowStep.emergencyWorkOrderDetails) {
      throw new Error("Invalid page for setting emergency work order details");
    }

    setFlowState((previousValue) => {
      return {
        ...previousValue,
        emergencyWorkOrderDetails: details,
        page: BookingFlowStep.emergencyWorkOrderConfirmation,
      };
    });
  }

  function goBack() {
    const currentPage = flowState.page;
    let previousPage: BookingFlowStep;
    if (currentPage % 100 === 0) {
      previousPage = BookingFlowStep.chooseBookingType;
    } else {
      previousPage = currentPage - 1;
    }

    setFlowState((previousValue) => {
      return {
        ...previousValue,
        page: previousPage,
      };
    });
  }

  function getCurrentProgress(): ProgressIndicatorStep {
    const page = flowState.page;
    switch (page) {
      case BookingFlowStep.chooseCostCenter:
      case BookingFlowStep.chooseBookingType:
        return ProgressIndicatorStep.bookingType;
      case BookingFlowStep.emergencyWorkOrderCustomerInformation:
        return ProgressIndicatorStep.verification;
      case BookingFlowStep.emergencyWorkOrderDetails:
      case BookingFlowStep.emergencyWorkOrderConfirmation:
        return ProgressIndicatorStep.booking;
      case BookingFlowStep.bookingRequestConfirmation:
      case BookingFlowStep.customerSupportConfirmation:
        return ProgressIndicatorStep.booking;
    }
  }

  function getProgressIndicatorSteps(): { label: string }[] {
    return [
      { label: "Servicetyp" },
      { label: "Verifiering" },
      { label: "Bokning" },
    ];
  }

  const page = flowState.page;

  return (
    <BookingFlowContext.Provider
      value={{
        selectedCostCenter: flowState.selectedCostCenter,
        selectCostCenter,
        costCenters: costCenterRes.data?.brands,
        selectedBookingType: flowState.bookingType,
        selectBookingType: selectBookingType,
        typeOfCustomer: flowState.typeOfCustomer,
        typeOfService: flowState.typeOfService,
        setTypeOfService: selectTypeOfSevice,
        setTypeOfCustomer,
        emergencyBookingCustomerValues: flowState.customerValues,
        emergencyWorkOrderDetails: flowState.emergencyWorkOrderDetails,
        setEmergencyWorkOrderDetails,
        getEmergencyWorkOrderLocation,
        setEmergencyBookingCustomerValues: setCustomerValues,
        confirmEmergencyWorkOrder,
        goBack,
        reset,
        step: flowState.page,
      }}
    >
      <IpisDialog isOpen={showHistory} cancel={() => setShowHistory(false)}>
        <BookingFlowHistory close={() => setShowHistory(false)} />
      </IpisDialog>
      <IpisLoadingOverlay isVisible={loader.isLoading} />
      <IpisPage
        headerProps={{
          heading: "Bokningsportal",
          logo: {
            lightUrl: LogoLight,
            darkUrl: LogoDark,
          },
          leftSlot: <CostCenterDropdown />,
          rightSlot: (
            <div className="flex items-center gap-2">
              <IpisButton
                label={"Arbetsorderhistorik"}
                onClick={() => setShowHistory(true)}
                variant="secondary-on-dark-background"
              />
              <ProfileWidget />
            </div>
          ),
        }}
      >
        <section className="grid h-full w-full grid-cols-1 grid-rows-[auto,auto,minmax(0,1fr)]">
          <FormProgressIndicator
            pages={getProgressIndicatorSteps()}
            currentStep={getCurrentProgress()}
            fixedAtTop
          />
          <BookingFlowBreadcrumb className="h-12 px-8 py-4 lg:px-16" />
          <IpisPagination
            pageIndex={page}
            gridRow={3}
            className="px-8 py-4 lg:px-16"
          >
            {page === BookingFlowStep.chooseCostCenter && (
              <ChooseCostCenterPage
                ccRes={costCenterRes}
                placeholderCount={8}
              />
            )}
            {page === BookingFlowStep.chooseBookingType && (
              <ChooseTypeOfServicePage
                costCenter={flowState.selectedCostCenter!}
              />
            )}
            <EmergencyFlowRouter flowState={flowState} />
            <BookingRequestFlowRouter flowState={flowState} />
            <CustomerSupportFlowRouter flowState={flowState} />
          </IpisPagination>
        </section>
      </IpisPage>
    </BookingFlowContext.Provider>
  );
};

const EmergencyFlowRouter = (props: { flowState: BookingFlowState }) => {
  const { flowState } = props;
  const page = flowState.page;

  if (page === BookingFlowStep.emergencyWorkOrderCustomerInformation) {
    return (
      <EmergencyCustomerInformationPage
        costCenter={flowState.selectedCostCenter!}
        defaultValues={flowState.customerValues}
      />
    );
  }

  if (page === BookingFlowStep.emergencyWorkOrderDetails) {
    return (
      <EmergencyWorkOrderDetailsPage
        customerInfo={flowState.customerValues!}
        selectedBookingType={flowState.bookingType!}
        defaultValues={flowState.emergencyWorkOrderDetails}
      />
    );
  }

  if (page === BookingFlowStep.emergencyWorkOrderConfirmation) {
    return (
      <EmergencyConfirmationPage
        costCenter={flowState.selectedCostCenter!}
        customerInfo={flowState.customerValues!}
        workOrderDetails={flowState.emergencyWorkOrderDetails!}
      />
    );
  }

  return <></>;
};

const BookingRequestFlowRouter = (props: { flowState: BookingFlowState }) => {
  const { flowState } = props;
  const page = flowState.page;

  if (page === BookingFlowStep.bookingRequestConfirmation) {
    return (
      <BookingRequestWorkOrderDetailsPage
        selectedCostCenter={flowState.selectedCostCenter!}
      />
    );
  }

  return <></>;
};

const CustomerSupportFlowRouter = (props: { flowState: BookingFlowState }) => {
  const { flowState } = props;
  const page = flowState.page;

  if (page === BookingFlowStep.customerSupportConfirmation) {
    return (
      <CustomerSupportDetailsPage
        selectedCostcenter={flowState.selectedCostCenter!}
      />
    );
  }

  return <></>;
};

export default withScreenSizeProtection({ breakpoint: "xl" })(BookingFlow);
