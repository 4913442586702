import { ExposedIconProps } from "../../../hocs/withIconSize";
import { IpisCaretIcon } from "../IpisCaretIcon";
import { IpisEditIcon } from "../IpisEditIcon";
import { IpisImageGroupFieldIcon } from "../IpisImageGroupFieldIcon";
import { IpisInformationIcon } from "../IpisInformationIcon";
import { IpisMailIcon } from "../IpisMailIcon";
import { IpisMultipleChoiceFieldIcon } from "../IpisMultipleChoiceFieldIcon";
import { IpisNumberFieldIcon } from "../IpisNumberFieldIcon";
import { IpisPlusIcon } from "../IpisPlusIcon";
import { IpisProfileIcon } from "../IpisProfileIcon";
import { IpisTextFieldIcon } from "../IpisTextFieldIcon";
import { IpisTrashIcon } from "../IpisTrashIcon";
import { IpisXIcon } from "../IpisXIcon";
import { IpisYesNoFieldIcon } from "../IpisYesNoFieldIcon";

export type IpisIconIdentifier =
  | "plus"
  | "trash"
  | "information"
  | "close"
  | "text-field"
  | "number-field"
  | "multiple-choice-field"
  | "image-group-field"
  | "yes-no-field"
  | "edit"
  | "caret-left"
  | "mail"
  | "profile";

interface Props extends ExposedIconProps<{}> {
  icon: IpisIconIdentifier;
}

export const IpisIconRouter = (props: Props) => {
  const { icon, ...rest } = props;

  switch (icon) {
    case "plus":
      return <IpisPlusIcon {...rest} />;
    case "trash":
      return <IpisTrashIcon {...rest} />;
    case "information":
      return <IpisInformationIcon {...rest} />;
    case "close":
      return <IpisXIcon {...rest} />;
    case "text-field":
      return <IpisTextFieldIcon {...rest} />;
    case "number-field":
      return <IpisNumberFieldIcon {...rest} />;
    case "multiple-choice-field":
      return <IpisMultipleChoiceFieldIcon {...rest} />;
    case "image-group-field":
      return <IpisImageGroupFieldIcon {...rest} />;
    case "yes-no-field":
      return <IpisYesNoFieldIcon {...rest} />;
    case "edit":
      return <IpisEditIcon {...rest} />;
    case "caret-left":
      return <IpisCaretIcon {...rest} direction="left" />;
    case "mail":
      return <IpisMailIcon {...rest} />;
    case "profile":
      return <IpisProfileIcon {...rest} />;
  }
};
