import { IconListCheck } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisMultipleChoiceFieldIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconListCheck {...rest} className={props.className} />;
};

export const IpisMultipleChoiceFieldIcon = withIconSize(
  IpisMultipleChoiceFieldIcon_
);
