import { IconMail } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisMailIcon_ = (props: Props) => {
  const { className, ...rest } = props;


  return <IconMail {...rest} className={props.className} />;
};
export const IpisMailIcon = withIconSize(IpisMailIcon_);
