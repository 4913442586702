import { useRef, useState } from "react";
import { useOnEscapeClick } from "../hooks/keyboard-event-hooks";
import { useOnOutsideClick } from "../hooks/user-interaction-hooks";
import { ExposedIconProps } from "../hocs/withIconSize";
import { useMediaQueries } from "../hooks/media-hooks";
import { classNames } from "../utils/client-utils";
import { RichTextPreTag } from "./form/form-inputs/components/RichTextPreTag";
import { IpisCaretIcon } from "./icons/IpisCaretIcon";
import { IpisHelpIcon } from "./icons/IpisHelpIcon";

type OpenAlternative = "left" | "right" | "top-left" | "top-right";
type OpenMap = {
  default: OpenAlternative;
  md?: OpenAlternative;
  lg?: OpenAlternative;
  xl?: OpenAlternative;
};

type ClassNamesMap = {
  left: string;
  right: string;
  "top-left": string;
  "top-right": string;
};

type OpenProp = OpenAlternative | OpenMap;

type StringContent = {
  content: string;
  contentClassName?: string;
};

type ElementContent = {
  content: () => JSX.Element;
  contentClassName?: undefined;
};

type Content = StringContent | ElementContent;

type Props = {
  iconProps?: ExposedIconProps<any>;
  className?: string;
  open: OpenProp;
  /* 
    If we want to use aria-describedby, we need to provide an id.
  */
  id?: string;
  customIcon?: () => JSX.Element;
  iconWrapperClassName?: string;
} & Content;

export const InformationTooltip = (props: Props) => {
  const { className, content, open, iconProps } = props;
  const { activeBreakPoint } = useMediaQueries(["md", "lg", "xl"]);

  const isMd = activeBreakPoint !== "default";

  const toggleRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<any | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useOnOutsideClick({
    ref: contentRef,
    callback: () => {
      setIsOpen(false);
    },
    exclude: [toggleRef],
  });

  useOnEscapeClick(() => {
    setIsOpen(false);
  });

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function onMouseOver() {
    if (isMd) {
      setIsHovering(true);
    }
  }

  function onMouseOut() {
    if (isMd) {
      setIsHovering(false);
    }
  }

  const showTooltip = isOpen || isHovering;

  const isString = typeof content === "string";
  const isRichText = typeof content === "object";
  const isFunction = typeof content === "function";

  let openMap: OpenMap;
  if (typeof open === "string") {
    openMap = {
      default: open,
    };
  } else {
    openMap = open;
  }

  const currentDirection = openMap[activeBreakPoint] ?? openMap.default;
  const classNamesMap: ClassNamesMap = {
    left: "right-0 top-full mt-2",
    right: "left-0 top-full mt-2",
    "top-left": "right-0 bottom-full mb-2",
    "top-right": "left-0 bottom-full mb-2",
  };
  const currentClassNames = classNamesMap[currentDirection];

  const contentClassName = classNames(
    "absolute whitespace-pre-wrap rounded bg-dark-900 p-2 text-sm text-light-background overflow-visible",
    currentClassNames,

    props.contentClassName
  );

  return (
    <div
      className={classNames(
        "relative z-30 flex aspect-square items-center justify-center rounded",
        props.customIcon && "border",
        "cursor-pointer",
        className
      )}
      onClick={toggleOpen}
      ref={toggleRef}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      role="tooltip"
      id={props.id}
    >
      <span className={classNames(props.iconWrapperClassName)}>
        {!props.customIcon && (
          <IpisHelpIcon
            {...iconProps}
            className={classNames(
              "hover:fill-dark-200",
              isOpen && "fill-dark-200"
            )}
          />
        )}
        {props.customIcon && props.customIcon()}
      </span>

      {showTooltip && (isString || isRichText) && (
        <div className={contentClassName} ref={contentRef}>
          <TriangleSpan open={currentDirection} />
          <RichTextPreTag html={content} />
        </div>
      )}
      {showTooltip && isFunction && (
        <div className={contentClassName} ref={contentRef}>
          {content()}
        </div>
      )}
    </div>
  );
};

const TriangleSpan = (props: { open: OpenAlternative }) => {
  const top = props.open.startsWith("top");

  return (
    <span
      className={classNames(
        "absolute -z-10 flex text-dark-900",
        props.open === "left" && "bottom-full right-0",
        props.open === "right" && "bottom-full left-0",
        props.open === "top-left" && "right-0 top-full",
        props.open === "top-right" && "left-0 top-full"
      )}
      aria-hidden
    >
      <IpisCaretIcon
        direction={top ? "down" : "up"}
        filled
        size="sm"
        viewBox={top ? "5 12 14 14" : "5 -2 14 14"}
      />
    </span>
  );
};
