import { useEffect, useRef } from "react";
import { useGooglePlaces } from "../../hooks/google-hooks";
import { classNames } from "../../utils/client-utils";

export type TGoogleAddressInputResponse = {
  formattedAddress: string;
  latitude: number;
  longitude: number;
  street: string;
  postalCode: string;
  city: string;
  country: string;
};

interface AddressInputProps extends React.HTMLProps<HTMLInputElement> {
  onAddressSelected?: (address: TGoogleAddressInputResponse) => void;
  className?: string;
  defaultValue?: string;
  apiKey: string;
}

const GoogleAddressInput = (props: AddressInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useGooglePlaces({
    apiKey: props.apiKey,
  });

  const { className, onAddressSelected, apiKey, ...rest } = props;

  useEffect(() => {
    if (!places || !window.google || !inputRef.current) {
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        fields: ["geometry", "formatted_address", "address_components"],
      }
    );

    function parseAddressComponents(
      components: google.maps.GeocoderAddressComponent[]
    ): Pick<
      TGoogleAddressInputResponse,
      "city" | "country" | "street" | "postalCode"
    > {
      const address: Pick<
        TGoogleAddressInputResponse,
        "city" | "country" | "street" | "postalCode"
      > = {
        city: "",
        country: "",
        street: "",
        postalCode: "",
      };

      let streetNumber: string | null = null;

      components.forEach((component) => {
        if (component.types.includes("street_number")) {
          streetNumber = component.long_name;
        }
        if (
          component.types.includes("route") ||
          component.types.includes("street_address")
        ) {
          address.street = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          address.postalCode = component.long_name;
        }
        if (
          component.types.includes("locality") ||
          component.types.includes("postal_town")
        ) {
          address.city = component.long_name;
        }
        if (component.types.includes("country")) {
          address.country = component.long_name;
        }
      });

      if (streetNumber) {
        address.street = `${address.street} ${streetNumber}`;
      }

      return address;
    }

    const listener = autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const formattedAddress = place.formatted_address ?? "";
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        const addressComponents = parseAddressComponents(
          place.address_components ?? []
        );

        const values: TGoogleAddressInputResponse = {
          formattedAddress,
          latitude,
          longitude,
          ...addressComponents,
        };

        onAddressSelected?.(values);
      } else {
        window.alert("Inga detaljer hittades för den här adressen");
      }
    });

    return () => {
      // Remove the listener when the component is unmounted
      window.google.maps.event.removeListener(listener);
    };
  }, [onAddressSelected, places]);

  return (
    <span
      className={classNames(
        props.className,
        "grid grid-cols-[minmax(0,1fr),auto] rounded"
      )}
    >
      <input
        {...rest}
        /* 
          Override the ref intentionally        
        */
        ref={inputRef}
        className="w-full  px-4 py-2"
        type="text"
        placeholder="Skriv in address"
        defaultValue={props.defaultValue}
        onChange={(e) => {
          //check if input is focused
          const ifFocused = document.activeElement === e.target;
          if (!ifFocused) {
            e.target.focus();
          }
        }}
        autoComplete="off"
      />
      {/* <span className="flex items-center justify-center px-2">
        {hasValue && <CheckmarkIcon size={24} className="text-green-600" />}
        {!hasValue && <AppErrorIcon size={24} className="text-red-600" />}
      </span> */}
    </span>
  );
};

export default GoogleAddressInput;
