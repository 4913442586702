import { classNames } from "../../../../utils/client-utils";

interface Props {
  className?: string;
  html: string;
}

export const RichTextPreTag = (props: Props) => {
  // whitespace-pre-wrap text-xs text-dark-500
  return (
    <pre
      className={classNames(
        "whitespace-pre-wrap break-words",
        props.className
      )}
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  );
};
