import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import {
  IpisButton,
  IpisTextArea,
  IpisTextInputCompact,
  useLoadingAction,
} from "@ipis/client-essentials";
import { FieldErrors, useForm } from "react-hook-form";
import EASGoogleMapsAddressPicker from "../../../components/EASGoogleMapsAddressPicker";
import { useApiClients } from "../../../contexts/EAS_api-client-context";
import { useBookingFlowContext } from "../../../contexts/booking-flow-context";
import { CostCenter } from "../../../eas-trpc-types";
import ImportantInformationSidebar from "../../components/ImportantInformationSidebar";
import { PhoneNumberSchema } from "../../../temporary-booking-flow-schemas";

const FormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  address: z.object({
    street: z.string().min(1),
    postalCode: z.string().min(1),
    city: z.string().min(1),
    country: z.string().min(1),
    formattedAddress: z.string().min(1),
    latitude: z.number(),
    longitude: z.number(),
  }),
  phone: PhoneNumberSchema,
  email: z.string().email(),
  description: z.string().min(1).max(150),
});

export type FormValues = z.infer<typeof FormSchema>;

interface Props {
  selectedCostCenter: CostCenter;
}

const BookingRequestWorkOrderDetailsPage = (props: Props) => {
  const { bookingFlow } = useApiClients();
  const ctx = useBookingFlowContext();
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
  });

  const onSubmitAction = useLoadingAction(async (values: FormValues) => {
    try {
      await bookingFlow.createBookingRequestCase({
        description: values.description,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        city: values.address.city,
        street: values.address.street,
        zipCode: values.address.postalCode,
        costCenterId: props.selectedCostCenter.id,
      });
      window.ipisModal.singleAction({
        title: "Bokningsförfrågan skapad",
        body: "Vi har tagit emot din bokningsförfrågan",
        actionLabel: "Tillbaka till startsidan",
        callback: () => {
          ctx.reset();
        },
        icon: "success",
      });
    } catch (er) {
      window.ipisModal.alert({
        title: "Okänt fel",
        prompt:
          "Något gick fel när vi försökte skicka in din bokningsförfrågan",
        typeOfAlert: "error",
      });
    }
  });

  function onError(errors: FieldErrors<FormValues>) {
    console.log({
      context: "BookingRequestWorkOrderDetailsPage",
      errors,
    });
  }

  return (
    <section className="grid h-full w-full grid-cols-3 grid-rows-[auto,minmax(0,1fr),auto] gap-x-24 gap-y-4">
      <h3 className="col-span-3 text-xl font-bold text-dark-950 md:text-2xl lg:text-3xl">
        Vänligen ange bokningsinformation
      </h3>
      <form
        className="col-span-2 mb-auto grid max-h-full grid-cols-2 gap-x-4 gap-y-8 overflow-auto px-2 py-4"
        onSubmit={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
      >
        <IpisTextInputCompact
          id="firstName"
          name="firstName"
          form={form}
          label="Förnamn"
        />
        <IpisTextInputCompact
          id="lastName"
          name="lastName"
          form={form}
          label="Efternamn"
        />
        <EASGoogleMapsAddressPicker
          onChange={(address) => form.setValue("address", address)}
          className="col-span-2"
          currentValue={form.watch("address")}
          isError={!!form.formState.errors.address}
        />

        <IpisTextInputCompact
          id="phone"
          name="phone"
          form={form}
          label="Telefonnummer"
          type="tel"
        />
        <IpisTextInputCompact
          id="email"
          name="email"
          form={form}
          label="E-postadress"
          type="email"
        />
        <IpisTextArea
          id="description"
          name="description"
          label="Beställningsbeskrivning"
          form={form}
          rows={2}
          maxLength={150}
          className="col-span-2 mt-6"
        />
        <footer className="col-span-2 flex items-center justify-between pt-12">
          <IpisButton
            variant="text"
            underline
            label="Gå tillbaka"
            onClick={ctx.goBack}
          />
          <IpisButton
            variant="primary"
            label="Skicka in"
            onClick={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
            loading={onSubmitAction.isLoading}
            disabled={onSubmitAction.isLoading}
          />
        </footer>
      </form>
      <ImportantInformationSidebar />
    </section>
  );
};

export default BookingRequestWorkOrderDetailsPage;
