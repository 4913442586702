import { IpisSignInScreen, useBind } from "@ipis/client-essentials";
import BgImage from "../assets/images/sign-in-bg.png";
import { useApiClients } from "../contexts/EAS_api-client-context";
import AuthenticationManager from "../authentication/EAS_AuthenticationManager";

const withBookingAgentSignIn =
  // Is this bad practice?


    <T extends {}>(WrappedComponent: React.ComponentType<T>) =>
    (props: T) => {
      const { auth } = useApiClients();

      const authState = useBind(AuthenticationManager.getInstance().obs);
      const isSignedIn = !!authState;

      if (isSignedIn) {
        return <WrappedComponent {...props} />;
      }

      async function signIn(args: {
        email: string;
        password: string;
      }): Promise<200 | 404> {
        try {
          const res = await auth.authenticate(args);
          return res;
        } catch (er) {
          throw er;
        }
      }

      return <IpisSignInScreen onSubmit={signIn} bgUrl={BgImage} fixed />;
    };

export default withBookingAgentSignIn;
