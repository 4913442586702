import { classNames } from "@ipis/client-essentials";
import {
  BookingFlowStep,
  useBookingFlowContext,
} from "../../contexts/booking-flow-context";

interface Props {
  className?: string;
}

const BookingFlowBreadcrumb = (props: Props) => {
  const ctx = useBookingFlowContext();
  function getParts(): string[] {
    if (!ctx.selectedCostCenter || !ctx.selectedBookingType) {
      return [];
    }

    if (ctx.step <= BookingFlowStep.chooseBookingType) {
      return [];
    }

    const parts = [];

    switch (ctx.selectedBookingType.category) {
      case "ON_CALL":
        parts.push("Jourarbete");
        break;
      case "WORK_ORDER_REQUEST":
        parts.push("Bokningsförfrågan");
        break;
      case "CUSTOMER_SERVICE":
        parts.push("Kundsupport");
        break;
    }

    switch (ctx.typeOfCustomer) {
      case "business":
        parts.push("Företagskund");
        break;
      case "private":
        parts.push("Privatkund");
        break;
    }

    const isEmergency = ctx.step >= 100 && ctx.step < 200;

    if (isEmergency) {
      if (ctx.step >= BookingFlowStep.emergencyWorkOrderCustomerInformation) {
        switch (ctx.typeOfCustomer) {
          case "business":
            parts.push("Företagsinformation");
            break;
          case "private":
            parts.push("Personuppgifter");
            break;
        }
      }
      if (ctx.step >= BookingFlowStep.emergencyWorkOrderDetails) {
        parts.push("Arbetsorderdetaljer");
      }
      if (ctx.step === BookingFlowStep.emergencyWorkOrderConfirmation) {
        parts.push("Bekräftelse");
      }
    }

    return parts;
  }

  const parts = getParts();
  return (
    <div
      className={classNames(
        "flex flex-col gap-1 text-base lg:flex-row",
        props.className
      )}
    >
      <ul className="flex items-center gap-1">
        {parts.map((part, i) => {
          const isLast = i === parts.length - 1;

          if (isLast) {
            return (
              <span key={part} className="font-bold text-secondary-400">
                {part}
              </span>
            );
          }

          return (
            <li key={part}>
              <span className=" text-semi-faded">{part}</span>
              <span className="text-semi-faded"> / </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BookingFlowBreadcrumb;
