import { BrandTheme } from "@eljouren/domain";
import { createContext, useContext } from "react";

type LayoutContextType = {
  externalBrandTheme?: BrandTheme.Type;
  setExternalBrandTheme: (theme: BrandTheme.Type | undefined) => void;
};

export const IpisLayoutContext = createContext<LayoutContextType>({} as never);

export function useLayout(): LayoutContextType {
  return useContext(IpisLayoutContext);
}
