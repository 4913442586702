import { IconTextRecognition } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";
//import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisTextFieldIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconTextRecognition {...rest} className={props.className} />;
};
export const IpisTextFieldIcon = withIconSize(IpisTextFieldIcon_);
