import { motion } from "framer-motion";
import { classNames } from "../../../../utils/client-utils";

type Props = {
  id: string;
  label: string;
  required?: boolean;
  bold?: boolean;
};

export const IpisFormInputLabel = (props: Props) => {
  return (
    <motion.label
      className={classNames(
        "items-center gap-1 text-base text-dark-950",
        props.bold && "font-bold"
      )}
      htmlFor={props.id}
    >
      {props.label}
      {props.required && <span className="pl-2 text-base">{"*"}</span>}
    </motion.label>
  );
};
