import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { IpisLogoIconV2 } from "../../components/branding/IpisLogoIconV2";
import { IpisBackButton } from "../../components/nav/IpisBackButton";
import { useExternalBrandTheme } from "../../hooks/brand-hooks";
import { classNames } from "../../utils/client-utils";

export interface PageHeaderPortalProps {
  heading: string;
  subHeading?: string;
  overrideLink?: string;
  overrideLinkWhenNoHistory?: string;
  noBackButton?: boolean;
  logo: {
    lightUrl: string;
    darkUrl: string;
  };
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
}

export const PageHeaderPortal = (props: PageHeaderPortalProps) => {
  const theme = useExternalBrandTheme().externalBrandTheme;
  const [, setRerenderHack] = useState(false);
  function rerender() {
    setRerenderHack((prev) => !prev);
  }

  const headerId = "page-header";
  const element = document.getElementById(headerId) as HTMLElement;

  /* const previousPageIsTheSame = useIsPreviousPageFromCurrentSite(); */
  /* 
    Important: this shouldn't be commented out, the the EAS client breaks for some reason if useLocation is used here. Probably some dependencey issue with react-router-dom.
  */
  const previousPageIsTheSame = false;
  /* const [params] = useSearchParams();
  const goBackTo = params.get("goBackTo"); */

  /* 
    Important: this shouldn't be commented out, the the EAS client breaks for some reason if useLocation is used here. Probably some dependencey issue with react-router-dom.
  */
  const goBackTo = null;

  let overrideLink: string | undefined;
  if (props.overrideLink) {
    overrideLink = props.overrideLink;
  } else if (!previousPageIsTheSame) {
    overrideLink = props.overrideLinkWhenNoHistory;
  }
  const customBack = overrideLink || !!goBackTo;
  const showBackButton =
    !props.noBackButton && (previousPageIsTheSame || customBack);

  const rerenderTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!element && !rerenderTimeoutRef.current) {
      rerenderTimeoutRef.current = setTimeout(() => {
        rerender();
        rerenderTimeoutRef.current = null;
      }, 100);
    }
  });

  if (!element) {
    return <></>;
  }

  function constructGridTemplateColumns() {
    return `minmax(max-content, 1fr) auto minmax(0, 1fr)`;
  }

  function constructGridTemplateAreas() {
    const subheadingArea = !!props.subHeading ? "subheading" : "heading";
    return `
        "leftSlot ${subheadingArea} rightSlot"
        "leftSlot heading rightSlot"
      `;
  }

  return ReactDOM.createPortal(
    /* 
      We might be able to use a fragment later and move the styling to the PageHeaderV2 component.
      Ill keep the styling here for now to make it easier to develop.
    */
    <motion.div
      className="items-center gap-x-4"
      style={{
        display: "grid",
        gridTemplateColumns: constructGridTemplateColumns(),
        gridTemplateAreas: constructGridTemplateAreas(),
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
    >
      <span
        className="mr-auto flex items-center pl-5"
        style={{
          gridArea: "leftSlot",
        }}
      >
        {!showBackButton && !theme && (
          <IpisLogoIconV2 size={50} light logo={props.logo} />
        )}
        {!showBackButton && theme && (
          <img
            src={theme.logoUrl}
            alt={`Logga för ${theme.brandName}`}
            className="max-w-[80px]"
          />
        )}
        {showBackButton && <IpisBackButton overrideLink={overrideLink} />}
        {props.leftSlot}
      </span>
      <h1
        className={classNames(
          "mx-auto text-center text-base font-semibold md:text-lg",
          !theme && "text-light-background"
        )}
        style={{
          gridArea: "heading",
        }}
      >
        {props.heading}
      </h1>
      {!!props.subHeading && (
        <h2
          className={classNames(
            "mx-auto text-sm font-normal ",
            !theme && "text-secondary-100"
          )}
          style={{
            gridArea: "subheading",
          }}
        >
          {props.subHeading}
        </h2>
      )}
      {!!props.rightSlot && (
        <div
          // Right padding to make room for fixed sidebar toggle
          className="hidden items-center justify-end pr-8 lg:flex"
          style={{
            gridArea: "rightSlot",
          }}
        >
          {props.rightSlot}
        </div>
      )}
    </motion.div>,
    element
  );
};

export default PageHeaderPortal;
