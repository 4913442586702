import {
  FormEditableAddressFieldset,
  FormInputContext,
  IpisButton,
  IpisLoadingOverlay,
  IpisTextArea,
  IpisTextInputCompact,
  UseMutableQueryResult,
  useMutableQuery,
} from "@ipis/client-essentials";
import { IconCalendarTime } from "@tabler/icons-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useApiClients } from "../contexts/EAS_api-client-context";
import {
  BusinessEasWorkOrder,
  EasWorkOrder,
  GetWorkOrderHistoryOutput,
  PrivateEasWorkOrder,
} from "../eas-trpc-types";

type BookingFlowHistoryContextType = {
  enableCancelWorkOrder: boolean;
  historyRes: UseMutableQueryResult<GetWorkOrderHistoryOutput>;
};
const BookingFlowHistoryContext = createContext<BookingFlowHistoryContextType>(
  {} as never
);

interface Props {
  close: () => void;
}

const BookingFlowHistory = (props: Props) => {
  const apiClients = useApiClients();
  const historyRes = useMutableQuery({
    queryKey: "bookingFlowHistory",

    queryFn: async () => {
      return apiClients.bookingFlow.getWorkOrderHistory();
    },
  });
  const [filterText, setFilterText] = useState("");
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<string | null>(
    null
  );

  function onRefetch() {
    historyRes.query.refetch();
  }

  const selectedWorkOrder =
    historyRes.query.data?.workOrders.find(
      (wo) => wo.id === selectedWorkOrderId
    ) ?? null;

  const [page, setPage] = useState(0);
  const [filterPage, setFilterPage] = useState(0);

  let workOrders = historyRes.query.data?.workOrders ?? [];
  /* if (!EnvironmentVariables.get().inProduction) {
    workOrders = Array.from({ length: 1000 }, (_, i) => {
      const index = i % workOrders.length;
      const copy = { ...workOrders[index] };
      return {
        ...copy,
        id: "faux-id-" + i,
      };
    });
  } */

  return (
    <BookingFlowHistoryContext.Provider
      value={{
        enableCancelWorkOrder: !!historyRes.query.data?.enableCancelWorkOrder,
        historyRes,
      }}
    >
      <section
        className={
          "mx-auto flex h-full w-full max-w-screen-xl flex-col px-8 pb-8 pt-4"
        }
      >
        <header className="flex items-center justify-end gap-4">
          <IpisButton
            variant="text"
            icon={{
              position: "trailing",
              type: "close",
            }}
            label="Stäng fönster"
            onClick={props.close}
          />
        </header>
        <IpisLoadingOverlay isVisible={historyRes.isLoading} />
        {historyRes.query.isError && (
          <p className="text-sm text-error">
            Det gick inte att hämta arbetsorderhistoriken just nu. Vänligen
            försök igen senare.
          </p>
        )}
        {!!workOrders && !selectedWorkOrder && (
          <HistoryTableSection
            workOrders={workOrders}
            filterText={filterText}
            selectWorkOrder={(workOrder) =>
              setSelectedWorkOrderId(workOrder.id)
            }
            setFilterText={setFilterText}
            page={page}
            setPage={setPage}
            filterPage={filterPage}
            setFilterPage={setFilterPage}
            refetch={onRefetch}
          />
        )}
        {!!selectedWorkOrder && (
          <FormInputContext.Provider
            value={{
              readOnly: true,
            }}
          >
            <SelectedWorkOrderSection
              workOrder={selectedWorkOrder}
              close={() => setSelectedWorkOrderId(null)}
            />
          </FormInputContext.Provider>
        )}
      </section>
    </BookingFlowHistoryContext.Provider>
  );
};

const SelectedWorkOrderSection = (props: {
  workOrder: EasWorkOrder;
  close: () => void;
}) => {
  const apiClients = useApiClients();
  const ctx = useContext(BookingFlowHistoryContext);

  async function cancelWorkOrder() {
    if (!ctx.enableCancelWorkOrder) {
      return;
    }
    if (ctx.historyRes.isLoading) {
      return;
    }
    try {
      await ctx.historyRes.mutate({
        callback: () => {
          return apiClients.bookingFlow.cancelWorkOrder({
            workOrderId: props.workOrder.id,
          });
        },
      });
    } catch (er) {
      window.ipisModal.alert({
        title: "Fel vid avbokning",
        prompt: "Det gick inte att avboka arbetsordern. Försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  return (
    <section className="grid h-full w-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto] gap-4 overflow-hidden">
      <header className="grid grid-cols-[minmax(0,1fr,auto)] grid-rows-[repeat(3,min-content)]">
        <h3 className="col-start-1 row-start-1">
          {props.workOrder.costCenterName.toUpperCase()}
        </h3>
        <h2 className="col-start-1 row-start-2 text-2xl text-dark-950 md:text-3xl">
          <b>Bokningsreferens: </b>#{props.workOrder.bookingReference}
        </h2>
        <p className="text-semifaded col-start-1 row-start-3 text-sm">
          Bokad av {props.workOrder.externalBookingAgentName}
        </p>
        <div className="col-start-2 row-span-3 row-start-1 mb-auto ml-auto flex flex-col">
          <div className="ml-auto flex w-full max-w-48 flex-col">
            <p className="text-sm">
              <span>Status: </span>
              <span className="text-primary">{props.workOrder.status}</span>
            </p>
            <IpisButton
              variant="secondary-on-light-background"
              onClick={cancelWorkOrder}
              label="Avboka"
              underline
              disabled={
                !props.workOrder.isCancelable ||
                !ctx.enableCancelWorkOrder ||
                ctx.historyRes.isLoading
              }
            />
          </div>
          {!ctx.enableCancelWorkOrder && (
            <p className="mt-2 text-xs">
              Funktionen för att avboka arbetsordrar är för närvarande avstängd.
            </p>
          )}
        </div>
      </header>
      <div className="grid-cols grid gap-x-12 overflow-y-auto md:grid-cols-2  ">
        {props.workOrder.customerType === "private" && (
          <PrivateSelectedWorkOrderSection workOrder={props.workOrder} />
        )}
        {props.workOrder.customerType === "business" && (
          <BusinessSelectedWorkOrderSection workOrder={props.workOrder} />
        )}
        <SelectedWorkOrderSectionSharedDetails workOrder={props.workOrder} />
      </div>
      <footer className="">
        <IpisButton
          variant="text"
          onClick={props.close}
          label="Tillbaka"
          underline
        />
      </footer>
    </section>
  );
};

const PrivateSelectedWorkOrderSection = (props: {
  workOrder: PrivateEasWorkOrder;
}) => {
  const locationForm = useForm<{
    street: string;
    city: string;
    postalCode: string;
    country: string | null;
  }>({
    defaultValues: props.workOrder.workOrderLocation,
  });

  return (
    <section className="flex flex-col gap-4">
      <h3 className="text-xl font-semibold">Personuppgifter</h3>
      <fieldset className="grid grid-cols-2 gap-x-2">
        <IpisTextInputCompact
          id="firstName"
          name="firstName"
          label="Förnamn"
          controls={{
            value: props.workOrder.contactDetails?.firstName,
          }}
        />
        <IpisTextInputCompact
          id="lastName"
          name="lastName"
          label="Efternamn"
          controls={{
            value: props.workOrder.contactDetails?.lastName,
          }}
        />
      </fieldset>
      <FormEditableAddressFieldset
        id="workOrderLocation"
        legend="Utförandeadress"
        isEditing={false}
        form={locationForm}
      />
      <IpisTextInputCompact
        id="phone"
        name="phone"
        label="Telefon"
        controls={{
          value: props.workOrder.contactDetails?.phone,
        }}
      />
    </section>
  );
};

const BusinessSelectedWorkOrderSection = (props: {
  workOrder: BusinessEasWorkOrder;
}) => {
  const addressForm = useForm({
    defaultValues: props.workOrder.workOrderLocation,
  });

  return (
    <section className="flex flex-col gap-4">
      <h3 className="text-xl font-semibold">Företagsuppgifter</h3>
      <IpisTextInputCompact
        id="businessName"
        name="businessName"
        label="Företagsnamn"
        controls={{
          value: props.workOrder.businessDetails.businessName,
        }}
      />

      <section className="flex flex-col gap-4">
        <h4 className="text-lg">Kontaktperson</h4>
        <fieldset className="grid grid-cols-2 gap-x-4">
          <IpisTextInputCompact
            id="contactFirstName"
            name="contactFirstName"
            label="Förnamn"
            controls={{
              value: props.workOrder.contactPerson.firstName,
            }}
          />
          <IpisTextInputCompact
            id="contactLastName"
            name="contactLastName"
            label="Efternamn"
            controls={{
              value: props.workOrder.contactPerson.lastName,
            }}
          />
        </fieldset>
        <IpisTextInputCompact
          id="contactPhone"
          name="contactPhone"
          label="Telefonnummer"
          controls={{
            value: props.workOrder.contactPerson.phone,
          }}
        />
      </section>

      <FormEditableAddressFieldset
        id="workOrderLocation"
        legend="Utförandeadress"
        isEditing={false}
        form={addressForm}
      />
    </section>
  );
};

const SelectedWorkOrderSectionSharedDetails = (props: {
  workOrder: EasWorkOrder;
}) => {
  const startTimeDate = new Date(props.workOrder.startDate);

  /* 
   i.e. 2024-01-01
  */
  const dateString = startTimeDate.toDateString();

  /* 
    i.e. 12:00
  */
  const timeString = startTimeDate.toLocaleTimeString("sv-SE", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <section className="flex flex-col gap-4">
      <h3 className="text-xl font-semibold">Arbetsorderdetaljer</h3>
      <fieldset className="flex flex-col gap-4">
        <IpisTextInputCompact
          id="serviceType"
          name="serviceType"
          label="Servicetyp"
          controls={{
            value: props.workOrder.serviceType,
          }}
        />
        <IpisTextArea
          id="description"
          name="description"
          label="Beskrivning"
          controls={{
            value: props.workOrder.description,
          }}
          rows={3}
        />
        <IpisTextInputCompact
          id="internalComment"
          name="internalComment"
          label="Kommentar till hantverkare"
          controls={{
            value: props.workOrder.internalComment,
          }}
        />
      </fieldset>

      <section className="flex flex-col gap-4 rounded pt-4">
        <h4 className="text-lg">Ansvarig hantverkare</h4>
        <IpisTextInputCompact
          id="handyman"
          name="handyman"
          label="Namn"
          controls={{
            value: props.workOrder.handyman.name,
          }}
        />
        <IpisTextInputCompact
          id="handymanPhone"
          name="handymanPhone"
          label="Telefon"
          controls={{
            value: props.workOrder.handyman.phone,
          }}
        />
      </section>
      <section className="flex flex-col gap-4 pt-4">
        <h4 className="text-lg">Schemalagt för</h4>
        <p className="grid grid-cols-[auto,minmax(0,1fr),minmax(0,1fr)] gap-4">
          <IconCalendarTime size={24} />
          <IpisTextInputCompact
            id="scheduledFor"
            name="scheduledFor"
            label="Datum"
            controls={{
              value: dateString,
            }}
          />
          <IpisTextInputCompact
            id="startTime"
            name="startTime"
            label="Ungefärlig starttid"
            controls={{
              value: timeString,
            }}
          />
        </p>
      </section>
    </section>
  );
};

const HistoryTableSection = (props: {
  workOrders: EasWorkOrder[];
  selectWorkOrder: (workOrder: EasWorkOrder) => void;
  filterText: string;
  setFilterText: (text: string) => void;
  page: number;
  setPage: (page: number) => void;
  filterPage: number;
  setFilterPage: (page: number) => void;
  refetch: () => void;
}) => {
  const hasFilter = props.filterText.length > 0;

  function setFilterText(text: string) {
    const currentValue = props.filterText;
    if (currentValue.length === 0 && text.length > 0) {
      props.setFilterPage(0);
    }
    props.setFilterText(text);
  }

  function setPage(page: number) {
    if (hasFilter) {
      props.setFilterPage(page);
    } else {
      props.setPage(page);
    }
  }

  function incrementPage() {
    setPage(getPageIndex() + 1);
  }

  function decrementPage() {
    setPage(getPageIndex() - 1);
  }

  function getPageIndex() {
    return hasFilter ? props.filterPage : props.page;
  }

  function filterWorkOrders(workOrders: EasWorkOrder[], filterText: string) {
    if (filterText.length === 0) {
      return workOrders;
    }
    const parts = filterText.split(" ");
    return workOrders.filter((workOrder) => {
      const json = JSON.stringify(workOrder).toLowerCase();
      return parts.every((part) => json.includes(part.toLowerCase()));
    });
  }

  const filteredWorkOrders = filterWorkOrders(
    props.workOrders,
    props.filterText
  );
  const pageIndex = getPageIndex();
  const chunk = 8;
  const maxPage = Math.max(Math.ceil(filteredWorkOrders.length / chunk), 1);
  const start = pageIndex * chunk;
  const end = start + chunk;
  const filteredWorkOrdersPage = filteredWorkOrders.slice(start, end);

  useEffect(() => {
    if (pageIndex >= maxPage) {
      setPage(maxPage - 1);
    }
  }, [setPage, pageIndex, maxPage]);

  return (
    <section className="grid h-full w-full grid-rows-[auto,minmax(0,1fr),auto] overflow-hidden">
      <header className="grid grid-cols-[minmax(0,1fr),auto]">
        <h2 className="col-span-2 text-2xl md:text-3xl">Arbetsorderhistorik</h2>
        <IpisButton
          variant="text"
          className="col-span-2 mr-auto"
          label="Ladda in på nytt"
          onClick={props.refetch}
        />
        <IpisTextInputCompact
          id="workOrderHistoryFilter"
          name="workOrderHistoryFilter"
          className="mt-8"
          label="Sök med fritext"
          controls={{
            value: props.filterText,
            onChange: (str) => setFilterText(str),
          }}
        />
        <ul className="col-span-2 grid grid-cols-7 border-b border-[#B0B0B0] py-4 font-bold text-dark-950">
          <li>Bokningsreferens</li>
          <li>Kund</li>
          <li>Hantverkare</li>
          <li>Datum</li>
          <li>Varumärke</li>
          <li>Servicetyp</li>
          <li>Bokare</li>
        </ul>
      </header>
      <ul className="overflow-y-auto pt-4">
        {filteredWorkOrdersPage.map((workOrder) => {
          const startDate = new Date(workOrder.startDate);
          const year = startDate.getFullYear();
          const _month = startDate.getMonth() + 1;
          const _day = startDate.getDate();
          const month = _month.toString().padStart(2, "0");
          const day = _day.toString().padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;

          let customer: string;
          if (workOrder.customerType === "private") {
            const contact = workOrder.contactDetails;
            const firstName = contact?.firstName ?? "";
            const lastName = contact?.lastName ?? "";
            customer = `${firstName} ${lastName}`;
          } else {
            customer = workOrder.businessDetails.businessName;
          }

          return (
            <li
              key={workOrder.bookingReference}
              className="grid  cursor-pointer grid-cols-7 even:bg-secondary-50 hover:bg-secondary-100"
              onClick={() => props.selectWorkOrder(workOrder)}
            >
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4 pl-1">
                {workOrder.bookingReference}
              </span>
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4">
                {customer}
              </span>
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4">
                {workOrder.handyman.name}
              </span>
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4">
                {formattedDate}
              </span>
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4">
                {workOrder.costCenterName}
              </span>
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4">
                {workOrder.serviceType}
              </span>
              <span className="w-full overflow-hidden overflow-ellipsis text-nowrap py-4">
                {workOrder.externalBookingAgentName}
              </span>
            </li>
          );
        })}
      </ul>
      <footer className="flex items-center justify-end">
        <nav className="flex gap-4 py-4">
          <IpisButton
            variant="text"
            onClick={decrementPage}
            label="Föregående"
            disabled={pageIndex === 0}
          />
          <span className="flex w-32 justify-center">
            Sida {pageIndex + 1} av {maxPage}
          </span>
          <IpisButton
            variant="text"
            onClick={incrementPage}
            label="Nästa"
            disabled={pageIndex === maxPage - 1}
          />
        </nav>
      </footer>
    </section>
  );
};

export default BookingFlowHistory;
