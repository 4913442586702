import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import {
  IpisButton,
  IpisRadioGroup,
  IpisTextInputCompact,
  classNames,
  useLoadingAction,
  usePrevious,
} from "@ipis/client-essentials";
import { useEffect } from "react";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import {
  CustomerVerificationFormValues,
  OrgNoSchema,
  SsnSchema,
} from "../../temporary-booking-flow-schemas";
import { useBookingFlowContext } from "../../contexts/booking-flow-context";
import { useApiClients } from "../../contexts/EAS_api-client-context";

const FormSchema = z.union([
  z.object({
    typeOfCustomer: z.literal("private"),
    ssn: SsnSchema,
  }),
  z.object({
    typeOfCustomer: z.literal("business"),
    orgNo: OrgNoSchema,
  }),
]);

type FormValues = z.infer<typeof FormSchema>;

interface Props {
  callback: (values: CustomerVerificationFormValues) => void;
  latestValues: CustomerVerificationFormValues | undefined;
}

const ControlledCustomerVerification = (props: Props) => {
  const { bookingFlow } = useApiClients();
  const ctx = useBookingFlowContext();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      typeOfCustomer: ctx.typeOfCustomer,
      ssn: getDefaultSsn(),
      orgNo: getDefaultOrgNo(),
    },
  });

  function reset() {
    props.callback({
      hasAttemptedVerification: false,
      typeOfCustomer: ctx.typeOfCustomer,
      verificationPassed: false,
    });
  }

  function getDefaultSsn(): string | undefined {
    const latest = props.latestValues;
    if (latest?.typeOfCustomer === "private" && latest.verificationPassed) {
      return latest.ssn;
    }

    return undefined;
  }

  function getDefaultOrgNo(): string | undefined {
    const latest = props.latestValues;
    if (latest?.typeOfCustomer === "business" && latest.verificationPassed) {
      return latest.orgNo;
    }

    return undefined;
  }

  useEffect(() => {
    form.setValue("typeOfCustomer", ctx.typeOfCustomer);
  }, [ctx.typeOfCustomer, form.setValue]);

  const latestValues = props.latestValues;

  const isBusiness = form.watch("typeOfCustomer") === "business";

  const previousIsBusiness = usePrevious(isBusiness);

  const isVerified = latestValues?.verificationPassed;
  const verificationAttempted = latestValues?.hasAttemptedVerification;

  // Had to move !isVerified for this to work
  const showCustomerNotFound =
    verificationAttempted && !isVerified && latestValues?.customerNotFound;
  const showNotApproved =
    verificationAttempted && !isVerified && !latestValues?.customerNotFound;
  const showSubmitButton = !verificationAttempted;

  useEffect(() => {
    form.setValue("typeOfCustomer", ctx.typeOfCustomer);
  }, [ctx.typeOfCustomer, form]);

  useEffect(() => {
    if (
      verificationAttempted &&
      previousIsBusiness !== undefined &&
      isBusiness !== previousIsBusiness
    ) {
      props.callback({
        hasAttemptedVerification: false,
        typeOfCustomer: isBusiness ? "business" : "private",
        verificationPassed: false,
      });
    }
  }, [props.callback, verificationAttempted, isBusiness, previousIsBusiness]);

  const onSubmitAction = useLoadingAction(async (values: FieldValues) => {
    try {
      const isBusiness = ctx.typeOfCustomer === "business";

      if (isBusiness) {
        const res = await bookingFlow.fetchBusinessCustomerVerification({
          orgnr: values.orgNo,
        });

        if (res.approved) {
          props.callback({
            typeOfCustomer: "business",
            hasAttemptedVerification: true,
            verificationPassed: true,
            customerId: res.customerId,
            orgNo: values.orgNo,
            businessName: res.businessName,
            businessAddress: {
              street: res.businessAddress.street,
              postalCode: res.businessAddress.postalCode,
              city: res.businessAddress.city,
              country: res.businessAddress.country,
            },
          });
        } else {
          props.callback({
            typeOfCustomer: "business",
            hasAttemptedVerification: true,
            customerNotFound: !!res.customerNotFound,
            verificationPassed: false,
          });
        }
      } else {
        const res = await bookingFlow.fetchPrivateCustomerVerification({
          ssn: values.ssn,
        });
        if (res.approved) {
          props.callback({
            typeOfCustomer: "private",
            hasAttemptedVerification: true,
            customerId: res.customerId,
            verificationPassed: true,
            ssn: values.ssn,
            firstName: res.firstName,
            lastName: res.lastName,
            address: {
              street: res.address.street,
              postalCode: res.address.postalCode,
              city: res.address.city,
              country: res.address.country,
            },
          });
        } else {
          props.callback({
            typeOfCustomer: "private",
            hasAttemptedVerification: true,
            verificationPassed: false,
            customerNotFound: !!res.customerNotFound,
          });
        }
      }
    } catch (er) {
      console.error(er);
      window.alert("Något gick fel, vänligen försök igen.");
    }
  });

  async function onError(errors: FieldErrors<FieldValues>) {
    console.log(form.getValues());
    console.log(errors);
  }

  return (
    <fieldset
      className={classNames(
        "grid grid-cols-[minmax(0,1fr),auto] gap-x-4 rounded",
        "my-4",
        onSubmitAction.isLoading && "skeleton"
      )}
    >
      <IpisRadioGroup
        name="typeOfCustomer"
        id="typeOfCustomer"
        options={[
          { label: "Privatkund", value: "private" },
          { label: "Företagskund", value: "business" },
        ]}
        hideError
        className="col-span-2 pb-4"
        useUniformWidth
        controls={{
          value: ctx.typeOfCustomer,
          onChange: (value) => {
            ctx.setTypeOfCustomer(value);
          },
        }}
      />
      {!verificationAttempted && (
        <h3 className="col-span-2 pb-4 font-bold">
          Ange {isBusiness ? "organisationsnummer" : "personnummer"} för
          verifiering
        </h3>
      )}

      {!isBusiness && (
        <IpisTextInputCompact
          id="ssn"
          label="Personnummer"
          form={form}
          name={"ssn"}
          placeholder="ÅÅÅÅMMDD-XXXX"
          readOnly={isVerified}
        />
      )}
      {isBusiness && (
        <IpisTextInputCompact
          id="orgNo"
          label="Organisationsnummer"
          form={form}
          name={"orgNo"}
          placeholder="XXXXXX-XXXX"
          readOnly={isVerified}
        />
      )}
      {showSubmitButton && (
        <IpisButton
          label="Kontrollera"
          onClick={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
          variant="secondary-on-light-background"
          className="mb-auto mt-2"
          loading={onSubmitAction.isLoading}
        />
      )}
      {isVerified && (
        <span className="mb-auto mt-2 flex items-center justify-center rounded bg-[#4CA154] p-2 px-4 font-bold text-white">
          Godkänd
        </span>
      )}
      {isVerified && (
        <div className="col-span-2 flex items-center gap-2 px-4 py-1 text-sm">
          <span className="text-semi-faded">
            Kunden är godkänd, vänligen kontrollera resterande uppgifter.
          </span>
          <IpisButton
            variant="text"
            label="Återställ"
            underline
            size="sm"
            onClick={reset}
          />
        </div>
      )}
      {showCustomerNotFound && (
        <div className="col-span-2 flex items-center gap-2 px-4 py-1 text-sm">
          <span className="text-error">
            Kunden hittades inte och kan därmed inte godkännas.
          </span>
          <IpisButton
            variant="text"
            label="Återställ"
            underline
            size="sm"
            onClick={reset}
          />
        </div>
      )}

      {showNotApproved && (
        <div className="col-span-2 flex items-center gap-2 px-4 py-1 text-sm">
          <span className="text-error">Kunden blev ej godkänd.</span>
          <IpisButton
            variant="text"
            label="Återställ"
            underline
            size="sm"
            onClick={reset}
          />
        </div>
      )}
    </fieldset>
  );
};

export default ControlledCustomerVerification;
