import { TabContext, TabContextProps } from "../../contexts/tab-context";
import { useNavigate, useSearchParams } from "react-router-dom";

const TabContextProvider = <T extends string>(props: TabContextProps<T>) => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();

  // Handle tab click events
  function onTabClick(tab: T) {
    // Return early if controls are disabled
    if (props.withoutControls) {
      return;
    }

    // If searchParams is defined, update search parameters
    if ("searchParams" in props) {
      setSearchParams({
        ...Object.fromEntries(params.entries()),
        ...props.searchParams(tab),
        internalNavigation: "true", // Add internalNavigation flag to indicate that the navigation is internal (within the same site)
      });
    } else if ("href" in props) {
      // If href is defined, navigate to the new URL
      const href = props.href(tab);
      // Pass the previous location in the state to allow the hook to determine if the navigation is internal or external
      navigate(href, { state: { previousLocation: window.location.href } });
    } else {
      // Otherwise, call the onSelect callback
      props.onSelect(tab);
    }
  }

  return (
    <TabContext.Provider
      value={{
        ...(props as TabContextProps<string>),
        onTabClick: (str) => onTabClick(str as T),
      }}
    >
      {props.children}
    </TabContext.Provider>
  );
};

export default TabContextProvider;
