import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { IpisButton } from "../../components/buttons/IpisButton";
import { IpisFormInputError } from "../../components/form/form-inputs/components/IpisFormInputError";
import { IpisTextInputCompact } from "../../components/form/form-inputs/text-input/IpisTextInputCompact";
import { useLoadingAction } from "../../hooks/general-hooks";
import { classNames } from "../../utils/client-utils";

const FormSchema = z.object({
  email: z.string().trim().email(),
  password: z.string().min(1),
});

type FormValues = z.infer<typeof FormSchema>;

interface Props {
  bgUrl: string;
  onSubmit?: (values: FormValues) => Promise<200 | 404>;
  fixed?: boolean;
}

/* 
	ToDo: forgot password functionality
*/
export function IpisSignInScreen(props: Props) {
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
  });

  const action = useLoadingAction(async (values: FormValues) => {
    return props.onSubmit?.(values);
  });

  const is404 = action.latestValue === 404;
  const isNon404Error = action.isError;

  return (
    <section
      className={classNames(
        "h-full w-full p-8",
        props.fixed && "fixed left-0 top-0"
      )}
      onSubmit={form.handleSubmit(action.triggerSafely)}
      style={{
        backgroundImage: `url(${props.bgUrl})`,
        backgroundSize: "cover",
        // Focus the right side
        backgroundPosition: "30% 50%",
      }}
    >
      <header className="py-8">
        <h1 className="text-2xl text-light-background">
          Välkommen till IPIS Platform
        </h1>
        <h2 className="text-primary-500">Din installationspartner i Sverige</h2>
      </header>
      <form className="flex w-full max-w-[400px] flex-col gap-4 py-4">
        <IpisTextInputCompact
          required
          id="email"
          form={form}
          name="email"
          label="E-post"
          type="email"
          onDarkBackground
        />
        <IpisTextInputCompact
          required
          id="password"
          form={form}
          name="password"
          label="Lösenord"
          type="password"
          onDarkBackground
        />
        {is404 && <IpisFormInputError error="Fel lösenord eller e-post" />}
        {isNon404Error && (
          <IpisFormInputError error="Något gick fel, försök igen" />
        )}

        <footer className="flex flex-col pt-8">
          <IpisButton
            type="submit"
            label="Logga in"
            loading={action.isLoading}
            disabled={action.isLoading}
          />
        </footer>
      </form>
    </section>
  );
}
