import { AuthenticationOutput } from "@ipis/auth-schemas";
import { Observable, PrivateObservable } from "../observables/Observable";

type AuthOutput = AuthenticationOutput.ExternalBookingAgentType;

export default class AuthenticationManager {
  private static instance: AuthenticationManager;
  private readonly _obs: Observable<AuthOutput | null>;

  private constructor() {
    const stored = this.getStored();
    this._obs = new Observable<AuthOutput | null>(stored);
  }

  public get obs(): PrivateObservable<AuthOutput | null> {
    return this._obs.privateObs;
  }

  public static getInstance(): AuthenticationManager {
    if (!AuthenticationManager.instance) {
      AuthenticationManager.instance = new AuthenticationManager();
    }
    return AuthenticationManager.instance;
  }

  private static readonly KEY = "EAS_AUTHENTICATION_OUTPUT";

  private updateStored(value: AuthOutput | null) {
    this._obs.set(value);
    localStorage.setItem(AuthenticationManager.KEY, JSON.stringify(value));
  }

  public store(values: AuthenticationOutput.Type) {
    const safeParse =
      AuthenticationOutput.ExternalBookingAgentSchema.safeParse(values);

    if (!safeParse.success) {
      throw new Error("Invalid values");
    }

    const parsed = safeParse.data;

    if (parsed.signedInAs !== "external-booking-agent") {
      throw new Error(
        "Only external-booking-agents are allowed for this client"
      );
    }
    this.updateStored(parsed);
  }

  private getStored(): AuthOutput | null {
    const stored = localStorage.getItem(AuthenticationManager.KEY);
    if (!stored) {
      return null;
    }
    try {
      const jsonParsed = JSON.parse(stored);
      const parsed = AuthenticationOutput.Schema.parse(jsonParsed);
      if (parsed.signedInAs !== "external-booking-agent") {
        return null;
      }

      if (!this.validateEnoughTimeLeft(parsed)) {
        this.signOut();
        return null;
      }

      return parsed as AuthOutput;
    } catch (er) {
      this.signOut();
      return null;
    }
  }

  private validateEnoughTimeLeft(creds: AuthOutput) {
    const now = new Date();
    const expiresAt = new Date(creds.expiresAt);
    const timeLeft = expiresAt.getTime() - now.getTime();
    const minutesLeft = Math.floor(timeLeft / 60000);
    return minutesLeft > 10;
  }

  public signOut() {
    localStorage.removeItem(AuthenticationManager.KEY);
    this._obs.set(null);
  }

  public getOptionalAuthHeader() {
    const creds = this.getStored();
    if (!creds) {
      return undefined;
    }

    return {
      Authorization: `Bearer ${creds.jwtToken}`,
    };
  }

  public isSignedIn(): boolean {
    try {
      const stored = this.getStored();
      if (!stored) {
        return false;
      }
      const now = new Date();
      const expiresAt = new Date(stored.expiresAt);
      return now < expiresAt;
    } catch (er) {
      return false;
    }
  }
}
