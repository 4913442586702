import { BrandTheme } from "@eljouren/domain";
import { motion } from "framer-motion";
import React from "react";
import { TabContextProps } from "../../contexts/tab-context";
import { classNames } from "../../utils/client-utils";
import PageHeaderPortal, { PageHeaderPortalProps } from "./PageHeaderPortal";
import { useExternalBrandTheme } from "../../hooks/brand-hooks";

export interface IpisPageProps {
  className?: string;
  children?: React.ReactNode;
  headerProps: PageHeaderPortalProps;
  tabProps?: TabContextProps<string>;
  noLayoutStyling?: boolean;
  externalBrandTheme?: BrandTheme.Type | null;
}

const variants = {
  closed: {
    //clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
    opacity: 0,
  },
  open: {
    //clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
    opacity: 1,
  },

  exit: {
    // Animating to same as open apparently doesn't work
    //clipPath: "polygon(0 1%, 100% 1%, 100% 1%, 0 1%)",
    opacity: 0,
  },
};

export const IpisPage = (props: IpisPageProps) => {
  const spanFull = !props.headerProps && !props.tabProps;
  const span2 = !spanFull && (!props.headerProps || !props.tabProps);
  const span1 = !spanFull && !span2;

  useExternalBrandTheme({ externalBrandTheme: props.externalBrandTheme });

  return (
    <>
      <PageHeaderPortal {...props.headerProps} />
      <motion.main
        className={classNames(
          //"border-4 border-red-400",
          !props.noLayoutStyling && "flex h-full w-full flex-col",
          "col-start-1",
          spanFull && "row-span-3 row-start-1",
          span2 && "row-span-2 row-start-2",
          span1 && "row-start-2 lg:row-start-3",
          props.className
        )}
        variants={variants}
        initial="closed"
        animate="open"
        exit="exit"
        transition={{ duration: 0.3 }}
      >
        {props.children}
      </motion.main>
    </>
  );
};
