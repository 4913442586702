import { zodResolver } from "@hookform/resolvers/zod";
import {
  IpisButton,
  classNames,
  useGoogleGeocode,
  useLoadingAction,
} from "@ipis/client-essentials";
import {
  DeepPartial,
  FieldErrors,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { useBookingFlowContext } from "../../../contexts/booking-flow-context";
import { CostCenter } from "../../../eas-trpc-types";
import {
  CustomerInformationFormSchema,
  CustomerInformationFormValues,
  EmergencyCustomerInformationValues,
  CustomerVerificationFormValues,
} from "../../../temporary-booking-flow-schemas";
import BusinessCustomerInformationFields from "../../components/BusinessCustomerInformationFields";
import ControlledCustomerVerification from "../../components/ControlledCustomerVerification";
import ImportantInformationSidebar from "../../components/ImportantInformationSidebar";
import PrivateCustomerInformationFields from "../../components/PrivateCustomerInformationFields";

type Props = {
  costCenter: CostCenter;
  defaultValues: EmergencyCustomerInformationValues | undefined;
};

const EmergencyCustomerInformationPage = (props: Props) => {
  const ctx = useBookingFlowContext();
  const form = useForm<CustomerInformationFormValues>({
    resolver: zodResolver(CustomerInformationFormSchema),
    //resolver: zodResolver(z.any()),
    defaultValues: getDefaultValues(),
  });

  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

  const getGeocode = useGoogleGeocode({
    apiKey,
  });

  function getDefaultValues(): DeepPartial<CustomerInformationFormValues> {
    if (props.defaultValues) {
      return props.defaultValues;
    }

    return {
      typeOfCustomer: ctx.typeOfCustomer,
      verificationStatus: {
        typeOfCustomer: ctx.typeOfCustomer,
        hasAttemptedVerification: false,
        verificationPassed: false,
      },
    };
  }

  const isBusinessCustomer = ctx.typeOfCustomer === "business";

  async function getBusinessContactCoordinates() {
    if (!isBusinessCustomer) {
      return;
    }

    const values = form.getValues();

    if (
      values.verificationStatus.verificationPassed &&
      values.typeOfCustomer === "business"
    ) {
      try {
        const address: {
          street: string;
          postalCode: string;
          city: string;
          country: string;
        } = values.verificationStatus.businessAddress;
        const fullAddress = `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`;
        const geocode = await getGeocode(fullAddress);
        const lat = geocode.geometry.location.lat();
        const lng = geocode.geometry.location.lng();
        form.setValue("businessInformation.businessAddressCoordinates", {
          lat,
          long: lng,
        });
      } catch (er) {
        window.alert(
          "Kunde inte hitta koordinater för företagsadressen. Detta kan innebära att du måste välja annan utförandeadress för att kunna boka tjänsten."
        );
      }
    }
  }

  async function getPrivateCustomerCoordinates() {
    if (isBusinessCustomer) {
      return;
    }

    const values = form.getValues();

    if (
      values.verificationStatus.verificationPassed &&
      values.typeOfCustomer === "private"
    ) {
      try {
        const address: {
          street: string;
          postalCode: string;
          city: string;
          country: string;
        } = values.verificationStatus.address;
        const fullAddress = `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`;
        const geocode = await getGeocode(fullAddress);
        const lat = geocode.geometry.location.lat();
        const lng = geocode.geometry.location.lng();
        form.setValue("privateCustomerInformation.addressCoordinates", {
          lat,
          long: lng,
        });
      } catch (er) {
        window.alert(
          "Kunde inte hitta koordinater för företagsadressen. Detta kan innebära att du måste välja annan utförandeadress för att kunna boka tjänsten."
        );
      }
    }
  }

  function verificationCallback(values: CustomerVerificationFormValues) {
    if (values.typeOfCustomer !== ctx.typeOfCustomer) {
      throw new Error("Type of customer mismatch in verification callback");
    }

    form.setValue("typeOfCustomer", ctx.typeOfCustomer);
    form.setValue("verificationStatus", values);

    form.resetField("businessInformation", {
      //@ts-ignore
      defaultValue: {},
    });

    if (values.typeOfCustomer === "business") {
      getBusinessContactCoordinates();
    }

    if (values.typeOfCustomer === "private") {
      getPrivateCustomerCoordinates();
    }

    form.resetField("privateCustomerInformation", {
      //@ts-ignore
      defaultValue: {},
    });

    form.resetField("workOrderLocationAddress", {
      //@ts-ignore
      defaultValue: {},
    });

    if (values.typeOfCustomer === "private" && values.verificationPassed) {
      form.setValue(
        "privateCustomerInformation.contactInformation.firstName",
        values.firstName
      );
      form.setValue(
        "privateCustomerInformation.contactInformation.lastName",
        values.lastName
      );
    }
  }

  const onSubmitAction = useLoadingAction(
    async (values: CustomerInformationFormValues) => {
      const v = values;
      if (!v.verificationStatus.verificationPassed) {
        window.alert("Formuläret är inte korrekt ifyllt.");
      } else {
        await ctx.setEmergencyBookingCustomerValues(
          v as EmergencyCustomerInformationValues
        );
      }
    }
  );

  function onError(errors: FieldErrors<CustomerInformationFormValues>) {
    const values = form.getValues();
    console.log({
      context: "CustomerInformationPage.onError",
      values,
      errors,
    });
  }

  function getSanitisedVerificationPassed() {
    const status = form.watch("verificationStatus");
    if (status.typeOfCustomer !== ctx.typeOfCustomer) {
      return false;
    }

    return status.verificationPassed;
  }

  return (
    <section
      className={classNames(
        "mr-auto grid h-full w-full grid-rows-[auto,minmax(0,1fr),auto] gap-4",
        onSubmitAction.isLoading && "pointer-events-none"
      )}
    >
      <h2 className="text-base font-bold text-dark-950  sm:text-xl lg:text-2xl">
        Vänligen ange{" "}
        {isBusinessCustomer ? "företagsuppgifter" : "personuppgifter"}
      </h2>
      <div className="grid h-full w-full grid-cols-[minmax(0,1fr),auto] grid-rows-1 gap-x-6 overflow-hidden md:gap-x-12 lg:gap-x-24">
        <form
          className="grid grid-rows-[minmax(0,1fr),auto]"
          onSubmit={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
        >
          <section className="flex h-full w-full flex-col gap-4 overflow-auto pb-4 pr-6">
            <ControlledCustomerVerification
              callback={verificationCallback}
              latestValues={form.watch("verificationStatus")}
            />
            {getSanitisedVerificationPassed() && <OnceVerified form={form} />}
          </section>
          <footer className="mt-auto flex items-center justify-between py-4">
            <IpisButton
              variant="text"
              underline
              label="Gå tillbaka"
              onClick={ctx.goBack}
            />
            <IpisButton
              variant="secondary-on-light-background"
              label="Fortsätt"
              onClick={form.handleSubmit(onSubmitAction.triggerSafely, onError)}
              loading={onSubmitAction.isLoading}
            />
          </footer>
        </form>
        <ImportantInformationSidebar />
      </div>
    </section>
  );
};

const OnceVerified = (props: {
  form: UseFormReturn<CustomerInformationFormValues>;
}) => {
  const ctx = useBookingFlowContext();
  const isBusinessCustomer = ctx.typeOfCustomer === "business";

  if (isBusinessCustomer) {
    return (
      <BusinessCustomerInformationFields form={props.form} editable={true} />
    );
  } else {
    return (
      <PrivateCustomerInformationFields form={props.form} editable={true} />
    );
  }
};

export { EmergencyCustomerInformationPage };
