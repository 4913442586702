import { IconInfoSquareRounded } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {
  className?: string;
}

const IpisInformationIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconInfoSquareRounded {...rest} className={props.className} />;
};
export const IpisInformationIcon = withIconSize(IpisInformationIcon_);
