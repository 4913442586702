import { BrandTheme } from "@eljouren/domain";
import { useLayout } from "../contexts/layout-context";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

type ExternalBrandConstants = {
  logoUrl: string;
  brandName: string;
};

const eonMockConstants: ExternalBrandConstants = {
  logoUrl: "https://upload.wikimedia.org/wikipedia/commons/9/93/EON_Logo.svg",
  brandName: "E.ON",
};

export function useStaticExternalBrand(): string | undefined {
  const params = useParams();
  const brand = params["externalBrand"];
  return brand?.toLowerCase();
}

export function useExternalBrandTheme(
  props: {
    externalBrandTheme?: BrandTheme.Type | null;
  } = {}
): {
  externalBrandTheme?: BrandTheme.Type;
  setExternalBrandTheme: (theme: BrandTheme.Type) => void;
} {
  const ctx = useLayout();
  const { setExternalBrandTheme } = ctx;
  const { externalBrandTheme } = props;

  useEffect(() => {
    if (externalBrandTheme) {
      setExternalBrandTheme(externalBrandTheme);
    }

    if (externalBrandTheme === null) {
      setExternalBrandTheme(undefined);
    }
  }, [externalBrandTheme, setExternalBrandTheme]);

  return ctx;
}

export function useExternalBrandConstants():
  | ExternalBrandConstants
  | undefined {
  const brand = useStaticExternalBrand();
  if (brand === "eon") {
    return eonMockConstants;
  }
  return undefined;
}
