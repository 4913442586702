import { classNames } from "../../../../utils/client-utils";
import { RichTextPreTag } from "./RichTextPreTag";

interface Props {
  className?: string;
  html: string;
}

export const IpisFormInputDescription = (props: Props) => {
  return (
    <RichTextPreTag
      className={classNames(
        "whitespace-pre-wrap text-xs text-semi-faded sm:text-sm",
        props.className
      )}
      html={props.html}
    />
  );
};
