import {
  IpisButton,
  TGoogleAddressInputResponse,
  classNames,
  useTailwindColors,
} from "@ipis/client-essentials";
import { IconPhone } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { useApiClients } from "../../contexts/EAS_api-client-context";
import { BookingType, BookingTypeService } from "../../eas-trpc-types";
import { BookingFlowHandyman } from "../../temporary-booking-flow-schemas";

interface Props {
  className?: string;
  service: BookingType;
  typeOfService: BookingTypeService | undefined;
  selectedHandyman: BookingFlowHandyman | undefined;
  address: TGoogleAddressInputResponse;
  selectHandyman: (handyman: BookingFlowHandyman) => void;
  isError: boolean;
}

const BookingFlowHandymanSuggestionComponent = (props: Props) => {
  const { bookingFlow } = useApiClients();

  const res = useQuery({
    queryKey: ["suggest-handymen", props.typeOfService?.productPackageId],
    queryFn: async () => {
      if (!props.typeOfService) {
        throw new Error("No skill selected");
      }
      return bookingFlow.fetchSuggestedHandymen({
        skill: props.typeOfService,
        service: props.service,
        address: props.address,
      });
    },
    enabled: false,
    cacheTime: 0,
    staleTime: 0
  });

  function trigger() {
    if (!props.typeOfService) {
      return;
    }
    res.refetch();
  }

  const handymen = res.data;
  const hasFetchedHandymen = !!handymen && !res.isFetching;
  const hasHandymen = hasFetchedHandymen && handymen.length > 0;

  const noHandymenFound = hasFetchedHandymen && !hasHandymen;

  return (
    <section
      className={classNames(
        "flex flex-col gap-8 border bg-secondary-50 p-4",
        !props.isError && "border-transparent",
        props.isError && "border-error",
        props.className
      )}
    >
      <header className="grid grid-cols-[minmax(0,1fr),auto] items-center gap-x-4 gap-y-4">
        <h3 className="text-xl font-bold text-dark-950">
          Välj tillgänlig hantverkare
        </h3>
        <IpisButton
          label="Hitta tillgänlig hantverkare"
          variant="secondary-on-light-background"
          disabled={!props.typeOfService}
          onClick={trigger}
        />
        <p className="col-span-2 text-sm">
          {!!props.typeOfService &&
            "Ring någon av nedanstående hantverkare för att bekräfta innan du går vidare med bokningen"}
          {!props.typeOfService &&
            "Vänligen välj en specifik tjänst för att se tillgängliga hantverkare"}
        </p>
        {res.isError && (
          <p className="col-span-2 text-red-400">
            Ett fel uppstod när vi försökte hämta hantverkare. Försök igen
            senare.
          </p>
        )}
      </header>
      {!noHandymenFound && (
        <fieldset
          className="grid grid-cols-3 gap-4"
          /*  {...form.register("handymanId")} */
        >
          {!hasFetchedHandymen && <PlaceHolders isLoading={res.isFetching} />}
          {hasHandymen &&
            handymen.map((handyman) => {
              const isSelected = props.selectedHandyman?.id === handyman.id;
              return (
                <HandymanRadioInput
                  key={handyman.id}
                  isSelected={isSelected}
                  handyman={handyman}
                  selectHandyman={props.selectHandyman}
                />
              );
            })}
        </fieldset>
      )}
      {noHandymenFound && <p>Inga hantverkare tillgängliga för vald tjänst</p>}
    </section>
  );
};

const HandymanRadioInput = (props: {
  handyman: BookingFlowHandyman;
  isSelected: boolean;
  selectHandyman: (handyman: BookingFlowHandyman) => void;
}) => {
  const h = props.handyman;
  const colors = useTailwindColors();
  return (
    <motion.p
      className="flex h-full w-full flex-col rounded bg-secondary-100"
      animate={{
        backgroundColor: props.isSelected
          ? colors.secondary[200]
          : colors.secondary[100],
      }}
      whileHover={{
        backgroundColor: colors.secondary[200],
      }}
    >
      <input
        id={h.id}
        className="sr-only"
        type="radio"
        value={h.id}
        onChange={(e) => {
          if (e.target.checked) {
            props.selectHandyman(h);
          }
        }}
      />
      <label
        htmlFor={h.id}
        className={classNames(
          "grid cursor-pointer grid-cols-[minmax(0,1fr),auto] gap-4 rounded border p-3",
          props.isSelected && "border-focus",
          !props.isSelected && "border-transparent"
        )}
      >
        <span className="text-base font-bold">{h.fullName}</span>

        <span className="col-span-2 flex items-center gap-2 py-2">
          <IconPhone size={24} />
          <a href={`tel:${h.phone}`} className="hover:underline">
            {h.phone}
          </a>
        </span>
        <span className="col-span-2 mt-auto text-sm font-semibold text-[#4CA154]">
          {h.travelInfoText} bort
        </span>
      </label>
    </motion.p>
  );
};

const PlaceHolders = (props: { n?: number; isLoading: boolean }) => {
  const n = props.n ?? 3;
  const arr = Array.from({ length: n }, (_, i) => i);

  return arr.map((_, index) => {
    return (
      <PlaceHolderListItem
        key={`handyman-placeholder-${index}`}
        isLoading={props.isLoading}
      />
    );
  });
};

const PlaceHolderListItem = (props: { isLoading: boolean }) => {
  return (
    <span
      aria-hidden
      className={classNames(
        "h-32 w-full border border-dashed border-border-color bg-secondary-100",
        props.isLoading && "skeleton"
      )}
    />
  );
};

export default BookingFlowHandymanSuggestionComponent;
