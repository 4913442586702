import { z } from "@ipis/centralized-zod";
import { BookingTypeService, CostCenter } from "./eas-trpc-types";

export const SsnSchema = z
  .string()
  .trim()
  .regex(/^\d{8}-\d{4}$|^\d{12}$/, {
    message:
      "Formatera personnumret med 12 siffor, enligt ÅÅÅÅMMDD-XXXX eller ÅÅÅÅMMDDXXXX",
  });
export const OrgNoSchema = z
  .string()
  .trim()
  .regex(/^\d{6}-\d{4}$|^\d{10}$/, {
    message:
      "Formatera organisationsnumret med 10 siffror, enligt XXXXXX-XXXX eller XXXXXXXXXX",
  });

export const PhoneNumberSchema = z
  .string()
  .trim()
  .min(2)
  .refine(
    (val) => {
      return val.startsWith("+");
    },
    {
      message: "Telefonnumret måste börja med +",
    }
  );

export const TypeOfCustomerSchema = z.enum(["private", "business"]);
export type TypeOfCustomer = z.infer<typeof TypeOfCustomerSchema>;

export const TypeOfServiceLiteralSchema = z.enum([
  "emergency",
  "request",
  "customerSupport",
]);
export type TypeOfServiceLiteral = z.infer<typeof TypeOfServiceLiteralSchema>;

const BaseGoogleAddress = z.object({
  formattedAddress: z.string().trim().min(1),
  longitude: z.number(),
  latitude: z.number(),
  street: z.string().trim().min(1),
  postalCode: z.string().trim().min(1),
  city: z.string().trim().min(1),
  country: z.string().trim().min(1),
});

export type BaseGoogleAddressType = z.infer<typeof BaseGoogleAddress>;

export const ExactGoogleAddress = BaseGoogleAddress.extend({
  extraInfo: z.string().trim().optional(),
});

const BaseAddress = z.object({
  street: z.string().trim().min(1),
  postalCode: z.string().trim().min(1),
  city: z.string().trim().min(1),
  country: z.string().trim().min(1),
});

export const VerifiedPrivateCustomer = z.object({
  hasAttemptedVerification: z.literal(true),
  typeOfCustomer: z.literal(TypeOfCustomerSchema.Enum.private),
  verificationPassed: z.literal(true),
  ssn: SsnSchema,
  customerId: z.string().trim().min(1),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  address: BaseAddress,
});

export const VerifiedBusinessCustomer = z.object({
  hasAttemptedVerification: z.literal(true),
  typeOfCustomer: z.literal(TypeOfCustomerSchema.Enum.business),
  verificationPassed: z.literal(true),
  customerId: z.string().trim().min(1),
  businessName: z.string().trim().min(1),
  businessAddress: BaseAddress,
  orgNo: OrgNoSchema,
});

export const UnverifiedSchema = z.object({
  hasAttemptedVerification: z.boolean(),
  verificationPassed: z.literal(false),
  customerNotFound: z.boolean().optional(),
  typeOfCustomer: TypeOfCustomerSchema,
});
export const CustomerVerificationFormSchema = z.union([
  UnverifiedSchema,
  VerifiedPrivateCustomer,
  VerifiedBusinessCustomer,
]);

export type CustomerVerificationFormValues = z.infer<
  typeof CustomerVerificationFormSchema
>;

function withOtherLocation<T extends z.ZodRawShape>(schema: z.ZodObject<T>) {
  return schema.extend({
    locationDifferentFromBaseAddress: z.literal(true),
    workOrderLocationAddress: ExactGoogleAddress,
  });
}

function withoutOtherLocation<T extends z.ZodRawShape>(schema: z.ZodObject<T>) {
  return schema.extend({
    locationDifferentFromBaseAddress: z.literal(false).optional(),
    workOrderLocationAddress: z.any(),
  });
}

const PrivateCustomerBaseSchema = z.object({
  typeOfCustomer: z.literal(TypeOfCustomerSchema.Enum.private),
  verificationStatus: VerifiedPrivateCustomer,
  privateCustomerInformation: z.object({
    contactInformation: z.object({
      firstName: z.string().trim().min(1),
      lastName: z.string().trim().min(1),
      /* 
		  Later, validate and transform this to have +46 in front 
		*/
      phone: PhoneNumberSchema,
    }),
    addressCoordinates: z.object({
      lat: z.number(),
      long: z.number(),
    }),
  }),
});

export const PrivateWithOtherLocationSchema = withOtherLocation(
  PrivateCustomerBaseSchema
);
export const PrivateWithoutOtherLocationSchema = withoutOtherLocation(
  PrivateCustomerBaseSchema
);

export type PrivateWithoutOtherLocationType = z.infer<
  typeof PrivateWithoutOtherLocationSchema
>;

const BusinessCustomerSchema = z.object({
  typeOfCustomer: z.literal(TypeOfCustomerSchema.Enum.business),
  verificationStatus: VerifiedBusinessCustomer,
  businessInformation: z.object({
    businessAddressCoordinates: z.object({
      lat: z.number(),
      long: z.number(),
    }),
    contactPerson: z.object({
      id: z.string().trim().min(1).optional(),
      ssn: SsnSchema.optional().or(z.literal("")),
      firstName: z.string().trim().min(1),
      lastName: z.string().trim().min(1),
      phone: PhoneNumberSchema,
      jobTitle: z.string().trim().min(1),
    }),
    invoiceEmail: z.string().email(),
    invoiceRecipient: z.enum(["business-account", "contact-person"]),
  }),
});

export const BusinessWithOtherLocationSchema = withOtherLocation(
  BusinessCustomerSchema
);
export const BusinessWithoutOtherLocationSchema = withoutOtherLocation(
  BusinessCustomerSchema
);

export const CustomerInformationFormSchema = z.union([
  PrivateWithoutOtherLocationSchema,
  PrivateWithOtherLocationSchema,
  BusinessWithoutOtherLocationSchema,
  BusinessWithOtherLocationSchema,
  z.object({
    typeOfCustomer: TypeOfCustomerSchema,
    verificationStatus: UnverifiedSchema,
  }),
]);

export type CustomerInformationFormValues = z.infer<
  typeof CustomerInformationFormSchema
>;

export const PrivateCustomerInformationSchema = z.union([
  PrivateWithoutOtherLocationSchema,
  PrivateWithOtherLocationSchema,
]);

export type PrivateCustomerInformationValues = z.infer<
  typeof PrivateCustomerInformationSchema
>;

export const BusinessCustomerInformationSchema = z.union([
  BusinessWithoutOtherLocationSchema,
  BusinessWithOtherLocationSchema,
]);

export type BusinessCustomerInformationValues = z.infer<
  typeof BusinessCustomerInformationSchema
>;

export const EmergencyCustomerInformationSchema = z.union([
  PrivateWithoutOtherLocationSchema,
  PrivateWithOtherLocationSchema,
  BusinessWithoutOtherLocationSchema,
  BusinessWithOtherLocationSchema,
]);

export type EmergencyCustomerInformationValues = z.infer<
  typeof EmergencyCustomerInformationSchema
>;

export const BookingFlowHandymanSchema = z.object({
  id: z.string().min(1),
  fullName: z.string().min(1),
  phone: z.string().min(1),
  earliestStart: z.date(),
  travelInfoText: z.string(),
});

export type BookingFlowHandyman = z.infer<typeof BookingFlowHandymanSchema>;

export type EmergencyWorkOrderDetails = {
  description: string;
  service: BookingTypeService;
  handyman: BookingFlowHandyman;
};

export type EmergencyWorkOrderConfirmationValues = {
  costCenter: CostCenter;
  customerInfo: EmergencyCustomerInformationValues;
  workOrderDetails: EmergencyWorkOrderDetails;
  comment?: string;
};
