import { classNames } from "../../../../utils/client-utils";

interface Props {
  className?: string;
  humanReadableName?: string;
  error: string;
}

export const IpisFormInputError = (props: Props) => {
  return (
    <span
      /* aria-label={`Error message for ${props.humanReadableName}`} */
      className={classNames("text-sm text-red-600", props.className)}
    >
      {props.error}
    </span>
  );
};
