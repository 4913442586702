import { classNames } from "../../../utils/client-utils";
import {FormProgressIndicatorListItem} from "./FormProgressIndicatorListItem";

interface Props {
  className?: string;
  pages: { label: string }[];
  currentStep: number;
  fixedAtTop?: boolean;
}

/* 
  Needs accessbility improvements
*/
const FormProgressIndicator = (props: Props) => {
  return (
    <ol
      className={classNames("grid w-full gap-x-2", !props.fixedAtTop && "p-2")}
      style={{
        gridTemplateColumns: `repeat(${props.pages.length}, minmax(0, 1fr))`,
      }}
    >
      {props.pages.map((page, index) => {
        return (
          <FormProgressIndicatorListItem
            key={page.label}
            label={page.label}
            index={index}
            currentStep={props.currentStep}
            fixedAtTop={props.fixedAtTop}
          />
        );
      })}
    </ol>
  );
};

export {FormProgressIndicator};
