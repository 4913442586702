import EAS_AuthApiClient from "../../api-clients/EAS_AuthApiClient";
import EAS_BookingFlowApiClient from "../../api-clients/EAS_BookingFlowApiClient";
import {
  ApiClientContext,
  ApiClientContextType,
} from "../EAS_api-client-context";

const values: ApiClientContextType = {
  bookingFlow: new EAS_BookingFlowApiClient(),
  auth: new EAS_AuthApiClient(),
};

interface Props {
  children?: React.ReactNode;
}

const ApiClientContextProvider = (props: Props) => {
  return (
    <ApiClientContext.Provider value={values}>
      {props.children}
    </ApiClientContext.Provider>
  );
};

export default ApiClientContextProvider;
