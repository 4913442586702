import { useEffect } from "react";

type Exclude = React.MutableRefObject<any> | undefined;
/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
export function useOnOutsideClick(args: {
  ref: React.MutableRefObject<any>;
  callback: () => any;
  exclude?: Exclude[];
}) {
  const { ref, callback, exclude = [] } = args;
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        const inExcludedArea = exclude.some(
          (excludeRef) =>
            excludeRef?.current && excludeRef.current.contains(event.target)
        );
        if (!inExcludedArea) {
          callback();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, exclude]);
}
