import { MotionProps, motion } from "framer-motion";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useFormInputContext } from "../../../../contexts/form-input-context";
import {
  useFormError,
  useFormRegisterProps,
} from "../../../../hooks/form-hooks";
import { classNames } from "../../../../utils/client-utils";
import { FauxFieldsetWrapper } from "../components/FauxFieldsetWrapper";
import { FormInputShellPublicProps } from "../components/FormInputShell";
import { IpisFormInputError } from "../components/IpisFormInputError";
import { IpisFormInputNote } from "../components/IpisFormInputNote";

export type IpisTextInputCompactProps = Omit<
  MotionProps,
  "minLength" | "maxLength" | "disabled" | "placeholder"
>;

type Size = "sm" | "md" | "lg";

interface Props<T extends FieldValues>
  extends Pick<
    FormInputShellPublicProps<T>,
    "form" | "name" | "controls" | "onBlur" | "onFocus"
  > {
  id: string;
  className?: string;
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  size?: Size;
  required?: boolean;
  placeholder?: string;
  inputProps?: IpisTextInputCompactProps;
  type?: "text" | "password" | "email" | "number" | "tel";
  onDarkBackground?: boolean;
  htmlNote?: string;
}
export const IpisTextInputCompact = <T extends FieldValues>(
  props: Props<T>
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const ctx = useFormInputContext();
  const error = useFormError({ form: props.form, name: props.name });
  const { inputProps, label } = props;
  const size = props.size ?? "sm";

  const registerProps = useFormRegisterProps({
    props,
    type: props.type === "number" ? "number" : "text",
  });

  const readOnly = props.readOnly || !!ctx?.readOnly;

  const tabIndexProps: {
    tabIndex?: number;
  } = {};
  if (readOnly) {
    tabIndexProps.tabIndex = -1;
  }

  return (
    <FauxFieldsetWrapper
      className={classNames("w-full", props.className)}
      leftSidePaddingInPixels={16}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      isFocused={isFocused}
      isHovered={isHovered}
      size={size}
      readOnly={readOnly}
      label={{
        text: label,
      }}
      required={props.required}
      onDarkBackground={props.onDarkBackground}
      {...inputProps}
    >
      <motion.input
        type={props.type ?? "text"}
        id={props.id}
        placeholder={props.placeholder}
        style={{}}
        className={classNames(
          "col-span-3 col-start-1 row-start-1 w-full rounded bg-transparent p-4 placeholder-semi-faded focus:outline-none",
          props.onDarkBackground && !readOnly && "text-light-background",
          props.onDarkBackground && readOnly && "text-light-background/80",
          readOnly && "text-semi-faded"
        )}
        {...registerProps}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        disabled={!!props.disabled}
        readOnly={readOnly}
        {...props.inputProps}
        {...tabIndexProps}
        onFocus={(e) => {
          setIsFocused(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          registerProps.onBlur?.(e);
          props?.onBlur?.(e);
        }}
        required={!!props.required}
      />
      {!!props.htmlNote && (
        <IpisFormInputNote html={props.htmlNote} className="col-span-3" />
      )}
      {!!error && (
        <IpisFormInputError
          error={error}
          humanReadableName={props.label || props.name}
          className="col-span-3"
        />
      )}
    </FauxFieldsetWrapper>
  );
};
