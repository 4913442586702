import { useEffect, useRef, useState } from "react";

export function usePrevious<T>(value: T) {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useLoading() {
  const [isLoading, setLoading] = useState(false);

  async function loadWhileAsync<T>(callback: () => Promise<T>): Promise<T> {
    return loadWhilePromise(callback());
  }

  async function loadWhilePromise<T>(promise: Promise<T>): Promise<T> {
    setLoading(true);
    try {
      const res = await promise;
      setLoading(false);
      return res;
    } catch (er) {
      setLoading(false);
      throw er;
    }
  }

  return { isLoading, loadWhileAsync, loadWhilePromise };
}

type UseLoadingActionReturn<Args, Return> = {
  trigger: (args: Args) => Promise<Return>;
  triggerSafely: (args: Args) => Promise<Return | undefined>;
  isLoading: boolean;
  isError: boolean;
  error: any;
  latestValue: Return | undefined;
};

export function useLoadingAction<Args, Return>(
  callback: (args: Args) => Promise<Return>
): UseLoadingActionReturn<Args, Return> {
  const loader = useLoading();
  const [error, setError] = useState<any>(null);
  const [latestValue, setLatestValue] = useState<Return | undefined>(undefined);
  const isError = error !== null;

  async function trigger(args: Args): Promise<Return> {
    try {
      if (loader.isLoading) {
        throw new Error("Already loading");
      }
      const res = await loader.loadWhileAsync(() => callback(args));
      setLatestValue(res);
      setError(null);
      return res;
    } catch (er) {
      setError(er);
      throw er;
    }
  }

  function triggerSafely(args: Args): Promise<Return | undefined> {
    if (loader.isLoading) {
      return Promise.resolve(undefined);
    }
    return trigger(args);
  }

  return {
    trigger,
    isLoading: loader.isLoading,
    isError,
    error,
    latestValue,
    triggerSafely,
  };
}
