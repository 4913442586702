export function classNames(...classNames: any[]): string | undefined {
  return (
    classNames.filter((str) => typeof str === "string").join(" ") || undefined
  );
}
export function renderOne<T>(
  args: T,
  renderFunctions: ((
    props: T
  ) => React.ReactElement | null | undefined | false)[]
): React.ReactNode {
  for (const renderFunction of renderFunctions) {
    const element = renderFunction(args);
    if (element) {
      return element;
    }
  }

  return null;
}
