import { IconUser } from "@tabler/icons-react";
import withIconSize, { InternalIconProps } from "../../hocs/withIconSize";

interface Props extends InternalIconProps {}

const IpisProfileIcon_ = (props: Props) => {
  const { className, ...rest } = props;

  return <IconUser {...rest} className={props.className} />;
};
export const IpisProfileIcon = withIconSize(IpisProfileIcon_);
