import { motion } from "framer-motion";
import React, { useState } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { classNames } from "../../../../utils/client-utils";
import { useFormError } from "../../../../hooks/form-hooks";
import { useFormInputContext } from "../../../../contexts/form-input-context";
import { IpisFormInputLabel } from "./IpisFormInputLabel";
import { InformationTooltip } from "../../../InformationTooltip";
import { IpisFormInputDescription } from "./IpisFormInputDescription";
import { IpisButton } from "../../../buttons/IpisButton";
import { SlideUpModal } from "../../../modals/SlideUpModal";
import { RichTextPreTag } from "./RichTextPreTag";
import { IpisFormInputNote } from "./IpisFormInputNote";
import { IpisFormInputParanthesizedTooltip } from "./IpisFormInputParanthesizedTooltip";
import { IpisFormInputError } from "./IpisFormInputError";

export type FormInputShellPublicProps<T extends FieldValues> = {
  id: string;
  name: Path<T>;
  label?: string | null;
  tooltip?: string | null;
  required?: boolean;
  richTextDescription?: string | null;
  richTextNote?: string | null;
  className?: string;
  hideError?: boolean;
  onBlur?: (e: React.ChangeEvent) => void;
  onFocus?: (e: React.ChangeEvent) => void;
  slideUpElement?: {
    prompt: string;
    content: string;
  } | null;
  form?: UseFormReturn<T>;
  controls?: {
    onChange?: (value: any) => void;
    value: any;
  };
};

interface Props<T extends FieldValues> extends FormInputShellPublicProps<T> {
  children?: React.ReactNode;
  placeChildren?: "top-right" | "after-description";
}
export const FormInputShell = <T extends FieldValues>(props: Props<T>) => {
  const ctx = useFormInputContext();
  const error = useFormError({ form: props.form, name: props.name });

  const [popupOpen, setPopupOpen] = useState(false);

  const placeChildren = props.placeChildren ?? "after-description";

  const tooltip = props.tooltip;
  const showTooltipAsInformationIcon =
    !!tooltip && placeChildren !== "top-right";
  const showTooltipAsAdditionalText =
    !!tooltip && placeChildren === "top-right";

  return (
    <motion.div
      className={classNames(
        "relative grid w-full grid-cols-[minmax(0,1fr),auto] gap-2",
        props.className
      )}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      {!!props.label && (
        <IpisFormInputLabel
          id={props.id}
          label={props.label}
          required={props.required}
        />
      )}
      {!!showTooltipAsInformationIcon && !ctx?.omitTooltip && (
        <InformationTooltip
          open={"left"}
          className="col-start-2"
          content={tooltip}
          contentClassName="w-[200px]"
        />
      )}
      {placeChildren === "top-right" && props.children}
      {!!props.richTextDescription && !ctx?.omitDescription && (
        <IpisFormInputDescription
          className="col-span-2 w-full"
          html={props.richTextDescription}
        />
      )}
      {showTooltipAsAdditionalText && !ctx?.omitTooltip && (
        <IpisFormInputParanthesizedTooltip html={tooltip} />
      )}
      {placeChildren === "after-description" && props.children}
      {!!props.slideUpElement && !ctx?.omitSlideUpElement && (
        <>
          <IpisButton
            onClick={() => setPopupOpen(true)}
            className="col-span-2"
            label={props.slideUpElement.prompt}
            variant="tonal"
            icon={{
              position: "trailing",
              type: "information",
            }}
          />
          <SlideUpModal
            isOpen={popupOpen}
            heading={props.slideUpElement.prompt}
            onClose={() => setPopupOpen(false)}
          >
            <RichTextPreTag html={props.slideUpElement.content} />
          </SlideUpModal>
        </>
      )}
      {!!props.richTextNote && !ctx?.omitNote && (
        <IpisFormInputNote html={props.richTextNote} className="col-span-2" />
      )}
      {!!error && !props.hideError && !ctx?.omitError && (
        <IpisFormInputError
          error={error}
          humanReadableName={props.label || props.name}
        />
      )}
    </motion.div>
  );
};
