import React from "react";

export type FormInputContextValue = {
  omitDescription?: boolean;
  omitError?: boolean;
  omitTooltip?: boolean;
  omitNote?: boolean;
  omitSlideUpElement?: boolean;
  readOnly?: boolean;
};

export const FormInputContext = React.createContext<FormInputContextValue | undefined>(
  undefined
);

export const useFormInputContext = () => {
  const context = React.useContext(FormInputContext);
  return context;
};

