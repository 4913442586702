import { createContext, useContext } from "react";

interface SharedBase<T extends string> {
  className?: string;
  mainClassName?: string;
  children?: React.ReactNode;
  selectedTab: T;
  addPadding?: boolean;
}

interface UncontrolledBase<T extends string> extends SharedBase<T> {
  tabs?: T[];
  icon?(tab: T): React.ReactNode;
  withoutControls: true;
}

interface ControlledBase<T extends string> extends SharedBase<T> {
  tabs: T[];
  icon?(tab: T): React.ReactNode;
  withoutControls?: false;
}

interface WithSearchParams<T extends string> extends ControlledBase<T> {
  searchParams: (tab: T) => Record<string, string | string[]>;
}
interface WithHref<T extends string> extends ControlledBase<T> {
  href: (tab: T) => string;
}
interface WithCallback<T extends string> extends ControlledBase<T> {
  onSelect(tab: T): void;
}
type SelfControlled<T extends string> = UncontrolledBase<T> & {
  withoutControls: true;
};
export type TabContextProps<T extends string> =
  | WithSearchParams<T>
  | WithHref<T>
  | WithCallback<T>
  | SelfControlled<T>;

type ContextType = TabContextProps<string> & {
  onTabClick: (tab: string) => void;
  showTabListOnKeyboardOpen?: boolean;
};

export const TabContext = createContext<ContextType>({} as never);

export function useTabContext(): ContextType {
  const ctx = useContext(TabContext);
  return ctx;
}
